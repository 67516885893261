import React from "react";

const RefreshText = () => {
  return (
    <h2
      style={{
        textAlign: "center",
        position: "absolute",
        top: "-1.5rem",
        width: "100%",
      }}
      className="md-none"
    >
      Board will be updated after every 1 min
    </h2>
  );
};

export default RefreshText;
