import { useREALAuth } from "context/AuthProvider";
import { useEffect, useState } from "react";
import { withBoosterBaseUrl } from "utils/utils";

const useBoosterHistory = () => {
  const [boosters, setBoosters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { auth } = useREALAuth();

  const fetchBoosters = async () => {
    setIsLoading(true);

    try {
      const token = await auth.auth?.currentUser?.getIdToken();

      const header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(withBoosterBaseUrl("/history"), {
        headers: header,
      });
      const boosters = await response.json();

      setBoosters(boosters?.boosters);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!auth) {
      return;
    }

    fetchBoosters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return {
    boosters,
    revalidate: fetchBoosters,
    isLoading,
    error,
  };
};

export default useBoosterHistory;
