// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__description__bgtn3 {
  padding: 12px 0;
  color: white;
  font-size: 1rem;
}
.styles_wrapper__content__fmEaX {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/pages/CartPage/styles.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADJ","sourcesContent":[".wrapper {\n  &__description {\n    padding: 12px 0;\n    color: white;\n    font-size: 1rem;\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper__description": `styles_wrapper__description__bgtn3`,
	"wrapper__content": `styles_wrapper__content__fmEaX`
};
export default ___CSS_LOADER_EXPORT___;
