/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { auth } from "../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  setquestdata,
  setquestloading,
  setquestsubmissions,
} from "../../Store/Features/modalSlice";
import { useLocation } from "react-router-dom";
import useFirestore from "../useFirestore";

export const useGetQuests = () => {
  const dispatch = useDispatch();
  const activeround = useSelector((state) => state.round.data);
  const { pathname } = useLocation();
  const firestore = useFirestore();

  const submissionsConditions = useMemo(
    () => [
      {
        fieldPath: "completedBy",
        opStr: "==",
        value: auth.currentUser?.uid,
      },
      { fieldPath: "roundId", opStr: "==", value: activeround?.id },
    ],
    [activeround?.id, auth.currentUser]
  );

  const questsConditions = useMemo(
    () => [
      { fieldPath: "roundId", opStr: "==", value: activeround?.id },
      { fieldPath: "isActive", opStr: "==", value: true },
    ],
    [activeround?.id]
  );

  const submissions = firestore.useSnapshot(
    "questsApplications",
    submissionsConditions
  );
  const quests = firestore.useSnapshot("quests", questsConditions);

  useEffect(() => {
    if (pathname !== "/") return;
    if (
      !auth.currentUser ||
      !activeround?.id
    )
      return;

    dispatch(setquestloading(true));

    dispatch(setquestsubmissions(submissions));

    dispatch(setquestdata(quests));
    dispatch(setquestloading(false));
  }, [auth.currentUser, activeround, pathname, dispatch, submissions, quests]);

  return null;
};
