import { CSSProperties } from "react";

/**
 * @param {{
 *   flexDirection: string,
 *   gap?: string,
 *   justifyContent?: string,
 *   alignItems?: string  ,
 *   style?: object,
 *   className?: string,
 *   flexBasis?: string
 *   rowGap?: string
 *   columnGap?: string
 *   flex?: string,
 * }} props
 */
const Flexed = (props) => {
  const {
    flexDirection = "row",
    gap,
    justifyContent = undefined,
    alignItems = undefined,
    children,
    style = {},
    className = "",
    flexBasis = undefined,
    rowGap = undefined,
    columnGap = undefined,
    flex = undefined,
    ...rest
  } = props;
  const styles = {};
  if (flexDirection) styles.flexDirection = flexDirection;
  if (justifyContent) styles.justifyContent = justifyContent;
  if (alignItems) styles.alignItems = alignItems;
  if (flexBasis) styles.flexBasis = flexBasis;
  if (columnGap) styles.columnGap = columnGap;
  if (rowGap) styles.rowGap = rowGap;
  if (flex) styles.flex = flex;
  if (gap) styles.gap = gap;
  return (
    <div
      className={className}
      style={{
        display: "flex",
        ...styles,
        ...style,
        flexWrap: "wrap",
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flexed;
