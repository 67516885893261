import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

export const useGetUser = () => {
  const getUser = async (id) => {
    try {
      const docref = doc(db, "users", id);
      const ref = await getDoc(docref);
      if (ref.exists()) {
        return ref.data();
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  return { getUser };
};
