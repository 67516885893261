// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_card__xtdMU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
}
.styles_card__title__FShaS {
  margin-top: 12px;
}
.styles_card__description__7iwIW {
  margin-top: 12px;
  color: white;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/pages/ShopPage/ErrorIndicator/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;AAAJ","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 12px;\n\n  &__title {\n    margin-top: 12px;\n  }\n\n  &__description {\n    margin-top: 12px;\n    color: white;\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `styles_card__xtdMU`,
	"card__title": `styles_card__title__FShaS`,
	"card__description": `styles_card__description__7iwIW`
};
export default ___CSS_LOADER_EXPORT___;
