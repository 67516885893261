import React, { createContext, useState, useContext, useEffect } from "react";

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "@config/firebase";

// Local storage keys
const PROJECTS_LOCAL_STORAGE_KEY = "__intraverse_projects_in_provider__";
const PROJECTS_SAVED_TIME_LOCAL_STORAGE_KEY =
  "__intraverse_projects_saved_time__";

const VALID_TIME = 60000; // 1 minutes

const ProjectsContext = createContext();

function ProjectsProvider({ children }) {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * The function `readProjectsFromLocalStorage` retrieves saved projects from local storage if they are
   * still valid based on a specified time limit.
   * @returns The function `readProjectsFromLocalStorage` returns the projects stored in the local
   * storage if they are still valid based on the saved time and the `VALID_TIME` constant. If the saved
   * time is not available or the saved time has expired, nothing is returned.
   */
  const readProjectsFromLocalStorage = () => {
    const savedTime = localStorage.getItem(
      PROJECTS_SAVED_TIME_LOCAL_STORAGE_KEY
    );
    if (!savedTime) return;
    if (Date.now() - savedTime < VALID_TIME) {
      const saved = localStorage.getItem(PROJECTS_LOCAL_STORAGE_KEY);
      if (!saved) {
        localStorage.removeItem(PROJECTS_SAVED_TIME_LOCAL_STORAGE_KEY);
        return;
      }
      const projects = JSON.parse(saved);
      return projects;
    }
  };

  /**
   * The function `saveProjectsToLocalStorage` saves projects to local storage along with the current
   * timestamp.
   * @param projects - The `projects` parameter in the `saveProjectsToLocalStorage` function is an array
   * of project objects that you want to save to the browser's local storage. Each project object
   * typically contains information about a specific project, such as its name, description, status, and
   * any other relevant data.
   */
  const saveProjectsToLocalStorage = (projects) => {
    localStorage.setItem(PROJECTS_LOCAL_STORAGE_KEY, JSON.stringify(projects));
    localStorage.setItem(PROJECTS_SAVED_TIME_LOCAL_STORAGE_KEY, Date.now());
  };

  /**
   * The `fetchProjects` function fetches active projects from a Firestore database, updates state with
   * the retrieved data, and handles loading and error states.
   * @returns The `fetchProjects` function is returning the result of the `onSnapshot` function call.
   */
  const fetchProjects = () => {
    try {
      setIsLoading(true);
      const q = query(
        collection(db, "projects"),
        where("isActive", "==", true)
      );

      return onSnapshot(
        q,
        (querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            data.push({ ...doc.data(), id });
          });

          setProjects(data);
          setIsLoading(false);
          saveProjectsToLocalStorage(data);
        },
        (error) => {
          console.error("Error fetching projects:", error);
          setIsLoading(false);
          setError(error);
        }
      );
    } catch (error) {
      setError(error);
      setIsLoading(false);
      setProjects([]);
    }
  };

  /* The `useEffect` hook in the code snippet is responsible for managing side effects in the
  `ProjectsProvider` component. Here's a breakdown of what it is doing: 
    - The `fetchProjects` function is called to fetch active projects from the Firestore database.
    - If there are saved projects in the local storage, they are retrieved and set as the initial state
    using the `setProjects` function.
    - The `useEffect` hook returns a cleanup function that unsubscribes from the Firestore listener when
    the component unmounts.
  */
  useEffect(() => {
    const savedProjects = readProjectsFromLocalStorage();
    if (savedProjects) {
      setProjects(savedProjects);
      return;
    }
    const unsubscribe = fetchProjects();

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    projects,
    isLoading,
    error,
  };

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
}

function useProjects() {
  return useContext(ProjectsContext);
}

export { ProjectsProvider, useProjects };
