import { Image } from "antd";
import { useGetImage } from "@/hooks";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

function NFTCard({ nft }) {
  const [imageBlob, setImageBlob] = useState(null);
  const { getimage } = useGetImage();

  useEffect(() => {
    if (nft?.image) {
      getimage(nft?.image).then((e) => setImageBlob(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft?.image]);

  return (
    <div className={styles.card}>
      <Image
        src={
          nft?.attributes
            ? nft.src
            : imageBlob
            ? imageBlob
            : `/assets/nft/Nebula.jpg`
        }
        className={styles.card__image}
      />
      <div className={styles.card__content}>
        <span className={styles.card__content__name}>
          {nft?.name || nft?.collection || nft?.attributes}
        </span>
        <span className={styles.card__content__count}>Count: {nft?.count}</span>
      </div>
    </div>
  );
}

export default NFTCard;
