import { message } from "antd";

export const useNotification = () => {
  message.config({
    maxCount: 2,
  });

  const openNotification = (text, status, duration) => {
    if (status === "error") {
      message.error({
        content: text,
        className: "intraverseNotificationMessage error",
        duration: duration,
      });
    } else if (status === "success") {
      message.success({
        content: text,
        className: "intraverseNotificationMessage success",
        duration: duration,
      });
    } else {
      message.info({
        content: text,
        className: "intraverseNotificationMessage info",
        duration: duration,
      });
    }
  };

  return { openNotification };
};
export default useNotification;
