import React from "react";
import NeonCard from "@common/neoncard";
import styles from "./styles.module.scss";
import classNames from "classnames";

function DynamicValueButton({
  className,
  setHoverEffect,
  value = 1,
  onChange = () => {},
  disabled = false,
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <NeonCard
        className={classNames(
          classNames(styles.wrapper__button, styles["wrapper__button--left"], {
            [styles["wrapper__button--disabled"]]: disabled,
          })
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;
          onChange(value - 1);
        }}
        onMouseEnter={() => {
          if (setHoverEffect) {
            setHoverEffect(false);
          }
        }}
        onMouseLeave={() => {
          if (setHoverEffect) {
            setHoverEffect(true);
          }
        }}
      >
        -
      </NeonCard>
      <span className={styles.wrapper__quantity}>{value}</span>
      <NeonCard
        className={classNames(
          classNames(styles.wrapper__button, styles["wrapper__button--right"], {
            [styles["wrapper__button--disabled"]]: disabled,
          })
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;
          onChange(value + 1);
        }}
        onMouseEnter={() => {
          if (setHoverEffect) {
            setHoverEffect(false);
          }
        }}
        onMouseLeave={() => {
          if (setHoverEffect) {
            setHoverEffect(true);
          }
        }}
      >
        +
      </NeonCard>
    </div>
  );
}

export default DynamicValueButton;
