import { useEffect, useState } from "react";
import { withShopBaseUrl, parseProduct } from "../../utils";

const useSingleProduct = (productId) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(withShopBaseUrl(`/products/${productId}`));
        const product = parseProduct(await response.json());

        setProduct(product);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  return { product, loading };
};

export default useSingleProduct;
