import CaliGraphy from "../../../../common/Caligraphy";
import NeonCard from "../../../../common/neoncard";
import React, { Fragment } from "react";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";
import theme from "../../../../theme";
function DisplayCard({ data = {} }) {
  const { pathname } = useLocation();
  const isMyApps = pathname.includes("submissions");
  let reason = data?.reason;

  const isRejected = data?.status === "rejected";

  return (
    <NeonCard
      className={`admincard full`}
      styles={{
        display: "block",
      }}
    >
      {!isMyApps ? (
        <Fragment>
          <Typography
            style={{
              width: "100%",
              textTransform: "none !important",
            }}
          >
            <Typography.Title level={2} ellipsis={{ rows: 1 }}>
              {data?.name}
            </Typography.Title>
          </Typography>

          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "1rem 0px" }}
            classname="smaller"
            content={`Type: ${data?.type}`}
          />
          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "0rem 0px" }}
            // style={{ marginBlock: "0 1.5rem" }}
            classname="smaller"
            content={`Expired: ${
              new Date(data?.endDate).getTime() < new Date().getTime()
            }`}
          />
          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "0rem 0px" }}
            classname="smaller"
            content={`Attempted: ${data?.attempted}`}
          />

          <CaliGraphy
            level={5}
            rows={1}
            // style={{ marginBlock: "0rem 0px" }}
            style={{ marginBlock: "0 1.5rem" }}
            classname="smaller"
            content={`timesCanBeCompleted: ${data?.timesCanBeCompleted}`}
          />

          <GetBottomcomponent data={data} />
        </Fragment>
      ) : null}
      {isMyApps ? (
        <Fragment>
          <Typography
            style={{
              width: "100%",
              textTransform: "none !important",
            }}
          >
            <Typography.Title level={2} ellipsis={{ rows: 1 }}>
              <span>{data?.status}</span>
            </Typography.Title>
          </Typography>

          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "1rem 0px" }}
            classname="smaller"
            content={`QuestType: ${data?.quizs ? " Quiz" : "Normal"} `}
          />

          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "0rem 0px" }}
            classname="smaller"
            content={`Quest: ${data?.questName}`}
          />
          <CaliGraphy
            level={5}
            rows={1}
            style={{ marginBlock: "0rem 0rem" }}
            classname="smaller"
            content={`Categories: ${data?.categories}`}
          />
          <CaliGraphy
            level={5}
            rows={10}
            style={{ marginBlock: "0rem 1.5rem" }}
            classname="smaller"
            content={`Reason: ${reason}`}
          />
          <GetBottomcomponent data={data} />
        </Fragment>
      ) : null}
    </NeonCard>
  );
}
const GetBottomcomponent = ({ data }) => {
  return (
    <div className="pointsrow">
      <span>
        <NeonCard
          className={`admincard`}
          styles={{ minWidth: "8.8rem", borderWidth: ".15rem" }}
        >
          <CaliGraphy
            rows={1}
            level={5}
            style={{
              minHeight: "unset",
              maxWidth: "8rem",
              fontSize: ".85rem",
              letterSpacing: ".01rem",
            }}
            type="heading"
            content={data?.activeround || ""}
          />
        </NeonCard>
      </span>

      <span>
        <NeonCard styles={{ borderWidth: ".15rem" }} className={`admincard`}>
          {data?.reward || "20pts"}
        </NeonCard>
      </span>
    </div>
  );
};
export default DisplayCard;
