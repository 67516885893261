import { useSelector } from "react-redux";
export const useCheckQuestLock = () => {
  const projects = useSelector(
    (state) => state.leaderboards.projectsLeaderBoard.data
  );
  const mySubmissions = useSelector((state) => state.modal.questsubmissions);

  const checkLock = async ({ id }) => {
    let data = Array.isArray(mySubmissions) ? mySubmissions : [];

    var flag = {};
    if (data?.length > 0) {
      const find = data?.find((application) => application?.projectId !== id);
      if (find) {
        const foundProject = projects?.find(
          (project) => project?.projectId === find?.projectId
        );

        flag = {
          projectName: foundProject?.projectName,
          projectId: foundProject?.projectId,
        };
      }
    }
    return flag;
  };
  return { checkLock };
};
