import { useEffect } from "react";
import { auth } from "../config/firebase";

const UsetifulComponent = () => {
  useEffect(() => {
    // Usetiful initialization script
    // if (!auth.currentUser) return;
    window.usetifulTags = { userId: auth.currentUser?.uid };
    (function (w, d, s) {
      var a = d.getElementsByTagName("head")[0];
      var r = d.createElement("script");
      r.async = 1;
      r.src = s;
      r.setAttribute("id", "usetifulScript");
      r.dataset.token = "1fd7a3017ea3561a9e6c22186689704d";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");

    // Cleanup script on component unmount (optional)
    return () => {
      var scriptElement = document.getElementById("usetifulScript");
      scriptElement.parentNode.removeChild(scriptElement);
    };
  }, []);
  return null;
};

export default UsetifulComponent;
