import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/firebase";
export const useUpload = () => {
  const upload = async (file, path) => {
    const storageRef = ref(storage, path + file.name);
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  };
  return { upload };
};
