import { AES, enc } from "crypto-js";
const secretKey = "dummykey";

export const useTextEncDecorder = () => {
  async function encryptPassword(pass) {
    const encoded = AES.encrypt(pass, secretKey).toString();
    return encoded;
  }

  async function decryptPassword(pass) {
    const dec = AES.decrypt(pass, secretKey).toString(enc.Utf8);
    return dec;
  }

  return { encryptPassword, decryptPassword };
};

export default useTextEncDecorder;
