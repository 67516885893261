import React from "react";
import styles from "./styles.module.scss";

import PaddedCard from "../PaddedCard";
import AddToCartButton from "../../../../components/AddToCartButton";

function Controller({ product }) {
  return (
    <PaddedCard>
      <div className={styles.card}>
        <div className={styles.card__content}>
          <div className={styles.card__content__image_wrapper}>
            <img
              className={styles.card__content__image_wrapper__image}
              alt="product"
              src={product.imageUrl}
            />
          </div>
        </div>

        <div className={styles.card__control_row}>
          <div className={styles.card__control_row__text}>
            <span className={styles.card__control_row__text__title}>
              Price:
            </span>
            <span className={styles.card__control_row__text__price}>
              $ {product.price}
            </span>
          </div>
          <AddToCartButton
            className={styles.card__control_row__add_to_cart}
            productId={product.id}
          />
        </div>
      </div>
    </PaddedCard>
  );
}

export default Controller;
