const theme = {
  primaryColor: "#64d2ff",
  red: "#cc5850",
  bgRedHover: "#ff6e64",
  btnBgBlue: "#1e3f4d",
  cardBorder: "#96afb8",
  fontFamily: '"Chakra Petch", sans-serif',
  textShadow:
    "0 0 7px rgba(0, 0, 0, 0.8), 0 0 7px rgba(0, 0, 0, 0.8), 0 0 0px rgba(0, 0, 0, 0.8)",
  shadow:
    "0 0 0.2rem 0px #326980, 0 0 0.6rem #326980, 0 0 3.125rem #326980 inset",
  shadowRed:
    "0 0 0.2rem 0px #cc5850, 0 0 0.6rem #cc5850, 0 0 3.125rem #cc5850 inset",
  shadowOutset: "0px 0px 8px 0px #326980",
  shadowHover:
    "0 0 2px 5px #2681a8, 0 0 15px 2px #2681a8, 0 0 70px #2681a8 inset",
  shadowRedHover:
    "0 0 2px 5px #ff6e64, 0 0 15px 2px #ff6e64, 0 0 70px #ff6e64 inset",
  bgColor: "#0009",
  borderColor: "rgb(174, 174, 174)",
  bgGradient: "linear-gradient(to bottom, #201c2f 0%, #080424 100%)",
  bgGradientQuest: "linear-gradient(to bottom, #080424 100%, #080424 100%)",
  bgDarkPurple: "#080424",
  transition: "all 0.3s ease-in-out",
  adminBoxBg: "#285b7f66",

  adminBg: "#040b14",
  borderRadius: ".5rem",
  borderRsmall: ".3rem",
  bw: ".24rem",
  bws: ".17rem",
  colors: [
    "#cd5de1",
    "#d39014",
    "#624d88",
    "#3984bd",
    "#2e524f",
    "#75674afe",
    "#bd842d",
    "#795548",
    "#607d8b",
    "#8a8888",
    "#b1b1b1",
    "#efe9e9",
    "#ffffff",
    "#dbbdbd",
    "#ffffff",
    "#e4bcbc",
    "#dec9c9",
    "#b5d503",
    "#963333",
    "#d7a2a2",
    "#48664a",
    "#174a1b",
    "#4caf50",
    "#b86a6a",
    "#95737b",
  ],

  // text
  TEXT_COLOR_PRIMARY: "#64d2ff",
  TEXT_COLOR_DANGER: "#cc5850",
  TEXT_COLOR_DANGER_HOVER: "#ff6e64",

  // client

  // admin panel
  ADMIN_BACKGROUND_MAIN: "#040b14", // black background color
  ADMIN_CONTAINER_BG: "#285b7f66", // bg color of paginated cards
};

export default theme;
