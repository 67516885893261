export const usingapp = process.env.REACT_APP_INTRAVERSE_ENV || "dev";

const envs = {
  dev: "https://intraverse-backend.metatope.com/api/v1",
  prod: "https://intraverse-backend.metatope.com/api/v1",
};

export const base_url = envs[usingapp];

export const base_public_url = `${base_url}/public/function/`;
export const base_private_url = `${base_url}/private/function/`;
export const base_auth_url = `${base_url}/auth/function/`;
