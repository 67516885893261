import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

export const useGetProject = () => {
  const getProject = async (id) => {
    try {
      const docref = doc(db, "projects", id);
      const data = await getDoc(docref);
      if (data.exists()) {
        return { ...data.data(), id: data.id };
      } else return null;
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };
  return { getProject };
};
