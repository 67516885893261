import React from "react";
import { Layout as AntdLayout } from "antd";
import BackButton from "./BackButton";
import styles from "./styles.module.scss";

const { Header: AntdHeader } = AntdLayout;

function Header() {
  const title = "INVENTORY";

  return (
    <AntdHeader className={styles.header}>
      <div className={styles.header__content}>
        <BackButton />
        <img
          className={styles.header__content__logo}
          alt="intraverse logo"
          src="/assets/logo/simple-logo.png"
        />
        <h1 className={styles.header__content__title}>{title}</h1>
      </div>
    </AntdHeader>
  );
}

export default Header;
