import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const LIMIT = 20;

export const useGetQuizQuestions = () => {
  const getQuizes = async ({ quizzes }) => {
    let array = [];

    try {
      const docRef = collection(db, "quizquestions");

      for (let i = 0; i < quizzes.length; i += LIMIT) {
        const batchQuizzes = quizzes.slice(i, i + LIMIT);

        for (const quiz of batchQuizzes) {
          const documentId = quiz.id;
          const documentSnapshot = await getDoc(doc(docRef, documentId));

          if (documentSnapshot.exists()) {
            array.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
            });
          }
        }
      }

      return array;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };

  return { getQuizes };
};
