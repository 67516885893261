// const getObject = () => {
//   const obj = {
//     userID: "",
//     email: "",
//     username: "",
//     wallet: [""],
//     "galaxy pass": "none",
//   };
//   return obj;
// };

// export const SEGMENT_EVENTS = {
//   log_in: {
//     event_name: "log_in",
//     data_to_send: getObject(),
//   },
//   sign_up: {
//     event_name: "sign_up",
//     data_to_send: getObject(),
//   },
//   click_tutorial: {
//     event_name: "click_tutorial",
//     data_to_send: getObject(),
//   },
//   tutorial_start: {
//     event_name: "tutorial_start",
//     data_to_send: getObject(),
//   },
//   tutorial_completed: {
//     event_name: "tutorial_completed",
//     data_to_send: getObject(),
//   },
//   tutorial_progress: {
//     event_name: "tutorial_progress",
//     data_to_send: { ...getObject(), percentage: 0 },
//   },
//   download: {
//     event_name: "download",
//     type: { mac: "mac", windows: "windows", android: "android" },
//     data_to_send: { type: "", userID: "" },
//   },
//   click_play_webgl: {
//     event_name: "click_play_webgl",
//     data_to_send: { userID: "" },
//   },
//   click_galaxy_pass: {
//     event_name: "click_galaxy_pass",
//     data_to_send: { userID: "" },
//   },
//   click_wallets: {
//     event_name: "click_wallets",
//     data_to_send: { userID: "" },
//   },
//   wallet_connected: {
//     event_name: "wallet_connected",
//     data_to_send: { userID: "" },
//   },
//   click_quest: {
//     event_name: "click_quest",
//     data_to_send: { type: "" },
//   },
//   quest_completed: {
//     event_name: "quest_completed",
//     data_to_send: {
//       type: "",
//       name: "",
//       round: "",
//       typology: "",
//       number: 0,
//       points: 0,
//       max_submissions: 0,
//     },
//   },
// };
const getObject = () => {
    const obj = {
        userID: '',
        email: '',
        username: '',
        wallet: [''],
        'galaxy pass': 'none',
    };
    return obj;
};

export const SEGMENT_EVENTS = {
    log_in: {
        event_name: 'log_in',
        data_to_send: getObject(),
    },
    sign_up: {
        event_name: 'sign_up',
        data_to_send: getObject(),
    },
    click_tutorial: {
        event_name: 'click_tutorial',
        data_to_send: getObject(),
    },
    tutorial_start: {
        event_name: 'tutorial_start',
        data_to_send: getObject(),
    },
    tutorial_completed: {
        event_name: 'tutorial_completed',
        data_to_send: getObject(),
    },
    tutorial_progress: {
        event_name: 'tutorial_progress',
        data_to_send: { ...getObject(), percentage: 0 },
    },
    download: {
        event_name: 'download',
        type: { mac: 'mac', windows: 'windows', android: 'android' },
        data_to_send: { type: '', userID: '' },
    },
    click_play_webgl: {
        event_name: 'click_play_webgl',
        data_to_send: { userID: '' },
    },
    click_galaxy_pass: {
        event_name: 'click_galaxy_pass',
        data_to_send: { userID: '' },
    },
    click_wallets: {
        event_name: 'click_wallets',
        data_to_send: { userID: '' },
    },
    wallet_connected: {
        event_name: 'wallet_connected',
        data_to_send: { userID: '' },
    },
    click_quest: {
        event_name: 'click_quest',
        data_to_send: { type: '' },
    },
    quest_completed: {
        event_name: 'quest_completed',
        data_to_send: {
            type: '',
            name: '',
            round: '',
            typology: '',
            number: 0,
            points: 0,
            max_submissions: 0,
        },
    },
};
