import React from "react";

import styles from "./styles.module.scss";
import OrderDetails from "./components/OrderDetails";
import Payment from "./components/Payment";
import { useCart } from "../../context/CartProvider";

// As the cart button will sync the cart on mount, we don't need to sync the cart on the cart page.
function CartPage() {
  const { products, totalPrice, isCartSyncing } = useCart();
  const isEmpty = !products || products?.length === 0;

  return (
    <div className={styles.wrapper}>
      <h1>CART</h1>
      <p className={styles.wrapper__description}>
        Check and complete the order
      </p>
      <div className={styles.wrapper__content}>
        <OrderDetails products={products} isLoading={isCartSyncing} />
        {!isEmpty && <Payment totalPrice={totalPrice} />}
      </div>
    </div>
  );
}

export default CartPage;
