import NeonCard from "common/neoncard";
import React from "react";

import styles from "./styles.module.scss";

function ErrorIndicator() {
  return (
    <NeonCard classname={styles.card}>
      <h1 className={styles.card__title}>Oops! Something went wrong!</h1>
      <p className={styles.card__description}>
        We are sorry, but we have encountered an error. Please try again later.
      </p>
    </NeonCard>
  );
}

export default ErrorIndicator;
