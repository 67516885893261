import React from "react";

import styles from "./styles.module.scss";
import NeonCard from "@common/neoncard";
import classNames from "classnames";
import { HAS_HOVER_CLASS_NAME } from "@common/watchForHover";
import { useNavigate } from "react-router-dom";
import CardBody from "./CardBody";

function ProductCardSkeleton() {
  return <NeonCard className={styles.pulse}></NeonCard>;
}

function ProductCard({ product }) {
  const shouldAddHoverOnCard =
    document.body.classList.contains(HAS_HOVER_CLASS_NAME);
  const [hoverEffect, setHoverEffect] = React.useState(shouldAddHoverOnCard);
  const navigate = useNavigate();

  return (
    <NeonCard
      className={classNames(styles.card, "shop-card", {
        hover: hoverEffect,
      })}
      onClick={() => {
        navigate(`/shop/${product.id}`);
      }}
    >
      <CardBody product={product} setHoverEffect={setHoverEffect} />
    </NeonCard>
  );
}

ProductCard.Skeleton = ProductCardSkeleton;
export default ProductCard;
