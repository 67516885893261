import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play: false,
  data: null,
  loading: false,
};

export const PlayingSlice = createSlice({
  name: "playing",
  initialState,
  reducers: {
    setplay: (state, action) => {
      state.play = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setplay } = PlayingSlice.actions;

export default PlayingSlice.reducer;
