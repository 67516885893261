import InformationModal from "common/InformationModal";

function RevenueShareModal({ isOpen, close }) {
  return (
    <InformationModal
      isOpen={isOpen}
      close={close}
      title="Boosters Revenue Share"
    >
      <p>
        When you <strong>purchase a booster</strong>, you'll be prompted to{" "}
        <strong>choose</strong> one of the
        <strong> participating projects</strong> in the tournament.&nbsp; This{" "}
        <strong>procedure</strong> is necessary because the{" "}
        <strong>revenue</strong> generated by the boosters will be distributed
        as follows:
      </p>
      <ul>
        <li>
          <strong>50%</strong> is allocated to the{" "}
          <strong>prize pool for players</strong>
        </li>
        <li>
          <strong>25%</strong> is directed towards the{" "}
          <strong>selected project</strong> at the time of booster acquisition
        </li>
        <li>
          <strong>10%</strong> is designated for <strong>Intraverse</strong>
        </li>
        <li>
          <strong>10%</strong> benefits Galaxy <strong>Pass holders</strong>
        </li>
        <li>
          <strong>5%</strong> is reserved for the{" "}
          <strong>project's prize pool</strong>
        </li>
      </ul>
    </InformationModal>
  );
}

export default RevenueShareModal;
