export default function composeProviders(providers) {
  return providers.reduce((Prev, Curr) => {
    const ComposeProviders = ({ children }) => (
      <Prev>
        <Curr>{children}</Curr>
      </Prev>
    );

    return ComposeProviders;
  });
}
