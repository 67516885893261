import { useNotification } from "../../../hooks";
import NFTDetails from "./components/NFTDetails";
import { useEffect } from "react";

import React from "react";
import { Layout as AntdLayout, ConfigProvider } from "antd";
import Header from "./components/Header";
import styles from "./styles.module.scss";

import darkMapTokens from "theme/antDDarkMapTokens";
import BoosterDetails from "./components/BoosterDetails";
import { useREALAuth } from "context/AuthProvider";
import LoginError from "./components/LoginError";
import useBoosterHistory from "./hooks/useBoosterHistory";

function InventoryLayout() {
  const query = new URLSearchParams(window.location.search);
  const status = query.get("status");

  const { auth } = useREALAuth();
  const {
    boosters,
    revalidate,
    isLoading: isHistoryLoading,
  } = useBoosterHistory();

  const { openNotification } = useNotification();

  useEffect(() => {
    if (status === "success") {
      openNotification("Your purchase was successful!", "success", 5);
    } else if (status === "failed") {
      openNotification("Purchase failed!", "error", 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This config provider is not global as it is risky to change the theme of the whole application
  // We have provided the theme to the Shop Admin Panel too.
  return (
    <ConfigProvider
      theme={{
        token: darkMapTokens,
      }}
    >
      <AntdLayout className={styles.layout}>
        <Header />
        <main className={styles.layout__main}>
          <div className={styles.layout__main__content}>
            {!auth && <LoginError />}
            {auth && <NFTDetails revalidate={revalidate} />}
            {auth && (
              <BoosterDetails
                boosters={boosters}
                isLoading={isHistoryLoading}
                revalidate={revalidate}
              />
            )}
          </div>
        </main>
      </AntdLayout>
    </ConfigProvider>
  );
}

export default InventoryLayout;
