import { setProfile } from "../../Store/Features/ProfileSlice";
import { load, unload } from "../../Store/Features/loadingSlice";
import useNotification from "../useNotifcation";
import { auth } from "../../config/firebase";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { setquestsubmissions } from "../../Store/Features/modalSlice";

export const useLogout = () => {
  const { openNotification } = useNotification();
  const dispatch = useDispatch();
  const handlelogout = async (flag) => {
    try {
      if (flag !== false) {
        dispatch(load());
        dispatch(setquestsubmissions([]));
        localStorage.removeItem("uid");
        sessionStorage.removeItem("walletinSession");
        localStorage.removeItem("email");
        localStorage.removeItem("pass");
        localStorage.removeItem("pas");
        sessionStorage.removeItem("userquests");
        if (flag !== "no message") {
          dispatch(setProfile(false));
          openNotification("signed out success", "success");
        }

        await signOut(auth);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
    }
  };
  const handleFullylogout = async (flag) => {
    try {
      dispatch(load());
      dispatch(setquestsubmissions([]));
      localStorage.removeItem("email");
      localStorage.removeItem("pass");
      localStorage.removeItem("pas");
      dispatch(setProfile(false));
      openNotification("signed out success", "success");

      await signOut(auth);
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
    }
  };
  const handleLogoutNoNotification = async (flag) => {
    try {
      localStorage.removeItem("email");
      localStorage.removeItem("pas");
      dispatch(setProfile(false));
      openNotification("signed out success", "success");

      await signOut(auth);
    } catch (e) {
      console.log(e);
    }
  };
  return { handlelogout, handleFullylogout, handleLogoutNoNotification };
};
