import React from "react";
import { Route, Routes } from "react-router-dom";
import Intraverse from ".";
import LayoutInventory from "./Inventory";
import NftwcQuests from "./nftwcQuests";

import { ShopLayout } from "../Shop";
import ShopPage from "../Shop/pages/ShopPage";
import CartPage from "../Shop/pages/CartPage";
import ProductPage from "../Shop/pages/ProductPage";
import { ProjectsProvider } from "context/ProjectsProvider";
import { ProfileProvider } from "context/ProfileProvider";
import { ActiveBoosterProvider } from "context/ActiveBoosterProvider";
import composeProviders from "@utils/composeProviders";

function IntraverseRoutes() {
  // AuthProvider is provided on top of the whole application
  const Providers = composeProviders([
    ProjectsProvider,
    ProfileProvider,
    ActiveBoosterProvider,
  ]);

  return (
    <Providers>
      <Routes>
        <Route path="/" element={<Intraverse />} />
        <Route path="/inventory" element={<LayoutInventory />} />
        <Route path="/shop" element={<ShopLayout />}>
          <Route path="/shop/" element={<ShopPage />} />
          <Route path="/shop/cart" element={<CartPage />} />
          <Route path="/shop/:id" element={<ProductPage />} />
        </Route>
        <Route path="/nftwc/quests/:name/:id/*" element={<NftwcQuests />} />
      </Routes>
    </Providers>
  );
}

export default IntraverseRoutes;
