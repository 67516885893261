import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  setmodalopen,
  setselectedquest,
} from "../../../../Store/Features/modalSlice";
import DisplayCard from "./DisplayCard";
import { auth } from "../../../../config/firebase";
import { useParams, useLocation } from "react-router-dom";
import {
  useRealTimedb,
  useNotification,
  useIsRoundDuration,
} from "../../../../hooks";
import CaliGraphy from "../../../../common/Caligraphy";
import Filters from "./Filters";

const PagniatedCol = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const quests = useSelector((state) => state.modal.quests);
  let s = useSelector((s) => s.modal.questsubmissions);
  const mySubmissions = useMemo(
    () => s?.filter((e) => e?.projectId === id),
    [s]
  );
  // mySubmissions = mySubmissions?.filter((e) => e?.projectId === id);
  const data = pathname.includes("submissions") ? mySubmissions : quests;

  const isMyApps = pathname.includes("submissions");

  const categories = useSelector((state) => state.modal.categories);
  const [paginatedData, setpaginatedData] = useState();
  const [active, setActive] = useState("all");

  useEffect(() => {
    setitem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quests, pathname, mySubmissions, auth.currentUser?.uid]);

  const setitem = async () => {
    let d = data?.map((e) => {
      const questId = e?.id;
      const projectId = id;
      const completedBy = auth?.currentUser?.uid;
      const status = "rejected";

      const timesCanBeCompleted = e?.timesCanBeCompleted;
      var filtered = mySubmissions?.filter((e) =>
        !isMyApps
          ? e?.questId === questId &&
            e?.completedBy === completedBy &&
            e?.status != status &&
            e?.projectId === projectId
          : // &&  e?.roundId === activeround?.id
            e?.id === id
      );
      const usedAll = timesCanBeCompleted <= filtered?.length ? true : false;
      const attempted = filtered?.length;

      return { ...e, usedAll: usedAll ? true : false, attempted };
    });
    if (isMyApps) setpaginatedData(d);
    else {
      const filter = d?.filter(
        (e) => !e?.usedAll && e?.projectIds?.includes(id)
      );
      setpaginatedData(filter);
      // setpaginatedData(d);
    }
  };

  const totalApplications = Array.isArray(data) ? data?.length : null;
  var totalRejected = 0;
  var totalApproved = 0;
  var totalPending = 0;
  if (Array.isArray(mySubmissions)) {
    totalRejected = mySubmissions?.filter((e) => e?.status === "rejected");
    totalRejected = totalRejected?.length;
    totalPending = mySubmissions?.filter((e) => e?.status === "pending");
    totalPending = totalPending?.length;
    totalApproved = mySubmissions?.filter((e) => e?.status === "approved");
    totalApproved = totalApproved?.length;
  }

  const categoriesToRender = categories?.find((e) => e?.id === "nftwc");

  return (
    <Fragment>
      <Col
        span={24}
        className="DisplayQuestRow"
        style={{ marginBottom: "8rem" }}
      >
        <CaliGraphy
          style={{
            fontSize: "1rem",
            marginBottom: "1rem",
            letterSpacing: "unset",
            lineHeight: "unset",
          }}
          rows={5}
          content={`Note: Once you select to submit social quests to get points for this projects, you will be disabled to submit social quests for other projects.`}
          type="text"
        />
        {isMyApps ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".8rem",
              flexWrap: "wrap",
              marginLeft: ".5rem",
            }}
          >
            <div>Total: {totalApplications}</div>
            <div>Approved: {totalApproved}</div>
            <div>Pending: {totalPending}</div>
            <div>Rejected: {totalRejected}</div>
          </div>
        ) : null}
        <Filters setActive={setActive} active={active} />

        {categoriesToRender?.categories?.map((cat, i) => {
          return (
            <GetRow
              paginatedData={paginatedData}
              key={i}
              cat={cat}
              active={active}
            />
          );
        })}

        {paginatedData?.length === 0 && active === "all" ? (
          <Col span={24} className="noData">
            No Data...!
          </Col>
        ) : null}
      </Col>
    </Fragment>
  );
};

const GetRow = ({ paginatedData, cat, active }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { rtdata } = useRealTimedb();
  const { id } = useParams();
  const { isValidDurtion } = useIsRoundDuration();

  const isMyApps = pathname.includes("submissions");
  const questsubmissions = useSelector((state) => state.modal.questsubmissions);
  const projects = useSelector(
    (state) => state.leaderboards.projectsLeaderBoard.data
  );
  const filtered = paginatedData?.filter((e) =>
    isMyApps ? e?.categories === cat : e?.categories == cat
  );
  const { openNotification } = useNotification();
  if (!projects) return;
  if (filtered?.length === 0 && active === "all") return null;
  if (active === "all" || active === cat)
    return (
      <Row
        style={{ marginBottom: "3rem" }}
        gutter={[
          { xs: 5, sm: 5, md: 15, lg: 20, xl: 20 },
          { xs: 12, sm: 12, md: 15, lg: 20, xl: 20 },
        ]}
        justify={"start"}
      >
        {active === "all" ? (
          <Col span={24}>
            <h1
              style={{
                fontSize: "1.4rem",
              }}
            >
              {cat}
            </h1>
          </Col>
        ) : null}
        {filtered?.map((e, i) => {
          return (
            <Col
              onClick={() => {
                let enableNftwc = rtdata?.enableNftwcQuests;
                let checkPass = rtdata?.passCheckReal;
                if (!auth.currentUser?.uid) {
                  return openNotification("Login First!");
                }
                if (questsubmissions?.length > 0) {
                  const ProjectId = id;
                  const find = questsubmissions?.find(
                    (e) => e?.projectId !== ProjectId
                  );
                  if (find) {
                    const name = projects?.find(
                      (e) => e?.projectId === find?.projectId
                    );
                    return openNotification(
                      `You are already doing quests for  ${name?.projectName}`
                    );
                  }
                }
                if (isMyApps) {
                  return;
                }
                const duration = isValidDurtion();
                if (!duration) {
                  return openNotification("Quests are disabled!");
                }
                // if (enableNftwc === 0) {
                //   return openNotification("Quests are disabled!");
                // }
                // if (checkPass === 1 && !user?.holdingIntraversePass) {
                //   return openNotification(
                //     "Access opened for galaxy pass holders only"
                //   );
                // }
                if (e?.usedAll)
                  openNotification("You have used all the attempts");
                dispatch(setmodalopen("quests"));
                dispatch(setselectedquest(e));
              }}
              key={i}
              xxl={6}
              xl={8}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="DisplayQuestCol"
            >
              <DisplayCard data={e} isMyApps={isMyApps} />
            </Col>
          );
        })}
        {filtered?.length === 0 ? (
          <Col span={24} className="noData">
            No Data...!
          </Col>
        ) : null}
      </Row>
    );
};
export default PagniatedCol;
