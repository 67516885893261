import { Select } from "antd";
import { CSSProperties, Fragment, useRef } from "react";
import useNotification from "../../hooks/useNotifcation";

/**
 * @param {{
 *   type: string,
 *   value?: string,
 *   setvalue?: ()=>void,
 *   onClear?: ()=>void,
 *   style?: CSSProperties,
 *   placeholder?: string
 *   mode?: string
 *   options?: Array
 * }} props
 */
const SELECT = ({
  value,
  mode = "single",
  setvalue = () => {},
  onClear = () => {},
  style,
  placeholder,
  options,
  OtherGroupsSelectedProjects = [],
  source = undefined,
}) => {
  const s = useRef();
  const { openNotification } = useNotification();
  return (
    <Fragment>
      <span ref={s} />
      <Select
        getPopupContainer={(e) => (e = s.current)}
        size={"middle"}
        mode={mode}
        allowClear
        onClear={onClear ? onClear : undefined}
        className="select"
        popupClassName="selectdropdown"
        placeholder={placeholder}
        value={value ? value : undefined}
        onChange={(e) => {
          if (source !== undefined) {
            const id = e?.[e?.length - 1];
            const findId = OtherGroupsSelectedProjects?.find((o) => o === id);
            if (findId) {
              return openNotification("Project is selected in other group!");
            }
            if (source?.find((s) => s === id)) setvalue(e);
          } else {
            setvalue(e);
          }
        }}
        style={style ? style : undefined}
        options={options}
      />
    </Fragment>
  );
};

export default SELECT;
