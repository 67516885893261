import React from "react";

import styles from "./styles.module.scss";
import BoosterAttributes from "@common/BoosterAttributes";
import ActivateButton from "./ActivateButton";

function CardBody({ booster, userHasActiveBooster, revalidate }) {
  return (
    <div className={styles.body}>
      <div className={styles.body__image_wrapper}>
        <img
          className={styles.body__image_wrapper__image}
          alt="booster"
          src={booster.imageUrl}
        />
      </div>
      <div className={styles.body__content}>
        <div>
          <BoosterAttributes
            duration={booster.boosterDuration}
            percentage={booster.boosterValue}
          />
          <p className={styles.body__content__title}>{booster.name}</p>
        </div>
        <div className={styles.body__content__activation}>
          <ActivateButton
            booster={booster}
            revalidate={revalidate}
            userHasActiveBooster={userHasActiveBooster}
          />
        </div>
      </div>
    </div>
  );
}

export default CardBody;
