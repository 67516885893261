import { FaDollarSign } from "react-icons/fa";
import styled from "styled-components";

export const FlexStyled = (props) => {
  const children = props.children;

  return (
    <div
      onClick={props?.onClick}
      style={{
        display: "flex",
        flexDirection: props.flexdirection || "row",
        justifyContent: props.justifycontent || "flex-start",
        alignItems: props.alignitems,
        height: props.height,
        boxshadow: props.boxshadow,
        width: props.width || "100%",
        border: props.border || 0,
        margin: props.margin || 0,
        padding: props.padding || 0,
        gap: props.gap || 0,
        ...props?.style,
      }}
    >
      {children}
    </div>
  );
};
export const GetDollarSignStyled = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexdirection || "row"};
  justify-content: ${(props) => props.justifycontent || "flex-start"};
  align-items: ${(props) => props.alignitems || "flex-start"};
  height: ${(props) => props.height || ""};
  box-shadow: ${(props) => props.boxshadow || "0"};
  width: ${(props) => props.width || "100%"};
  border: ${(props) => props.border || "0"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  gap: ${(props) => props.gap || "0"};
`;
const GetGetDollarSignStyled = styled(FaDollarSign)`
  top: 3px;
  left: -4px;
  position: relative;
  fontsize: 1.02rem;
  ${(props) => props.style};
`;
const GetNFTSignStyled = styled.img`
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  top: -1px;
  ${(props) => props.style};
`;

export const GetDollarSign = ({ styles }) => {
  return (
    <GetGetDollarSignStyled
      style={{
        ...styles,
      }}
    />
  );
};
export const GetNFTSign = ({ styles }) => {
  return (
    <GetNFTSignStyled
      style={{
        width: "1.3rem",
        height: "1.3rem",
        position: "relative",
        top: "-1px",
        ...styles,
      }}
      src="/assets/nft/nft2.png"
      alt="nft"
    />
  );
};
