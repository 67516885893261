import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";

import styles from "./styles.module.scss";
import Header from "./Header";
import { useProducts } from "../../context/ProductProvider";
import ErrorIndicator from "./ErrorIndicator";
import classNames from "classnames";
import { FaRegQuestionCircle } from "react-icons/fa";
import RevenueShareModal from "./RevenueShareModal/indes";
import { useNavigate } from "react-router-dom";

function ShopPage() {
  const { products, isLoading, error, fetchProducts } = useProducts();
  const [isRevenueShareModalOpen, setIsRevenueShareModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ErrorIndicator />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.wrapper__bottom_bar}>
          <span
            className={styles.wrapper__bottom_bar__claim}
            onClick={() => {
              navigate("/inventory");
            }}
          >
            Claim boosters for free
          </span>
          <div
            className={styles.wrapper__bottom_bar__info}
            onClick={() => {
              setIsRevenueShareModalOpen(true);
            }}
          >
            <FaRegQuestionCircle />
            <span>Boosters revenue share</span>
          </div>
        </div>
        <div
          className={classNames(styles.container, {
            [styles["container--loading"]]: isLoading,
          })}
        >
          {isLoading && products.length === 0
            ? [1, 2, 3, 4].map((key) => <ProductCard.Skeleton key={key} />)
            : products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
        </div>
      </div>
      <RevenueShareModal
        isOpen={isRevenueShareModalOpen}
        close={() => {
          setIsRevenueShareModalOpen(false);
        }}
      />
    </>
  );
}

export default ShopPage;
