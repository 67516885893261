import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../config/firebase";

export const useGetImage = () => {
  const getimage = async (path) => {
    try {
      //   const imageRef = ref(storage, path);
      //   const imageURL = await getDownloadURL(imageRef);
      //   return imageURL;

      const imageRef = ref(storage, path);

      // Return a Promise that resolves with the imageURL when it is fetched successfully
      return getDownloadURL(imageRef)
        .then((imageURL) => {
          return imageURL;
        })
        .catch((error) => {
          console.error("Error fetching image URL:", error);
          return null;
        });
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return null;
    }
  };

  return { getimage };
};

export default useGetImage;
