// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__8HbPp {
  text-align: center;
  color: white;
  margin: 0 !important;
  padding: 6px 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: unset !important;
  font-size: 0.9rem;
  height: unset !important;
}
.styles_button--disabled__0QHKm {
  opacity: 0.2;
  cursor: not-allowed;
}

.styles_select__Qe2xV {
  width: 100%;
}

.styles_select_wrapper__I6DpQ {
  width: 100%;
  margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/components/AddToCartButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,oBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,iBAAA;EACA,wBAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;AAAF","sourcesContent":[".button {\n  text-align: center;\n  color: white;\n  margin: 0 !important;\n  padding: 6px 18px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  min-height: unset !important;\n  font-size: 0.9rem;\n  height: unset !important;\n\n  &--disabled {\n    opacity: 0.2;\n    cursor: not-allowed;\n  }\n}\n\n.select {\n  width: 100%;\n}\n\n.select_wrapper {\n  width: 100%;\n  margin-bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__8HbPp`,
	"button--disabled": `styles_button--disabled__0QHKm`,
	"select": `styles_select__Qe2xV`,
	"select_wrapper": `styles_select_wrapper__I6DpQ`
};
export default ___CSS_LOADER_EXPORT___;
