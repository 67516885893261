import axios from "axios";
import { auth } from "./firebase";
import { base_auth_url, base_private_url, base_public_url } from "./constants";

export const publicrequest = async ({ data = {}, endpoint }) => {
  const response = await axios.post(`${base_public_url}${endpoint}`, data);
  return response;
};
export const authrequest = async ({ data = {}, endpoint }) => {
  const idtoken = await auth.currentUser?.getIdToken();
  const response = await axios.post(`${base_auth_url}${endpoint}`, data, {
    headers: { Authorization: `Bearer ${idtoken}` },
  });
  return response;
};
export const privaterequest = async ({ data = {}, endpoint }) => {
  const idtoken = await auth.currentUser?.getIdToken();
  const response = await axios.post(`${base_private_url}${endpoint}`, data, {
    headers: { Authorization: `Bearer ${idtoken}` },
  });
  return response;
};
export const getFetch = async (endpoint) => {
  const idtoken = await auth.currentUser?.getIdToken();
  const response = await axios.get(`${base_private_url}${endpoint}`, {
    headers: { Authorization: `Bearer ${idtoken}` },
  });
  return response;
};
