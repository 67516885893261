import { BrowserRouter } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { store } from "./Store/store";
import "antd/dist/reset.css";
import "./styles/app.scss";
import React from "react";
import App from "./App";

import { WagmiWrapperProvider } from "./chainsWrappers/walletconnect";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { REALAuthProvider } from "context/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <WagmiWrapperProvider>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <REALAuthProvider>
            <App />
          </REALAuthProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </WagmiWrapperProvider>
);
