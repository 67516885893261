// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_body__KiWGf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.styles_body__title__fvgw3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.styles_body__select_wrapper__s5MNu {
  width: 100%;
  margin-bottom: 1rem;
}
.styles_body__select__GCfef {
  width: 100%;
}
.styles_body__submit_button__jdAKu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/components/AddToCartButton/ProjectSelectorModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AACI;EACI,iBAAA;EACA,mBAAA;AACR;AAEI;EACI,WAAA;EACA,mBAAA;AAAR;AAGI;EACI,WAAA;AADR;AAII;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFR","sourcesContent":[".body {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 1rem;\n\n    &__title {\n        font-size: 1.5rem;\n        margin-bottom: 1rem;\n    }\n\n    &__select_wrapper {\n        width: 100%;\n        margin-bottom: 1rem;\n    }\n\n    &__select {\n        width: 100%;\n    }\n\n    &__submit_button {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `styles_body__KiWGf`,
	"body__title": `styles_body__title__fvgw3`,
	"body__select_wrapper": `styles_body__select_wrapper__s5MNu`,
	"body__select": `styles_body__select__GCfef`,
	"body__submit_button": `styles_body__submit_button__jdAKu`
};
export default ___CSS_LOADER_EXPORT___;
