import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth, db } from "../../config/firebase";
import { useLocation } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";

import {
  setPlayerLeaderBoard,
  setProjectsLeaderBoard,
  setreferralLeaderBoard,
} from "../../Store/Features/leaderboard";
import { publicrequest } from "../../config/api";
import { withBoosterBaseUrl } from "utils/utils";

const mapOfDistributionForBoosterReward = {
  1: 0.1,
  2: 0.1,
  3: 0.1,
  4: 0.05,
  5: 0.05,
  6: 0.05,
  7: 0.05,
  8: 0.05,
  9: 0.05,
  10: 0.05,
  11: 0.05,
  12: 0.04,
  13: 0.04,
  14: 0.04,
  15: 0.03,
  16: 0.03,
  17: 0.03,
  18: 0.03,
  19: 0.03,
  20: 0.03,
};

export const useFetchLeaderboards = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const getReferralLeaderboard = async () => {
    try {
      dispatch(
        setreferralLeaderBoard({
          loading: true,
          data: [],
        })
      );
      let res = await publicrequest({
        endpoint: `getReferralLeaderBoardV1`,
        data: {},
      });
      const data = res.data;
      dispatch(
        setreferralLeaderBoard({
          loading: false,
          data,
        })
      );
    } catch (e) {
      dispatch(
        setreferralLeaderBoard({
          loading: false,
          data: [],
        })
      );
    }
  };

  useEffect(() => {
    if (pathname !== "/") return;
    dispatch(setPlayerLeaderBoard({ loading: true }));
    dispatch(setProjectsLeaderBoard({ loading: true }));

    const fetchBoard = async () => {
      try {
        // This will return sum of all boosters revenue for latest round
        const response = await fetch(
          withBoosterBaseUrl(`/revenue/latest`)
        );
        const value = await response.json();
        const totalIncomeFromBoosters = value.value ?? 0;

        const disturbedIncomeFromBoosters = totalIncomeFromBoosters * 0.5;
        const collectionRef = collection(db, "leaderboardnftwc");

        onSnapshot(collectionRef, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();

            if (doc?.id === "leaderboard") {
              const d = data.data;
              d.sort((a, b) => {
                const bvals = (b?.totalQuestPoint || 0) + (b?.totalPoints || 0);
                const avals = (a?.totalQuestPoint || 0) + (a?.totalPoints || 0);
                return bvals - avals;
              });
              dispatch(setProjectsLeaderBoard({ loading: false, data: d }));
            } else if (doc?.id === "playerleaderboard") {
              const d = data.data;
              d.sort((a, b) => {
                const bvals = (b?.totalQuestPoint || 0) + (b?.totalPoints || 0);
                const avals = (a?.totalQuestPoint || 0) + (a?.totalPoints || 0);
                return bvals - avals;
              });

              d.map((item, index) => {
                const coefficient =
                  mapOfDistributionForBoosterReward[index + 1];
                if (coefficient) {
                  item.boosterReward = (
                    disturbedIncomeFromBoosters * coefficient
                  ).toFixed(0);
                }
                return item;
              });

              dispatch(setPlayerLeaderBoard({ loading: false, data: d }));
            }
          });
        });
      } catch (error) {
        dispatch(setPlayerLeaderBoard({ loading: false }));
        dispatch(setProjectsLeaderBoard({ loading: false }));
        console.log({ error: error.message });
      }

      getReferralLeaderboard();
    };

    fetchBoard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser, pathname]);

  return null;
};
