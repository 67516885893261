import { Input } from "antd";
import { CSSProperties } from "react";

/**
 * @param {{
 *   type: string,
 *   value?: string,
 *   setvalue?: ()=>void,
 *   style?: CSSProperties,
 *   placeholder?: string
 * }} props
 */
const INPUT = ({
  type,
  value,
  setvalue = () => {},
  style,
  placeholder,
  accept,
  setimage,
  decimals,
  max,
}) => {
  return (
    <Input
      className="inputwrapper"
      type={type ? type : "text"}
      value={value}
      accept={accept}
      allowClear={type === "file"}
      placeholder={placeholder}
      style={style ? style : undefined}
      onChange={(e) => {
        if (type === "file") {
          if (String(e.currentTarget.value).trim().length < 4) {
            setimage(null);
          } else {
            setvalue(e);
          }
        } else {
          if (type === "number") {
            const func = decimals ? parseFloat : parseInt;
            let num = Math.abs(func(e.currentTarget.value));

            if (max !== undefined) {
              if (num > max) {
                setvalue(max);
              } else setvalue(num || 0);
            } else setvalue(num || 0);
          } else setvalue(e.currentTarget.value);
        }
      }}
    />
  );
};

export default INPUT;
