import { Typography } from "antd";
import { CSSProperties } from "react";

/**
 * @param {{
 *   content: string,
 *   rows?: number,
 *   level?: number,
 *   type?: string,
 *   classname?: string,
 *   fonstSize?: string  ,
 *   style?: CSSProperties,
 *   isHtml?: boolean,
 *   scrollAble?: boolean
 * }} props
 */

const CaliGraphy = (props) => {
  const {
    content,
    rows = 1,
    style = {},
    type = "text",
    level = 1,
    classname,
    isHtml,
    scrollAble,
  } = props;

  let stylesToGive = {
    ...style,
  };
  if (type === "text") {
    stylesToGive = {
      ...stylesToGive,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: rows,
    };
  }

  if (isHtml) {
    return (
      <Typography
        style={{
          ...stylesToGive,
          color: "white",
          display: "block",
          height: "calc(100% - 11%)",
          overflowY: "auto !important",
        }}
        className={`${classname ? classname : ""} Caligraphy Paragraph ${
          scrollAble ? "VerticalScrollBar" : ""
        }`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
  return (
    <Typography>
      {type === "text" ? (
        <Typography
          style={stylesToGive}
          className={`${classname ? classname : ""} Caligraphy Paragraph`}
          ellipsis={{ rows: rows }}
        >
          {content}
        </Typography>
      ) : (
        <Typography.Title
          style={stylesToGive}
          className={`${classname ? classname : ""} Caligraphy Title`}
          ellipsis={{ rows: rows ? rows : 1 }}
          level={level}
        >
          {content}
        </Typography.Title>
      )}
    </Typography>
  );
};
export default CaliGraphy;
