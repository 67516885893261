// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_description__2j6CR {
  width: 100%;
  font-size: 16px;
}
.styles_description__2j6CR .ant-tabs-tab-btn {
  font-size: 20px;
}

.styles_content__dte5b {
  font-size: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/pages/ProductPage/components/Description/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,0BAAA;AAAF","sourcesContent":[".description {\n  width: 100%;\n  font-size: 16px;\n\n  :global(.ant-tabs-tab-btn) {\n    font-size: 20px;\n  }\n}\n\n.content {\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `styles_description__2j6CR`,
	"content": `styles_content__dte5b`
};
export default ___CSS_LOADER_EXPORT___;
