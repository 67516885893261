import {
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { useEffect, useState } from "react";

export const useFirestore = () => {
  const fireStoreDb = db;

  const addOrUpdate = async (id, data, collectionName) => {
    const document = doc(fireStoreDb, collectionName, id);
    await setDoc(document, data);
  };

  const get = async (id, collectionName) => {
    const documentRef = doc(fireStoreDb, collectionName, id);
    const documentSnapshot = await getDoc(documentRef);
    if (!documentSnapshot.exists()) return null;
    const entity = { ...documentSnapshot.data(), id: documentSnapshot.id };
    return entity;
  };

  const getAll = async (collectionName) => {
    const querySnapshot = await getDocs(
      collection(fireStoreDb, collectionName)
    );
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  };

  const whereDocumentIn = async (ids, collectionName) => {
    const results = [];
    for (const id of ids) {
      const document = await get(id, collectionName);
      if (document) results.push(document);
    }
    return results;
  };

  const whereEqualTo = async (fieldPath, value, collectionName) => {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, collectionName),
        where(fieldPath, "==", value)
      )
    );
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  };

  const whereMultipleConditions = async (conditions, collectionName) => {
    let collectionRef = collection(fireStoreDb, collectionName);
    conditions.forEach((condition) => {
      collectionRef = query(
        collectionRef,
        where(condition.fieldPath, condition.opStr, condition.value)
      );
    });

    const querySnapshot = await getDocs(collectionRef);
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  };
  const useSnapshot = (collectionName, conditions) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      let unsubscribe;

      const fetchData = async () => {
        if (conditions.some((e) => e.value === undefined)) {
          console.log("Invalid conditions, returning");
          return;
        }

        const collectionRef = collection(fireStoreDb, collectionName);
        const filteredCollection = conditions.reduce(
          (queryRef, condition) =>
            query(
              queryRef,
              where(condition.fieldPath, condition.opStr, condition.value)
            ),
          collectionRef
        );

        unsubscribe = onSnapshot(filteredCollection, (snapshot) => {
          const newData = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          });
          setData(newData);
        });
      };

      fetchData();

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }, [collectionName, conditions]);

    return data;
  };

  const getDocument = async ({ collectionName, docId }) => {
    const collectionRef = doc(collection(db, collectionName), docId);
    const document = await getDoc(collectionRef);
    if (document.exists()) {
      return document.data();
    } else {
      return null;
    }
  };

  return {
    addOrUpdate,
    get,
    getAll,
    whereDocumentIn,
    whereEqualTo,
    whereMultipleConditions,
    useSnapshot,
    getDocument,
  };
};

export default useFirestore;
