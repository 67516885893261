import { useDispatch } from "react-redux";
import NeonCard from "../../../../../../common/neoncard";
import CaliGraphy from "../../../../../../common/Caligraphy";
import { setActiveLeaderboardTab } from "../../../../../../Store/Features/leaderboard";

function InterVersaVideo() {
  const dispatch = useDispatch();

  return (
    <NeonCard
      className={"nobg hover video quickTutorial"}
      onClick={() => {
        dispatch(setActiveLeaderboardTab(2));
      }}
    >
      <CaliGraphy
        style={{
          margin: "auto",
          width: "100% !imporatant",
          paddingInline: ".3rem",
        }}
        level={2}
        rows={2}
        type="Heading"
        content={`Quick Tutorial`}
        classname="blue shadow"
      />
    </NeonCard>
  );
}

export default InterVersaVideo;
