import { setActiveLeaderboardTab } from "../../../../Store/Features/leaderboard";
import { useDispatch, useSelector } from "react-redux";
import NeonCard from "../../../../common/neoncard";
import RefreshText from "./refershtext";
import LeaderBoardTabs from "./Tabs";
import { useEffect } from "react";
import { Col, Row } from "antd";
import Tabs from "./tabledata";

function ColMiddle() {
  const dispatch = useDispatch();
  const round = useSelector((state) => state.round.data);

  const activeTab = useSelector((state) => state.leaderboards.activeTab);

  useEffect(() => {
    if (round?.roundActive) {
      if (!round?.isGrouped) {
        if (![1, 2, 3].includes(activeTab)) {
          dispatch(setActiveLeaderboardTab(2));
        }
      } else {
        if (![4, 5].includes(activeTab)) {
          dispatch(setActiveLeaderboardTab(4));
        }
      }
    } else {
      dispatch(setActiveLeaderboardTab(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  return (
    <Row className="grid-col-row middle-col" align={"top"} gutter={[0, 2]}>
      <Col span={24} style={{ flex: 1 }}>
        <RefreshText />
        <NeonCard className={"ranking  removeclippath"}>
          <Row className="ranking" style={{ alignItems: "flex-start" }}>
            <LeaderBoardTabs />
            <Col span={24}>
              <Tabs />
            </Col>
          </Row>
        </NeonCard>
      </Col>
    </Row>
  );
}

export default ColMiddle;
