export const KEYS = {
  //Ethereum Mainnet keys
  EthID: 1,
  EthNetwork: "https://mainnet.infura.io/60a360d66de14db98db7e6c870b72bd2",

  // walletconnect key
  WalletConnectID: "ba1b9bf3d773d7b5489f0e061389a5ad", //  => change in production

  // EtherScan Key
  ETHER_SCAN: "SEPY5AAFTAR9VUWAGWPHV1JH5BZNX6C3F3", //   => change in production

  // google spreadsheet key
  SPREADSHEET_ID: "1P_VaEe-GPG7TcSuIibNn0FVnbrQTz9YVQi3sKK6hhqM",

  //Ethereum Testnet keys
  // EthID: 5,
  // EthNetwork: "https://goerli.infura.io/v3/60a360d66de14db98db7e6c870b72bd2",
};
