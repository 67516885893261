import { useDispatch, useSelector } from 'react-redux';
import * as waxjs from '@waxio/waxjs/dist';
import { useAccount } from 'wagmi';
import { setwalletLoading, unload } from '../../Store/Features/loadingSlice';
import { useWeb3Modal } from '@web3modal/react';
import { deleteField } from 'firebase/firestore';
import { CHAINS } from '../../config/urls';
import Connex from '@vechain/connex';
import { useNotification, useCheckWallet, useUpdateUser } from '..';

import { useCardano } from '@cardano-foundation/cardano-connect-with-wallet';
import { useEffect } from 'react';

var wax;
var Vechain;
try {
    wax = new waxjs.WaxJS({
        rpcEndpoint: 'https://wax.greymass.com',
        tryAutoLogin: false,
    });
} catch (E) {}

try {
    Vechain = new Connex.Vendor();
} catch (e) {
    console.log(e);
}

// Start observing the DOM for changes
export const useWallet = () => {
    const user = useSelector((state) => state.profile.profile);
    const dispatch = useDispatch();
    // eth- wagmi
    const { address } = useAccount();
    // eth- wagmi
    const { open, close } = useWeb3Modal();
    const { update } = useUpdateUser('update');
    const { openNotification } = useNotification();
    const { walletcheck, walletcheckSimpler, cardanoconnect } =
        useCheckWallet();
    // cardano
    const {
        connect,
        disconnect,
        signMessage,
        stakeAddress,
        installedExtensions,
    } = useCardano();

    const profile = useSelector((state) => state.profile.profile);

    useEffect(() => {
        return () => {
            disconnect();
            close();
        };
    }, []);

    const connectwallet = async (chain, addressOfWax) => {
        try {
            if (
                chain !== CHAINS.Cardano.chain &&
                chain !== CHAINS.ETHEREUM.chain
            ) {
                dispatch(setwalletLoading(true));
            }
            var add = null;
            if (chain === CHAINS.ETHEREUM.chain) {
                if (!address) return open();
                add = address;
            }
            if (chain === CHAINS.WAX.chain) {
                if (addressOfWax === "cloud") {
                    // it means it is cloud wallet
                    add = await wax.login();
                } else {
                    add = addressOfWax;
                }          
            }

            if (chain === CHAINS.SOLANA.chain) {
                await walletcheck(addressOfWax, CHAINS.SOLANA.chain);
            }
            if (chain !== CHAINS.VCHAIN.chain) {
                if (add) {
                    await walletcheck(add, chain);
                }
            }

            if (chain === CHAINS.VCHAIN.chain) {
                Vechain.sign('cert', {
                    purpose: 'identification',
                    payload: {
                        type: 'text',
                        content: 'connect to Intraverse',
                    },
                })

                    .request()
                    .then(async (r) => {
                        await walletcheck(r.annex.signer, chain);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
            if (chain === CHAINS.Cardano.chain) {
                const isNami = installedExtensions?.find(
                    (e) => e === addressOfWax
                );
                if (!isNami) {
                    return openNotification(`${addressOfWax} wallet not found`);
                }
                connect(addressOfWax)
                    .then(() => {
                        signMessage(
                            'connect to Intraverse!',
                            async (signature, key) => {
                                dispatch(setwalletLoading(true));
                                console.log({ key, signature, stakeAddress });

                                await cardanoconnect({
                                    data: { key, signature },
                                    stakeAddress,
                                }).finally(() => {
                                    disconnect();
                                    dispatch(setwalletLoading(false));
                                });
                            },
                            (error) => {
                                console.log(error);
                                disconnect();
                                dispatch(setwalletLoading(false));
                            }
                        ).catch((e) => {
                            console.log({ error: e });
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
            if (chain !== CHAINS.Cardano.chain) {
                dispatch(setwalletLoading(false));
            }
        } catch (e) {
            console.log(e);
            dispatch(setwalletLoading(false));
        }
    };

    const disconnectwallet = async (chain) => {
        try {
            await update({
                walletaddress: deleteField(),
                nfts: [],
                chain: deleteField(),
                wallets: [],
            });
            await wax.logout();
            disconnect();

            dispatch(unload());
            openNotification(
                `${String(chain)} Wallet Removed from your account!`,
                'success'
            );
            sessionStorage.removeItem('walletinSession');
            dispatch(setwalletLoading(false));
        } catch {
            disconnect();
            dispatch(setwalletLoading(false));
            dispatch(unload());
        }
    };
    const disconnectThenConnectEth = async (address) => {
        try {
            let nfts = profile?.nfts;

            nfts = nfts?.filter(
                (e) => e?.chain !== 'eth' && e?.chain !== 'polygon'
            );

            let wallets = profile?.wallets;
            wallets = wallets?.filter(
                (e) => e?.chain !== CHAINS.ETHEREUM.chain
            );

            await update({
                walletaddress: deleteField(),
                nfts,
                chain: deleteField(),
                wallets,
            });
            disconnect();

            await walletcheckSimpler(address, CHAINS.ETHEREUM.chain);
            dispatch(unload());
            dispatch(setwalletLoading(false));
        } catch {
            dispatch(setwalletLoading(false));
            dispatch(unload());
            disconnect();
        }
    };

    return { connectwallet, disconnectwallet, disconnectThenConnectEth };
};
