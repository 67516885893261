import React from "react";
import { useBreakPoints } from "@/hooks";
import Xs from "./Xs";
import Md from "./Md";

function CartProduct({ product }) {
  const { xs } = useBreakPoints();

  if (xs) {
    return <Xs product={product} />;
  }

  return <Md product={product} />;
}

const CartProductSkeleton = () => {
  const { xs } = useBreakPoints();

  if (xs) {
    return <Xs.Skeleton />;
  }

  return <Md.Skeleton />;
};

CartProduct.Skeleton = CartProductSkeleton;

export default CartProduct;
