import React, { createContext, useState, useContext, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

// The `REALAuthProvider` component is a custom provider that wraps the entire application and provides
// the user object to all components that need it.

// why we have add the REAL prefix to the AuthProvider?
// The REAL prefix is added to the AuthProvider to differentiate it from other authentication providers
// that may be used in the application. This makes it clear that the provider is specifically for the REAL
// authentication service.
function REALAuthProvider({ children }) {
  const [auth, setAuth] = useState(null);

  const response = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(response, (newAuth) => {
      if (newAuth) {
        setAuth(newAuth);
      } else {
        setAuth(null);
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    auth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useREALAuth() {
  return useContext(AuthContext);
}

export { REALAuthProvider, useREALAuth };
