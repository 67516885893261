import NeonCard from "common/neoncard";
import CaliGraphy from "common/Caligraphy";
import { useState } from "react";

import CalculatorModal from "./CalculatorModal";

function Calculator() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <NeonCard
        onClick={() => setIsModalOpen(true)}
        className={"nobg hover"}
        styles={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CaliGraphy
          level={2}
          type="Heading"
          content="Calculator"
          classname="blue shadow"
        />
        <CaliGraphy
          level={1}
          style={{
            fontSize: "0.5rem",
            opacity: "0.7",
          }}
          content="Boosters explained"
          classname="blue shadow"
        />
      </NeonCard>
      <CalculatorModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default Calculator;
