import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

function SimpleCard({ title, children, className }) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {title && <h2 className={styles.wrapper__title}>{title}</h2>}
      <div className={styles.wrapper__body}>{children}</div>
    </div>
  );
}

export default SimpleCard;
