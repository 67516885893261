import { Col, Row } from "antd";
import React, { useState } from "react";
import ModalInput from "../../../../common/Input";
import { BUTTON } from "../../../../common/button";
import { IoEnterOutline } from "react-icons/io5";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth, db } from "../../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../../Store/Features/ProfileSlice";
import { load, unload } from "../../../../Store/Features/loadingSlice";
import {
  useTextEncDecorder,
  useLogout,
  useNotification,
} from "../../../../hooks";
import { doc, getDoc } from "firebase/firestore";
import NeonCard from "../../../../common/neoncard";

function LoginComponent() {
  const loading = useSelector((state) => state.profile.loading);
  const { openNotification } = useNotification();
  const [password, setpassword] = useState("");
  const { handlelogout } = useLogout();
  const [val, setval] = useState("");
  const dispatch = useDispatch();
  const { encryptPassword } = useTextEncDecorder();

  const handlesignin = async () => {
    if (loading) return;
    if (String(val).trim().length < 6)
      return openNotification("Enter a valid email");
    if (!password) return openNotification("Enter password correctly");

    try {
      dispatch(load());

      await signInWithEmailAndPassword(auth, val, password);

      const cu = auth.currentUser;
      if (!cu) openNotification("No Such user is found");
      if (!cu?.emailVerified) {
        await handlelogout(false);
        return openNotification("Email is not verified!");
      }

      localStorage.setItem("email", val);
      localStorage.setItem("pas", await encryptPassword(password));

      const get = async () => {
        try {
          const docRef = doc(db, "users", auth.currentUser?.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            const nfts = data?.nfts;
            const holdingIntraversePass = nfts?.find(
              (e) => e?.attributes && e?.attributes !== "none"
            );
            const { prevCall, lastCall, ...rest } = data;

            dispatch(
              setProfile({
                ...rest,
                holdingIntraversePass: holdingIntraversePass ? true : false,
              })
            );
          } else {
            openNotification("No user data found");
          }
        } catch (error) {
          console.log({ error });
        }
      };
      await get();

      dispatch(unload());
      dispatch(setProfile(false));
    } catch (error) {
      if (auth?.currentUser?.uid) {
        // await handlelogout(false)
      }
      console.log(error);
      if (String(error.message).includes("user-not-found")) {
        openNotification("No such user is found ", "error");
      } else if (error.message.includes("(auth/wrong-password)")) {
        openNotification("Invalid  password", "error");
      } else {
        console.log(error);
        openNotification(error?.message || "signin error", "error");
      }
      dispatch(unload());
    }
  };
  return (
    <NeonCard
      classname="admincard"
      styles={{
        boxShadow: "none",
        width: "600px",
        maxWidth: "100%",
        margin: "auto",
        height: "max-content",
        padding: "2rem ",
        paddingTop: "2.8rem",
      }}
    >
      <Row
        justify={"center"}
        gutter={[0, 20]}
        onKeyUp={(e) => {
          if (e?.key === "Enter") {
            handlesignin();
          }
        }}
      >
        <Col span={24}>
          <ModalInput
            type={"email"}
            label={"E-Mail"}
            value={val}
            placeholder="Email"
            setvalue={setval}
            style={{ color: "white", paddingBlock: ".5rem" }}
          />
        </Col>
        <Col span={24}>
          <ModalInput
            type={"password"}
            label={"Password"}
            placeholder="Password"
            style={{ color: "white", paddingBlock: ".5rem" }}
            value={password}
            setvalue={setpassword}
          />
        </Col>
      </Row>

      <Row
        align={"middle"}
        gutter={[20, 20]}
        className="interversaBtnRow"
        style={{ marginTop: "2rem" }}
      >
        <Col sm={12} xs={24}>
          <BUTTON
            text={"Login"}
            icon={<IoEnterOutline />}
            onClick={() => {
              handlesignin();
            }}
          />
        </Col>
      </Row>
    </NeonCard>
  );
}

export default LoginComponent;
