import { Button, Col, Layout, Menu, Row } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NFTWCQUESTS } from "../../../config/urls";
import { signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import NeonCard from "../../../common/neoncard";
import { setProfile } from "../../../Store/Features/ProfileSlice";
import Login from "./Login";
import CaliGraphy from "../../../common/Caligraphy";
import Flexed from "../../../common/Styled/Flexed";
import { useBreakPoints } from "../../../hooks";

const { Header, Content, Sider } = Layout;

const GeneralQuestLayout = () => {
  const [toggle, settoggle] = useState(false);
  const [selected, setselected] = useState("1");

  const { pathname } = useLocation();
  const { name, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sm, md, xs } = useBreakPoints();

  useEffect(() => {
    if (!sm && !xs) {
      settoggle(false);
    }
  }, [md, sm, xs]);

  const newitems = NFTWCQUESTS?.map((route, index) => {
    return {
      key: `${parseInt(index) + 1}`,
      icon: route?.Icon,
      onClick: async () => {
        if (route?.isLeaderBoard) {
          navigate("/");
        } else {
          if (route.name === "My SUBMISSIONS") {
            navigate(route.url + name + "/" + id + "/submissions");
          } else navigate(route?.url + name + "/" + id);
        }
      },
      label: route?.name,
    };
  });
  useEffect(() => {
    settoggle(false);
  }, [pathname]);
  useEffect(() => {
    if (pathname.includes("submissions")) {
      setselected("2");
    } else setselected("1");
  }, []);
  const profile = useSelector((state) => state.profile.profile);
  const projects = useSelector(
    (state) => state.leaderboards.projectsLeaderBoard.data
  );
  const project = projects?.find((e) => e?.projectId === id);
  const condition = xs || sm || md;
  return (
    <Layout className="AdminQuestManager">
      <Header className="header">
        {condition ? (
          <Row justify={"space-between"} gutter={[15, 10]}>
            <Col className="toggle">
              <Button
                onClick={() => settoggle((e) => !e)}
                icon={<img alt="" src="/assets/nav/burger.svg" />}
              ></Button>
            </Col>
            <Col className="toggle">
              <CaliGraphy
                type="heading"
                level={1}
                style={{ fontSize: "1.1rem" }}
                content={project?.projectName}
              />{" "}
            </Col>
          </Row>
        ) : (
          <Row justify={"space-between"} gutter={[15, 15]}>
            {/* <Col  xl={6} lg={8} md={8}> */}
            <Col flex={1}>
              <Flexed alignItems="center" gap="2rem" flexDirection="row">
                <img alt="" className="logo" src="/assets/logo/logo.png" />

                <CaliGraphy
                  type="heading"
                  level={1}
                  style={{ fontSize: "1.25rem", marginLeft: "1rem" }}
                  content={project?.projectName}
                />
              </Flexed>
            </Col>
            <Col
              className="logoutcol"
              onClick={async () => {
                if (profile?.username) {
                  await signOut(auth);
                  dispatch(setProfile(null));
                } else {
                }
              }}
            >
              <NeonCard className={"admincard"}>
                {profile?.username ? "Logout" : "Login"}
              </NeonCard>
            </Col>
          </Row>
        )}
      </Header>
      <Layout>
        <Sider
          style={{ position: "absolute", zIndex: 2, height: "100%" }}
          className={`siderfixed ${toggle ? "active" : "inactive"}`}
        >
          <Menu
            mode="inline"
            onSelect={(e) => setselected(e.key)}
            selectedKeys={[selected]}
            items={newitems}
          />
        </Sider>
        {condition ? null : (
          <Sider collapsed={condition} className="sider">
            <Menu
              mode="inline"
              selectedKeys={[selected]}
              onSelect={(e) => setselected(e.key)}
              items={newitems}
            />
          </Sider>
        )}
        <Content className={`content AdminContent`}>
          {!profile?.username ? <Login /> : <Outlet />}
        </Content>
      </Layout>
    </Layout>
  );
};
export default GeneralQuestLayout;
