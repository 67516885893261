import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: null,
  searchloading: false,
  selectedcard: null,
  projects: [],
  loadingagain: false,
  admin: null,
  questFilters: [],
  activeround: {},
  gqsactiverounds: {},
  categories: [],
  quizQuestions: [],
  system: "nftwc",
  quests: [],
};

export const AdminQuestsSlice = createSlice({
  name: "adminquests",
  initialState,
  reducers: {
    setselectedcard: (state, action) => {
      state.selectedcard = action.payload;
    },
    setprojects: (state, action) => {
      state.projects = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setactiveround: (state, action) => {
      state.activeround = action.payload;
    },
    setgqsactiverounds: (state, action) => {
      state.gqsactiverounds = action.payload;
    },
    setsearch: (state, action) => {
      state.search = action.payload;
    },

    setadmin: (state, action) => {
      state.admin = action.payload;
    },

    setsearchloading: (state, action) => {
      state.searchloading = action.payload;
    },
    setquestFilters: (state, action) => {
      state.questFilters = action.payload;
    },
    setquizQuestions: (state, action) => {
      state.quizQuestions = action.payload;
    },
    resetAdmin: (state, action) => {
      state.selectedcard = null;
    },
    setsytem: (state, action) => {
      state.system = action.payload;
    },
    setQuests: (state, action) => {
      state.quests = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setactiveround,
  setsearch,
  setselectedcard,
  setprojects,
  setadmin,
  setsearchloading,
  setquestFilters,
  resetAdmin,
  setgqsactiverounds,
  setsytem,
  setCategories,
  setquizQuestions,
  setQuests,
} = AdminQuestsSlice.actions;

export default AdminQuestsSlice.reducer;
