import NeonCard from "../../../../../../common/neoncard";
import CaliGraphy from "../../../../../../common/Caligraphy";
import { useNavigate } from "react-router-dom";

function Inventory() {
  const navigate = useNavigate();

  return (
    <>
      <NeonCard
        onClick={() => navigate("/inventory")}
        className={"nobg hover"}
        styles={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CaliGraphy
          level={2}
          type="Heading"
          content="Inventory"
          classname="blue shadow"
        />
      </NeonCard>{" "}
    </>
  );
}

export default Inventory;
