import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalopen: false,
  closeable: true,
  questsubmissions: null,
  quests: null,
  questloading: false,
  selectedquest: null,
  categories: null,
  activeround: null,
  disclaimer: {
    text: "",
  },
};

export const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setmodalopen: (state, action) => {
      if (action.payload === "resetpassword") {
        state.closeable = false;
      } else if (action.payload === "loginforverify") {
        state.closeable = false;
      } else state.closeable = true;
      state.modalopen = action.payload;
    },

    setquestdata: (state, action) => {
      state.quests = action.payload;
    },
    setquestsubmissions: (state, action) => {
      state.questsubmissions = action.payload;
    },

    setquestloading: (state, action) => {
      state.questloading = action.payload;
    },
    setselectedquest: (state, action) => {
      state.selectedquest = action.payload;
    },

    resetModal: (state, action) => {
      state.questinfo = null;
      state.questloading = false;
      state.selectedquest = null;
    },

    setDisclaimer: (state, action) => {
      state.disclaimer = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setActiveRoundModal: (state, action) => {
      state.activeround = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setmodalopen,
  setquestdata,
  setquestloading,
  setselectedquest,
  setquestsubmissions,
  resetModal,
  setDisclaimer,
  setCategories,
  setActiveRoundModal,
} = ModalSlice.actions;

export default ModalSlice.reducer;
