import { doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebase";

export const useUpdateUser = (type) => {
  const userid = auth.currentUser?.uid;
  const update = async (obj) => {
    if (type === "update") {
      await updateDoc(doc(db, "users", userid), obj);
    } else await setDoc(doc(db, "users", userid), obj);
  };

  return { update };
};
