import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Popover } from "antd";
import { useUtils } from "../../../../../hooks";
import { GetDollarSign, GetNFTSign } from "../../../../../common/Styles";
import { IoTicket } from "react-icons/io5";
import { auth } from "../../../../../config/firebase";

export const usePlayerTable = (active) => {
  const round = useSelector((state) => state.round.data);
  const { data, loading } = useSelector(
    (state) => state.leaderboards.playerLeaderBoard
  );
  const [sortedData, setSortedData] = useState([]);

  const { format } = useUtils();
  const IsActiveChanged = useMemo(() => active, [active]);
  const WPD = round?.playerLeaderBoard?.WPD;
  const LPD = round?.playerLeaderBoard?.LPD;

  const winningUsers = round?.playerLeaderBoard?.winningUsers;
  const totalUsers = round?.playerLeaderBoard?.totalUsers;

  const getIndex = (i) => {
    const l = WPD?.length;
    return i - l;
  };

  const player_columns = [
    {
      title: "Ranking",
      dataIndex: "name",
      render: (data) => {
        const sr = data?.sr;
        return (
          <p
            style={{
              color: "white",
              textAlign: "center",
              margin: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".8rem",
              fontSize: "1rem",
            }}
          >
            #{sr}
          </p>
        );
      },
    },
    {
      title: "User",
      dataIndex: "name",
      render: (data) => {
        const name = data?.name;
        return name !== "-" ? (
          <p
            style={{
              color: "white",
              textAlign: "center",
              margin: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".8rem",
              fontSize: "1rem",
            }}
          >
            {name}
          </p>
        ) : (
          name
        );
      },
    },

    {
      title: "Game",
      dataIndex: "game",
      render: (data) => {
        let name;
        if (!data?.totalGamePoints) {
          name = "0";
        }
        if (data?.totalGamePoints) {
          name = data?.totalGamePoints;
        }

        return (
          <Popover content={`Game Points: ${name}`}>
            <p
              style={{
                color: "white",
                textAlign: "center",
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".8rem",
                fontSize: "1rem",
              }}
            >
              {`${format(name)}`}
            </p>
          </Popover>
        );
      },
    },
    {
      title: "Rewards",
      dataIndex: "rewards",
      ellipsis: true,
      render: (data) => {
        const icons = data?.icons || [];
        const findIsUSD = icons?.find((e) => e === "USD");
        const findIsNFT = icons?.find((e) => e === "NFT");
        const is2 = Array.isArray(icons) && icons?.length === 2;
        const isRaffle = data?.isRaffle;
        const points = data?.points || 0;
        const isWinner = data?.isWinner;
        const boosterReward = data?.boosterReward;
        const messageProject = `Prize in ${
          is2 ? "NFT and token" : findIsNFT ? "NFT" : "TOKEN"
        }`;
        if (isRaffle) {
          return (
            <>
              {parseInt(points / 100)} <IoTicket />
            </>
          );
        }
        return (
          <Popover
            content={
              <div style={{ textAlign: "center" }}>
                {messageProject} <br />
                {boosterReward !== 0 &&
                  `Also have earned ${boosterReward}$ from the booster revenue share`}
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: ".1rem",
              }}
            >
              <span style={{ fontSize: ".95rem", color: data?.color }}>
                {data?.text}
              </span>
              {findIsNFT ? <GetNFTSign /> : null}
              {findIsUSD ? (
                <GetDollarSign
                  styles={{
                    top: "-1px",
                    left: "-4px",
                    color: data?.color,
                  }}
                />
              ) : null}

              {!findIsNFT && !findIsUSD ? (
                <GetDollarSign
                  styles={{
                    top: "-1px",
                    left: "-4px",
                    color: data?.color,
                  }}
                />
              ) : null}
              {Number(boosterReward) !== 0 && (
                <>
                  <span>+ {boosterReward}</span>
                  <GetDollarSign
                    styles={{
                      top: "-1px",
                      left: "-4px",
                      color: data?.color,
                    }}
                  />
                </>
              )}
              {isWinner ? (
                <>
                  5 <IoTicket />
                </>
              ) : null}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Points",
      dataIndex: "totalPoints",
      render: (data) => {
        let name;
        if (!data?.totalPoints) {
          name = 0;
        }
        if (data?.totalPoints) {
          name = data?.totalPoints;
        }

        return (
          <Popover content={`Total Points: ${name}`}>
            <p
              style={{
                color: "white",
                textAlign: "center",
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".8rem",
                paddingRight: "1rem",
                fontSize: "1rem",
              }}
            >
              <span style={{ color: "rgb(153, 255, 128)", fontSize: ".9rem" }}>
                {`${format(name)}`} {" xp"}
              </span>
            </p>
          </Popover>
        );
      },
    },
    {
      title: "Quests",
      dataIndex: "quest",
      render: (data) => {
        let name;
        if (!data?.totalQuestPoints) {
          name = "0";
        }
        if (data?.totalQuestPoints) {
          name = data?.totalQuestPoints;
        }

        return (
          <Popover content={`Quest Points: ${name}`}>
            <p
              style={{
                color: "white",
                textAlign: "center",
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".8rem",
                fontSize: "1rem",
              }}
            >
              {`${format(name)}`}
            </p>
          </Popover>
        );
      },
    },
  ];

  const player_data = sortedData?.map((e, i) => {
    const length = winningUsers;
    const colors = i < length ? "#99FF80" : "#FF9980";
    return {
      key: parseInt(i) + 1,

      name: {
        name: e?.name,
        sr: e?.findIndex ? e?.findIndex : parseInt(i) + 1,
        color: "white",
      },
      quest: {
        totalQuestPoints: e?.totalQuestPoints,
      },
      game: {
        totalGamePoints: e?.totalGamePoints,
      },
      rewards: {
        color: colors,
        winnerLength: WPD?.length,
        text:
          i < winningUsers
            ? WPD?.[i]?.sweepTotal || 0
            : LPD?.[getIndex(i)]?.sweepTotal || 0,
        icons:
          i < winningUsers
            ? WPD?.[i]?.sweepType || []
            : LPD?.[getIndex(i)]?.sweepType || [],
        boosterReward: e?.boosterReward ?? 0,
        isRaffle: i < winningUsers ? false : true,
        points: e?.totalPoints,
        isMYData: e?.findIndex,
        isWinner: i < winningUsers,
      },
      totalPoints: {
        // quests: e?.quests,
        totalPoints: e?.totalPoints,
      },
    };
  });

  const getData = (data) => {
    if (data?.length < totalUsers) {
      const remaining = Array.isArray(data)
        ? [...data, ...Array(totalUsers - data.length).fill(null)]
        : [...Array(totalUsers - data.length).fill(null)];
      const mapped = remaining?.map((user, ind) => {
        if (!user) {
          return {
            name: "-",
            totalGamePoints: 0,
            totalMatchesWon: 0,
            totalPoints: 0,
            totalQuestPoints: 0,
            userId: " ",
          };
        } else return user;
      });
      return mapped;
    } else {
      return data;
    }
  };

  useEffect(() => {
    if (!Array.isArray(data)) return;
    const myId = auth.currentUser?.uid;
    let sliced = Array.isArray(data) ? data?.slice(0, totalUsers) : [];
    if (myId) {
      const findInSliced = sliced?.find((e) => e?.userId === myId);

      const findInData = data?.find((e) => e?.userId === myId);
      if (!findInSliced && findInData) {
        const findIndex = data?.findIndex((e) => e?.userId === myId);
        sliced.push({ ...findInData, findIndex: findIndex + 1 });
      }
    }
    setSortedData(getData(sliced));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsActiveChanged, data, auth.currentUser]);

  return {
    player_columns,
    player_data,
    player_loading: loading,
  };
};
