import React from "react";

function Bg_Animations({ quest }) {
  return (
    <div className={`starsFields ${quest ? "quest" : ""}`}>
      <div className="stars"></div>
      <div className="stars2"></div>
      <div className="stars3"></div>
      <div className="stars4"></div>
      <div className="comet1" style={{ right: "-20%" }}></div>
      <div className="comet2" style={{ right: "10%" }}></div>
      <div className="comet3" style={{ right: "45%" }}></div>
      <div className="comet4" style={{ right: "75%" }}></div>
    </div>
  );
}

export default Bg_Animations;
