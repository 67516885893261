import React from "react";
import { Layout as AntdLayout, ConfigProvider } from "antd";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.scss";

import composeProviders from "@utils/composeProviders";
import { ProductsProvider } from "../context/ProductProvider";
import { CartProvider } from "../context/CartProvider";
import darkMapTokens from "theme/antDDarkMapTokens";

function Layout() {
  // Project is provided on top of the ShopLayout
  const ShopProviders = composeProviders([ProductsProvider, CartProvider]);
  // This config provider is not global as it is risky to change the theme of the whole application
  // We have provided the theme to the Shop Admin Panel too.
  return (
    <ConfigProvider
      theme={{
        token: darkMapTokens,
      }}
    >
      <ShopProviders>
        <AntdLayout className={styles.layout}>
          <Header />
          <main className={styles.layout__main}>
            <div className={styles.layout__main__content}>
              <Outlet />
            </div>
          </main>
        </AntdLayout>
      </ShopProviders>
    </ConfigProvider>
  );
}

export default Layout;
