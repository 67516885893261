import ColMiddle from "./components/colMiddle";
import ColRight from "./components/colRight";
import ColLeft from "./components/colLeft";
import { useSelector } from "react-redux";
import Logo from "../../common/logo";
import { Col, Row } from "antd";
import { useBreakPoints } from "../../hooks";
import React from "react";
import { WrapperCountDownTimer } from "../../common/CountDownTimer";
import { WombatWrapper } from "./modal/components/Wallets/Wombat";
import { useProjects } from "context/ProjectsProvider";
import { useREALProfile } from "context/ProfileProvider";
import { useActiveBooster } from "context/ActiveBoosterProvider";

const ModalWrapper = React.lazy(() => import("./modal"));
const GameComponent = React.lazy(() => import("./GameComponent"));

function Intraverse() {
  const play = useSelector((state) => state.playing.play);
  const modalopen = useSelector((state) => state.modal.modalopen);

  const isvalue = ["resetPassword", "quests"];
  const styles = {
    opacity: isvalue.includes(modalopen) || play ? "0" : undefined,
    tabIndex: isvalue.includes(modalopen) || play ? "-2 !important" : undefined,
    userSelect:
      isvalue.includes(modalopen) || play ? "none !important" : undefined,
    display: isvalue.includes(modalopen) ? "none" : undefined,
  };
  const { lg, xl, xxl, xs } = useBreakPoints();
  const none = lg || xl || xxl;
  const womb = useSelector((state) => state.WombatWallet.wombatclicked);

  const { isLoading: isProjectsLoading } = useProjects();
  const { isLoading: isProfileLoading } = useREALProfile();
  const { isLoading: isBoosterLoading } = useActiveBooster();

  const isLoading = isProjectsLoading || isProfileLoading || isBoosterLoading;

  if (isLoading) {
    return null;
  }

  return (
    <React.Fragment>
      <WombatWrapper click={womb} />

      {play ? (
        <GameComponent />
      ) : (
        <div className="INTERVERSA INTERVERSAHOME" style={styles}>
          <Row className="interversa-row" align={"top"} justify={"start"}>
            <Col
              span={24}
              className="interversa-col"
              style={{
                position: "relative",
                marginBottom: xs ? "5rem" : !none ? "4rem" : undefined,
              }}
            >
              <Logo />
              {none ? null : (
                <WrapperCountDownTimer
                  fontSize={"1.2rem"}
                  top={xs ? "4rem" : "4.2rem"}
                />
              )}
            </Col>
            <Col span={24} className="interversa-col">
              <Row
                gutter={[
                  { xs: 10, sm: 10, md: 10, lg: 15, xl: 15, xxl: 18 },
                  { xs: 10, sm: 15, md: 10, lg: 15, xl: 15, xxl: 18 },
                ]}
                className="row-grid"
                justify={"space-between"}
              >
                <Col
                  lg={{ span: 6, order: 0 }}
                  md={{ span: 24, order: 0 }}
                  sm={{ span: 24, order: 4 }}
                  xs={{ span: 24, order: 4 }}
                  className="grid-col"
                >
                  <ColLeft />
                </Col>
                <Col
                  lg={{ span: 12, order: 1 }}
                  md={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 3 }}
                  xs={{ span: 24, order: 3 }}
                  className="grid-col"
                >
                  <ColMiddle />
                </Col>
                <Col
                  lg={{ span: 6, order: 3 }}
                  md={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 0 }}
                  xs={{ span: 24, order: 0 }}
                  className="grid-col"
                >
                  <ColRight />
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalWrapper />
        </div>
      )}
    </React.Fragment>
  );
}

export default Intraverse;
