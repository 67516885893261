import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Modal } from "antd";
import { useEffect } from "react";
import {
  resetModal,
  setquestloading,
  setselectedquest,
  setmodalopen,
} from "../../../../Store/Features/modalSlice";

import QuestCard2 from "./ModalCard/indexMain";
import ModalCancelButton from "../../../../common/ModalCancelButton";

function ModalWrapper() {
  const dispatch = useDispatch();
  const modalopen = useSelector((state) => state.modal.modalopen);

  const remove = () => {
    dispatch(setselectedquest(null));
    dispatch(setquestloading(null));
    dispatch(resetModal());
    dispatch(setmodalopen(false));
  };

  useEffect(() => {
    return () => {
      remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (modalopen === false || modalopen === null || modalopen === undefined) {
    return null;
  }

  return (
    <Modal
      className={`TransparentModal`}
      centered
      open={modalopen}
      footer={null}
      mask={true}
      maskClosable={true}
      maskStyle={{ backdropFilter: "blur(1rem)" }}
      keyboard={null}
      onCancel={remove}
      bodyStyle={{
        overflowX: "hidden",
      }}
      closeIcon={<ModalCancelButton />}
      closable={true}
      destroyOnClose={true}
      outsideClose={true}
    >
      <QuestCard2 />
    </Modal>
  );
}

export default ModalWrapper;
