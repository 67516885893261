import { Button, Badge } from "antd";
import React, { useEffect } from "react";
import { FaCartShopping } from "react-icons/fa6";
import styles from "./styles.module.scss";
import { useCart } from "../../../context/CartProvider";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

// CartButton will call sync function from useCart hook on the initial render to fetch the cart items.
function CartButton() {
  const { products, isCartSyncing, sync } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = products.length;

  const getColor = () => {
    if (isCartSyncing) return "yellow";

    return "red";
  };

  return (
    <Badge
      color={getColor()}
      className={classNames(styles.badge, {
        [styles["badge--hide"]]: count === 0,
      })}
      count={count}
      offset={[-4, 4]}
      showZero
    >
      <Button
        type="text"
        shape="circle"
        onClick={() => {
          navigate("/shop/cart");
        }}
        className={styles.button}
        icon={<FaCartShopping color="white" fontSize={"20px"} />}
      />
    </Badge>
  );
}

export default CartButton;
