import { setmodalopen } from "../../Store/Features/modalSlice";
import { setProfile } from "../../Store/Features/ProfileSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { applyActionCode } from "@firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { AdminRoutes } from "../../config/urls";
import { useNotification } from "../../hooks";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// This Componant checks for if the url is for email verification or password reset or redirect to main page if wrong url
function Redirectes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { openNotification } = useNotification();
  const oobCode = searchParams.get("oobCode");
  const mode = searchParams.get("mode");
  const { pathname } = useLocation();
  const count = useRef(0);

  useEffect(() => {
    var unsubscribeuser;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        dispatch(setProfile({}));
        const find = AdminRoutes?.find((e) => e?.url === pathname);
        if (find?.url && pathname.includes("intradashboard")) {
          if (pathname?.includes("login")) {
          } else {
            navigate("/intradashboard/login");
          }
        }
      } else if (user?.emailVerified) {
        const docRef = doc(db, "users", auth.currentUser?.uid);

        try {
          if (auth?.currentUser && auth.currentUser?.emailVerified)
            unsubscribeuser = onSnapshot(docRef, (doc) => {
              if (doc.exists()) {
                // Document exists and has been changed
                const data = doc.data();
                const nfts = data?.nfts;
                const holdingIntraversePass = nfts?.find(
                  (e) => e?.attributes && e?.attributes !== "none"
                );
                const { prevCall, lastCall, ...rest } = data;
                dispatch(
                  setProfile({
                    ...rest,
                    holdingIntraversePass: holdingIntraversePass ? true : false,
                  })
                );
              } else {
                dispatch(setProfile(null));
              }
            });
        } catch (e) {
          console.log({ usererror: e });
        }
      } else if (!user) {
        dispatch(setProfile(null));
      }
    });

    return () => {
      unsubscribe();
      unsubscribeuser?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  useEffect(() => {
    if (!mode && !oobCode) return;
    else if (oobCode && mode) {
      // after getting auth if mode resetpasssword openining the rest password modal
      if (mode === "resetPassword") {
        return dispatch(setmodalopen("resetpassword"));
      }

      if (!oobCode && !mode && (pathname !== "/" || pathname !== "")) {
        return navigate("/");
      }

      // if mode is verifyEmail then simply verify the email
      else if (mode === "verifyEmail" && oobCode) {
        applyActionCode(auth, oobCode)
          .then(async (e) => {
            count.current = 1;
            openNotification("Email verified", "success");
            count.current = 0;
            navigate("/");
          })
          .catch(async (error) => {
            if (count.current === 1) return;
            console.log(error);
            openNotification("Could not verify email", "error");
            navigate("/");
          });
      }
    }
  }, []);
}

export default Redirectes;
