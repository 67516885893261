import { useREALAuth } from "@context/AuthProvider";
import { db } from "@config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import React, { createContext, useState, useContext, useEffect } from "react";

const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { auth } = useREALAuth();

  useEffect(() => {
    setIsLoading(true);
    if (!auth) {
      setError("No user found!");
      setIsLoading(false);
      return;
    }

    const userRef = doc(db, "users", auth.auth.currentUser?.uid);

    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setProfile(doc.data());
      } else {
        setError("No such user!");
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const value = {
    profile,
    isLoading,
    error,
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

function useREALProfile() {
  return useContext(ProfileContext);
}

export { ProfileProvider, useREALProfile };
