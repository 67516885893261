import { auth, db } from "../../config/firebase";
import { where, collection, query, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";

export const useQuestApplicatoins = () => {
  const activeround = useSelector((state) => state.round.data);

  const getProjectApplicatons = async ({ questId, projectId }) => {
    let q;
    if (projectId) {
      const collectionRef = collection(db, "questsApplications");
      q = query(
        collectionRef,
        where("completedBy", "==", auth.currentUser?.uid),
        where("activeround", "==", activeround?.roundName),
        where("projectId", "==", projectId)
      );
    } else {
      const collectionRef = collection(db, "generalQuestApplicationManager");
      q = query(
        collectionRef,
        where("completedBy", "==", auth.currentUser?.uid),
        where("activeround", "==", activeround?.roundName)
      );
    }
    const querySnapshot = await getDocs(q);
    const usersData = querySnapshot.docs.map((doc) => {
      const { createdAt, ...rest } = doc.data();
      return {
        id: doc.id,
        ...rest,
      };
    });

    return usersData?.filter(
      (e) =>
        e?.status !== "rejected" &&
        e?.questId === questId &&
        e?.roundId === activeround?.id
    );
  };
  return { getProjectApplicatons };
};
