// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__ld3v2 {
  padding: 7px 15px;
}

.styles_badge__f\\+kuS .ant-badge-count {
  color: white !important;
  box-shadow: unset;
  pointer-events: none;
}
.styles_badge--hide__JSVEt .ant-badge-count {
  color: white !important;
  box-shadow: unset;
  pointer-events: none;
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Shop/components/Header/CartButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAGE;EACE,uBAAA;EACA,iBAAA;EACA,oBAAA;AAAJ;AAII;EACE,uBAAA;EACA,iBAAA;EACA,oBAAA;EACA,wBAAA;AAFN","sourcesContent":[".button {\n  padding: 7px 15px;\n}\n\n.badge {\n  :global(.ant-badge-count) {\n    color: white !important;\n    box-shadow: unset;\n    pointer-events: none;\n  }\n\n  &--hide {\n    :global(.ant-badge-count) {\n      color: white !important;\n      box-shadow: unset;\n      pointer-events: none;\n      display: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__ld3v2`,
	"badge": `styles_badge__f+kuS`,
	"badge--hide": `styles_badge--hide__JSVEt`
};
export default ___CSS_LOADER_EXPORT___;
