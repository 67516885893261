import { collection, onSnapshot, query } from "firebase/firestore";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setRound } from "../../Store/Features/roundslice";
import { db } from "../../config/firebase";
import { setActiveRoundModal } from "../../Store/Features/modalSlice";

export const useFetchRound = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== "/") return;
    var unsubscribe;
    try {
      const tournamentId = "tournament2023";
      const collectionRef = collection(
        db,
        "tournaments",
        tournamentId,
        "rounds"
      );
      unsubscribe = onSnapshot(query(collectionRef), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const id = doc.id;
            if (data?.roundActive === true) {
              const { createdAt, ...rest } = data;
              dispatch(setRound({ ...rest, id }));
              dispatch(setActiveRoundModal({ ...rest, id }));
            }
          }
        });
      });
    } catch {}

    return () => {
      unsubscribe();
    };
  }, [pathname]);
  return null;
};
