import InformationModal from "common/InformationModal";
import React from "react";

function CalculatorModal({ isOpen, close }) {
  return (
    <InformationModal
      isOpen={isOpen}
      close={close}
      title={"Boosters Explained"}
    >
      <h2>What are Boosters?</h2>
      <p>
        Intraverse Boosters are items that users can buy and use during a
        tournament hosted by Intraverse. They act as score multipliers during a
        limited period of time, giving you the edge you need to dominate the
        competition.
      </p>

      <h3>Multiplier, Duration, and Prices</h3>
      <p>
        Each booster will act as a 4x multiplier on your fighting points. The
        price of each booster depends on its validity period. Chains integrated
        for payment are: ETH, Sol, BNB, and Polygon.
      </p>

      <h3>Rules & Modalities</h3>
      <p>Usage is only available under these conditions:</p>
      <ul>
        <li>A tournament round is currently active.</li>
        <li>There’s no other booster currently active.</li>
      </ul>
      <p>
        If a match is started when a booster is active, its score is increased
        by the boost percentage. If the booster's effect ends during the match,
        the final score will remain boosted. If a booster is active at the end
        of a gaming session, the remaining minutes are lost. Be sure to activate
        your booster in time!
      </p>
      <p>
        At the end of the tournament, all active boosters are discarded.
        However, unused boosters in the inventory remain for the next
        tournament.
      </p>
      <a href="https://medium.com/@intraverse/unveiling-the-power-of-intraverse-boosters-turbocharge-your-gaming-experience-72722b826724">
        More information about the boosters
      </a>
    </InformationModal>
  );
}

export default CalculatorModal;
