import { useEffect, useState } from "react";
import { CSSProperties } from "react";

/**
 * @param {{
 *   bg?: string,
 *   classname?: string,
 *   stats?: boolean,
 *   onClick?: ()=> void,
 *   styles?: CSSProperties
 * }} props
 */

function NeonCard(props) {
  const {
    className,
    onClick,
    bg,
    styles = {},
    children,
    stats,
    ...rest
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hover = className?.includes("hover");
  const bghoveredglow = ".30";
  const bghovered = ".55";
  const backgroundimg = bg
    ? `linear-gradient( rgba(0, 0, 0, ${
        hover ? (isHovered ? bghoveredglow : bghovered) : ".5"
      }), rgba(0, 0, 0,  ${
        hover ? (isHovered ? bghoveredglow : bghovered) : ".5"
      }) ), url(${bg}) no-repeat center center`
    : undefined;

  useEffect(() => {
    return () => {
      setIsHovered(false);
    };
  }, []);
  return (
    <div
      style={{
        height: "100%",
        ...styles,
        background: backgroundimg,
        backgroundSize: stats ? "cover" : isHovered ? "125%" : "100%",
        transition: "all .25s !important",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`neoncard ${className ? className : ""}  `}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
}

export default NeonCard;
