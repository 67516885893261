import NeonCard from "@common/neoncard";
import classNames from "classnames";
import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import useActivateBooster from "./useActivateBooster";
import { useNotification } from "hooks";

function ActivateButton({ booster, userHasActiveBooster, revalidate }) {
  const { activateBooster, activatedBoosterId, isLoading, error } =
    useActivateBooster(booster.id);
  const { openNotification } = useNotification();

  const isActive = booster.status === "active";
  const isConsumed = booster.status === "consumed";
  const isReady = booster.status === "ready";

  const getButtonText = () => {
    if (isActive) return "This booster is active right now!";
    if (isConsumed) return "This booster is already consumed";
    if (userHasActiveBooster) return "Another booster is active";
    if (isReady) return "ACTIVATE BOOSTER";
  };

  useEffect(() => {
    if (activatedBoosterId) {
      openNotification("Booster activated successfully!", "success");
      if (revalidate) {
        revalidate();
      }
    }

    if (error) {
      console.log("notification error", error);
      openNotification(error, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedBoosterId, error]);

  return (
    <NeonCard
      className={classNames(styles.button, "add-to-cart", {
        [styles["button--disabled"]]:
          isConsumed || userHasActiveBooster || isLoading,
        hover: isReady && !userHasActiveBooster && !isActive && !isLoading,
      })}
      onClick={() => {
        if (userHasActiveBooster || !isReady) return;

        activateBooster();
      }}
    >
      {getButtonText()}
    </NeonCard>
  );
}

export default ActivateButton;
