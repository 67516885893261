import { useSelector } from "react-redux";

export const useIsRoundDuration = () => {
  const activeRound = useSelector((state) => state.round.data);

  const isValidDurtion = () => {
    const intervals = activeRound?.intervals ?? [];
    const firstInterval = intervals?.[0];
    const firstIntervalStart = firstInterval?.startDate;
    const firstIntervalPassTime = firstInterval?.passTime;
    const lastInterval = intervals?.[intervals?.length - 1];
    const lastIntervalStart = lastInterval?.startDate;
    const lastIntervalPassTime = lastInterval?.passTime;
    const current = new Date();
    const isOnlyOne = intervals?.length === 1;

    if (isOnlyOne) {
      return (
        current >= new Date(firstIntervalStart) &&
        current < new Date(firstIntervalPassTime)
      );
    }

    return (
      current >= new Date(firstIntervalStart) &&
      current < new Date(lastIntervalPassTime)
    );
  };

  return { isValidDurtion };
};
