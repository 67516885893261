// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_card__9M-lw {
  background-color: black;
  width: 150px;
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
}
.styles_card__content__gESNw {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-transform: capitalize;
  padding: 6px;
  color: white;
}
.styles_card__content__name__ueKSK {
  text-transform: capitalize;
}
.styles_card__content__count__zyxvi {
  text-transform: capitalize;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Intraverse/Inventory/components/NFTDetails/card/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,0BAAA;EACA,YAAA;EACA,YAAA;AACJ;AACI;EACE,0BAAA;AACN;AAEI;EACE,0BAAA;EACA,YAAA;AAAN","sourcesContent":[".card {\n  background-color: black;\n  width: 150px;\n  flex-shrink: 0;\n  border-radius: 6px;\n  overflow: hidden;\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    text-transform: capitalize;\n    padding: 6px;\n    color: white;\n\n    &__name {\n      text-transform: capitalize;\n    }\n\n    &__count {\n      text-transform: capitalize;\n      opacity: 0.8;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `styles_card__9M-lw`,
	"card__content": `styles_card__content__gESNw`,
	"card__content__name": `styles_card__content__name__ueKSK`,
	"card__content__count": `styles_card__content__count__zyxvi`
};
export default ___CSS_LOADER_EXPORT___;
