export const ADMINENDPOINTS = {
  referraLeaderboard: "getReferralLeaderBoardV1",
  checkuserdetails: "checkUserDetails",
  adminManager: "adminManager",
  questNFTWC: "questApplicationManager",
  questGeneral: "generalQuestApplicationManager",
};

export const USERENDPOINTS = {
  checkuserdetails: "checkUserDetails",
  multiWalletCloudSession: "multiWalletCloudSession",
  getUserWalletContents: "getUserWalletContents",
};
