// // Import necessary modules
// import Analytics from "analytics";
// import { useSelector } from "react-redux";
// import segmentPlugin from "@analytics/segment";
// import { SEGMENT_EVENTS } from "./segmentEvents";
// import { auth, usingapp } from "../config/firebase";

// const names = {
//   dev: "Intraverse dev",
//   prod: "Intraverse prod",
//   local: "website_dev_test",
// };
// const keys = {
//   dev: "X3LWrXRUkm0JvqZQISx53B043qa3fZMC",
//   prod: "CRgzw2b5YZT61IuRcbuNjxBRz2zsi2VM",
//   local: "X3LWrXRUkm0JvqZQISx53B043qa3fZMC",
// };
// const key = keys[usingapp];
// const name = names[usingapp];

// export const SEGMENT = Analytics({
//   app: name,
//   plugins: [
//     segmentPlugin({
//       writeKey: key,
//     }),
//   ],
// });

// export const useSegment = () => {
//   const profile = useSelector((state) => state.profile.profile);
//   const projects = useSelector(
//     (state) => state.leaderboards.projectsLeaderBoard.data
//   );

//   // identifySegment
//   const identifySegment = (object) => {
//     SEGMENT.identify(auth?.currentUser?.uid, object);
//   };

//   // Track Calls
//   const trackSegment = (uid, event, properties) => {
//     let obj = properties;
//     SEGMENT.track(event, obj);
//   };
//   const groupCallSegment = (event, properties) => {
//     SEGMENT.plugins.segment.group(event, properties);
//   };

//   const createDataToSendObject = (eventName) => {
//     const obj = SEGMENT_EVENTS[eventName].data_to_send;
//     if (auth?.currentUser?.uid && profile?.username) {
//       obj.username = profile?.username;
//       obj.email = profile?.email;
//       obj.wallet = profile?.wallets;
//       obj.userID = auth?.currentUser?.uid;
//       const filter = profile?.nfts
//         ?.filter((e) => e?.attributes && e?.attributes !== "none")
//         ?.map((e) => e?.attributes);
//       obj["galaxy pass"] = filter?.length > 0 ? filter : "none";
//     } else {
//       delete obj.username;
//       delete obj.email;
//       delete obj.wallet;
//       delete obj.userID;
//       delete obj["galaxy pass"];
//     }
//     return obj;
//   };
//   return {
//     identifySegment,
//     trackSegment,
//     createDataToSendObject,
//     groupCallSegment,
//   };
// };
// Import necessary modules
import Analytics from 'analytics';
import { useSelector } from 'react-redux';
import segmentPlugin from '@analytics/segment';
import { SEGMENT_EVENTS } from './segmentEvents';
import { auth, usingapp } from '../config/firebase';

const names = {
    dev: 'Intraverse dev',
    prod: 'Intraverse prod',
    local: 'website_dev_test',
};
const keys = {
    dev: 'X3LWrXRUkm0JvqZQISx53B043qa3fZMC',
    prod: 'CRgzw2b5YZT61IuRcbuNjxBRz2zsi2VM',
    local: 'X3LWrXRUkm0JvqZQISx53B043qa3fZMC',
};
const key = keys[usingapp];
const name = names[usingapp];

export const SEGMENT = Analytics({
    app: name,
    plugins: [
        segmentPlugin({
            writeKey: key,
        }),
    ],
});

export const useSegment = () => {
    const profile = useSelector((state) => state.profile.profile);
    const projects = useSelector(
        (state) => state.leaderboards.projectsLeaderBoard.data
    );

    // identifySegment
    const identifySegment = (object) => {
        SEGMENT.identify(auth?.currentUser?.uid, object);
    };

    // Track Calls
    const trackSegment = (uid, event, properties) => {
        let obj = properties;
        SEGMENT.track(event, obj);
    };
    const groupCallSegment = (event, properties) => {
        SEGMENT.plugins.segment.group(event, properties);
    };

    const createDataToSendObject = (eventName) => {
        const obj = SEGMENT_EVENTS[eventName].data_to_send;
        if (auth?.currentUser?.uid && profile?.username) {
            obj.username = profile?.username;
            obj.email = profile?.email;
            obj.wallet = profile?.wallets;
            obj.userID = auth?.currentUser?.uid;
            const filter = profile?.nfts
                ?.filter((e) => e?.attributes && e?.attributes !== 'none')
                ?.map((e) => e?.attributes);
            obj['galaxy pass'] = filter?.length > 0 ? filter : 'none';
        } else {
            delete obj.username;
            delete obj.email;
            delete obj.wallet;
            delete obj.userID;
            delete obj['galaxy pass'];
        }
        return obj;
    };
    return {
        identifySegment,
        trackSegment,
        createDataToSendObject,
        groupCallSegment,
    };
};
