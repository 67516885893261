import { useSegment } from "../../Segment";
import { auth, db } from "../../config/firebase";
import { SEGMENT_EVENTS } from "../../Segment/segmentEvents";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  useGetProject,
  useQuestApplicatoins,
  useNotification,
  useCheckQuestLock,
  useIsRoundDuration,
} from "../index";

export const useQuest = () => {
  const { getProject } = useGetProject(); // geting project details from db
  const { trackSegment } = useSegment(); // segment tracking
  const { openNotification } = useNotification(); // notificaiton function
  const { getProjectApplicatons } = useQuestApplicatoins();
  const { checkLock } = useCheckQuestLock(); // check if user has submitted quest in any other project
  const { isValidDurtion } = useIsRoundDuration();

  const user = useSelector((state) => state.profile.profile);

  const trackQuest = async ({ QUEST, id }) => {
    try {
      const event = SEGMENT_EVENTS.quest_completed;
      const object = event.data_to_send;
      if (id) {
        const project = await getProject(id);
        object.type = project?.name;
      }
      object.name = QUEST?.name;
      object.round = QUEST?.activeround;
      object.points = QUEST?.reward;
      object.max_submissions = QUEST?.timesCanBeCompleted;
      object.typology = QUEST?.type;
      trackSegment(auth.currentUser.uid, event.event_name, object);
    } catch (E) {
      console.log(E.message);
    }
  };
  const submitNftwcQuest = async ({ data }) => {
    await addDoc(collection(db, "questsApplications"), data);
  };
  const submitGeneralQuest = async ({ data }) => {
    await addDoc(collection(db, "generalQuestApplicationManager"), data);
  };

  const checkQuizAttempts = ({ arr, data }) => {
    if (!arr || !data) {
      return [];
    }

    return arr
      ?.map((ele) => {
        const findEle = data.find((e) => e?.id === ele.id);
        return findEle && ele.answer === findEle.answer;
      })
      .filter((e) => e);
  };
  //    common validations in both quiz and blockchain/social quests
  const commonvalidations = async ({ QUEST, projectId }) => {
    // let enableNftwc = rtdata?.enableNftwcQuests;
    // let checkPass = rtdata?.passCheckReal;
    if (!user?.email) {
      openNotification("Login First!");
      return true;
    }
    const duration = isValidDurtion();
    if (!duration) {
      openNotification("Quests are disabled!");
      return true;
    }
    // if (enableNftwc === 0) {
    //   openNotification("Quests are disabled");
    //   return true;
    // }
    // if (checkPass === 1 && !user?.holdingIntraversePass) {
    //   openNotification("Access opened for galaxy pass holders only");
    //   return true;
    // }

    if (projectId) {
      const foundProject = await checkLock({ id: projectId });
      if (foundProject?.projectName) {
        openNotification(
          `You are already doing quests for ${foundProject?.projectName}`
        );

        return true;
      }
    }

    const getMyApplications = await getProjectApplicatons({
      projectId,
      questId: QUEST?.id,
    });

    const timesCanBeCompleted = QUEST?.timesCanBeCompleted;
    if (!user?.email) {
      openNotification("Login First!");
      return true;
    }
    if (timesCanBeCompleted <= getMyApplications?.length) {
      openNotification("Max Sumbissions reached!");
      return true;
    }

    if (!QUEST?.name) {
      openNotification("Quest Doesn't Exist Anymore!");
      return true;
    }
    const endDateString = String(QUEST?.endDate);
    const endDate = new Date(endDateString).setHours(24);

    if (endDate !== "Invalid Date") {
      if (endDate < new Date()) {
        openNotification("Quest has Ended!");
        return true;
      }
    }

    if (!QUEST?.isActive) {
      openNotification("quest is not active anymore");
      return true;
    }
    return false;
  };

  //   common validations + quiz or blockchain/social quests validation
  const questValidation = async ({
    QUEST,
    type,
    projectId,
    find,
    proofLink,
  }) => {
    // validates common quest checks, like if quest is active or not.
    const validationFailed = await commonvalidations({ QUEST, projectId });
    if (validationFailed) {
      return true;
    }
    if (type === "quiz") {
      if (find) {
        openNotification("Please attemt all the asnwers");
        return true;
      } else return false;
    } else {
      const collectionRef = collection(db, "questsApplications");
      const q = query(collectionRef, where("proofLink", "==", proofLink));
      const querySnapshot = await getDocs(q);
      const links = querySnapshot.docs.length;

      if (links > 0) {
        openNotification("Link Already Submitted", "error");
        return true;
      }
    }
    return false;
  };

  return {
    trackQuest,
    submitNftwcQuest,
    submitGeneralQuest,
    questValidation,
    checkQuizAttempts,
  };
};
