import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const LeaderBoarSlice = createSlice({
  name: "round",
  initialState,
  reducers: {
    setRound: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRound } = LeaderBoarSlice.actions;

export default LeaderBoarSlice.reducer;
