import { useActiveBooster } from "context/ActiveBoosterProvider";
import { useREALAuth } from "context/AuthProvider";
import { useState } from "react";
import { withBoosterBaseUrl } from "utils/utils";

const useActivateBooster = (boosterId) => {
  const [activatedBoosterId, setActivatedBoosterId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { auth } = useREALAuth();
  const { revalidate } = useActiveBooster();

  const activateBooster = async () => {
    setIsLoading(true);

    try {
      setError(null);
      setActivatedBoosterId(null);
      const token = await auth.auth?.currentUser?.getIdToken();

      const header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        withBoosterBaseUrl(`/activate/${boosterId}`),
        {
          headers: header,
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(
          error?.message ??
            "Unknown error happened while activating the booster"
        );
      }

      setActivatedBoosterId(boosterId);
      setError(null);
      revalidate();
    } catch (error) {
      setActivatedBoosterId(null);
      setError(
        error?.message ?? "Unknown error happened while activating the booster"
      );
    }

    setIsLoading(false);
  };

  return {
    activateBooster,
    activatedBoosterId,
    isLoading,
    error,
  };
};

export default useActivateBooster;
