import React from "react";

import AddToCartButton from "../../../../components/AddToCartButton";

import styles from "./styles.module.scss";
import BoosterAttributes from "../../../../../../common/BoosterAttributes";

function CardBody({ setHoverEffect, product }) {
  return (
    <div className={styles.body}>
      <div className={styles.body__image_wrapper}>
        <img
          className={styles.body__image_wrapper__image}
          alt="product"
          src={product.imageUrl}
        />
      </div>
      <div className={styles.body__content}>
        <div>
          <BoosterAttributes
            duration={product.duration}
            percentage={product.multiplier}
          />
          <p className={styles.body__content__title}>{product.name}</p>
          <p className={styles.body__content__description}>
            {product.description}
          </p>
        </div>
        <div className={styles.body__content__price_col}>
          <div className={styles.body__content__price_col__price_row}>
            <span className={styles.body__content__price_col__price_row__title}>
              PRICE:
            </span>
            <span className={styles.body__content__price_col__price_row__price}>
              $ {product.price}
            </span>
          </div>
          <AddToCartButton
            setHoverEffect={setHoverEffect}
            productId={product.id}
          />
        </div>
      </div>
    </div>
  );
}

export default CardBody;
