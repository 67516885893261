// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_buttons_container__VeDiN {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  height: 100%;
}

.styles_buy_button__CpM62 {
  width: 100%;
  max-width: 300px;
}

.styles_no_active_boosters_container__zJT4S {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 12px;
}

.styles_loading_container__f2pMN {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.styles_active_boosters_container__zyyW4 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}
@keyframes styles_pulse__HAcXy {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}
.styles_active_boosters_container__image__VWia- {
  animation: styles_pulse__HAcXy 2s infinite;
  height: calc(100% - 60px);
  max-height: 200px;
}
.styles_active_boosters_container__timer__dZdQh {
  color: white;
  font-size: 1.4rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Intraverse/components/colLeft/components/BoosterIndicator/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,gBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAEF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;AAEF;AAAE;EACE;IACE,mBAAA;EAEJ;EAAE;IACE,sBAAA;EAEJ;EAAE;IACE,mBAAA;EAEJ;AACF;AACE;EACE,0CAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".buttons_container {\n  display: flex;\n  justify-content: flex-end;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 8px;\n  height: 100%;\n}\n.buy_button {\n  width: 100%;\n  max-width: 300px;\n}\n\n.no_active_boosters_container {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n  width: 100%;\n  padding: 12px;\n}\n\n.loading_container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.active_boosters_container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px;\n\n  @keyframes pulse {\n    0% {\n      transform: scale(1);\n    }\n    50% {\n      transform: scale(1.04);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n\n  &__image {\n    animation: pulse 2s infinite;\n    height: calc(100% - 60px);\n    max-height: 200px;\n  }\n\n  &__timer {\n    color: white;\n    font-size: 1.4rem;\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    justify-content: center;\n    margin-top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons_container": `styles_buttons_container__VeDiN`,
	"buy_button": `styles_buy_button__CpM62`,
	"no_active_boosters_container": `styles_no_active_boosters_container__zJT4S`,
	"loading_container": `styles_loading_container__f2pMN`,
	"active_boosters_container": `styles_active_boosters_container__zyyW4`,
	"active_boosters_container__image": `styles_active_boosters_container__image__VWia-`,
	"pulse": `styles_pulse__HAcXy`,
	"active_boosters_container__timer": `styles_active_boosters_container__timer__dZdQh`
};
export default ___CSS_LOADER_EXPORT___;
