import React from "react";
import NeonCard from "../neoncard";

const ModalCancelButton = () => {
  return (
    <NeonCard
      styles={{
        minWidth: "3.4rem",
        minHeight: "2.4rem !important",
        height: "2.4rem !important",
        marginTop: "-.5rem",
      }}
      className={"flex smaller hover"}
    >
      <span style={{ color: "white" }}>x</span>
    </NeonCard>
  );
};

export default ModalCancelButton;
