import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

import { useEffect, useState } from "react";

export const useGetQuest = (id, duplicate) => {
  const [questGotFromDb, setQuestGotFromDb] = useState(null);
  useEffect(() => {
    if (!id) return;
    const getdata = async () => {
      try {
        const docref = doc(db, duplicate ? "generalQuests" : "quests", id);
        const gap = await getDoc(docref);
        if (gap.exists()) {
          setQuestGotFromDb(gap.data());
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, [id]);
  return { questGotFromDb };
};
