export const useUtils = () => {
  // const format = (num) => {
  //   if (isNaN(num)) {
  //     return "";
  //   }
  //   // if (parseInt(num) <= 1000) return num;
  //   if (num <= 1000) {
  //     return num % 1 !== 0 ? num.toFixed(1) : num.toString();
  //   }
  //   // const symbols = ["", "k", "M", "B"];
  //   // const symbolIndex = Math.floor((String(num).length - 1) / 3);
  //   // const shortNum = Number((num / Math.pow(1000, symbolIndex)).toFixed(1));
  //   // const symbol = symbols[symbolIndex];
  //   // return shortNum.toLocaleString() + symbol;
  //   const symbols = ["", "k", "M", "B"];
  //   const symbolIndex = Math.floor((String(num).length - 1) / 3);
  //   const shortNum = Number((num / Math.pow(1000, symbolIndex)).toFixed(1));
  //   const symbol = symbols[symbolIndex];

  //   // Specify options to enforce dot notation
  //   const formattedNumber = shortNum.toLocaleString("en-US", {
  //     useGrouping: true,
  //     maximumFractionDigits: 1,
  //   });

  //   return formattedNumber + symbol;
  // };
  const format = (num) => {
    if (isNaN(num)) {
      return "";
    }

    if (num <= 1000) {
      return num % 1 !== 0 ? num.toFixed(1) : num.toString();
    }

    const symbols = ["", "k", "M", "B"];
    const symbolIndex = Math.floor(Math.log10(Math.abs(num)) / 3);
    const shortNum = Number((num / Math.pow(1000, symbolIndex)).toFixed(1));
    const symbol = symbols[symbolIndex];

    // Specify options to enforce dot notation
    const formattedNumber = shortNum.toLocaleString("en-US", {
      useGrouping: true,
      maximumFractionDigits: 1,
    });

    return formattedNumber + symbol;
  };

  const generateReferral = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const currentDate = new Date().getTime().toString();
    let randomString = "";

    for (let i = 0; i < 3; i++) {
      randomString += alphabet.charAt(
        Math.floor(Math.random() * alphabet.length)
      );
    }

    for (let i = 0; i < 3; i++) {
      randomString += currentDate.charAt(
        Math.floor(Math.random() * currentDate.length)
      );
    }

    return randomString;
  };
  return { format, generateReferral };
};

// export const useUtils = () => {
//   const format = (num) => {
//     if (isNaN(num)) {
//       return "";
//     }

//     const symbols = ["", "k", "M", "B"];
//     const symbolIndex = Math.floor((String(num).length - 1) / 3);
//     const shortNum = Number((num / Math.pow(1000, symbolIndex)).toFixed(1));
//     const symbol = symbols[symbolIndex];

//     // Specify options to enforce dot notation
//     const formattedNumber = shortNum.toLocaleString("en-US", {
//       useGrouping: true,
//       maximumFractionDigits: 1,
//     });

//     return formattedNumber + symbol;
//   };

//   const generateReferral = () => {
//     const alphabet = "abcdefghijklmnopqrstuvwxyz";
//     const currentDate = new Date().getTime().toString();
//     let randomString = "";

//     for (let i = 0; i < 3; i++) {
//       randomString += alphabet.charAt(
//         Math.floor(Math.random() * alphabet.length)
//       );
//     }

//     for (let i = 0; i < 3; i++) {
//       randomString += currentDate.charAt(
//         Math.floor(Math.random() * currentDate.length)
//       );
//     }

//     return randomString;
//   };

//   return { format, generateReferral };
// };
