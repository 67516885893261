import { setmodalopen } from "../../../../Store/Features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import CaliGraphy from "../../../../common/Caligraphy";
import NeonCard from "../../../../common/neoncard";
import GameStats from "./components/GameStats";
import Referal from "./components/Referal";
import { Col, Row, Tooltip, Popover } from "antd";
import User from "./components/User";
import React, { Fragment, useEffect } from "react";
import { useAccount } from "wagmi";
import { CHAINS } from "../../../../config/urls";
import { useSegment } from "../../../../Segment";
import { auth } from "../../../../config/firebase";
import { SEGMENT_EVENTS } from "../../../../Segment/segmentEvents";
import Flexed from "../../../../common/Styled/Flexed";
import { WrapperCountDownTimer } from "../../../../common/CountDownTimer";
import { useBreakPoints, useWallet } from "../../../../hooks";

function ColRight() {
  const dispatch = useDispatch();
  const { trackSegment } = useSegment();

  const openwallets = () => {
    dispatch(setmodalopen("wallets"));
    const name = SEGMENT_EVENTS.click_wallets.event_name;
    trackSegment(auth.currentUser?.uid, name, {});
  };
  const profile = useSelector((state) => state.profile.profile);
  const { address } = useAccount();
  const { connectwallet } = useWallet();

  useEffect(() => {
    if (!profile?.username) return;
    const ethChain = CHAINS.ETHEREUM.chain;
    const findEth = profile?.wallets?.find((e) => e?.chain === ethChain);

    if (findEth?.chain) {
    } else {
      if (address && !findEth?.chain) connectwallet(ethChain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const { lg, xl, xxl } = useBreakPoints();
  var fontSize = "";
  if (xxl) {
    fontSize = "1.2rem";
  } else if (xl) {
    fontSize = "1.1rem";
  } else if (lg) {
    fontSize = "1rem";
  }
  const none = !lg && !xl && !xxl;
  return (
    <Row
      className="grid-col-row right-col"
      gutter={[0, { xs: 10, sm: 15, md: 10, lg: 15, xl: 15, xxl: 18 }]}
    >
      {none ? null : <WrapperCountDownTimer fontSize={fontSize} />}
      {profile?.email ? (
        <Popover trigger="hover" content={<GetFlexed />}>
          <span
            style={{ flex: "1.15", height: "100%", display: "inline-block" }}
          >
            <NeonCard
              bg={"/assets/wallets/connect.png"}
              className={"walletConnect hover"}
              onClick={openwallets}
            >
              <CaliGraphy
                rows={1}
                content={`Wallets`}
                level={2}
                classname="blue shadow"
                type="Heading"
              />
            </NeonCard>
          </span>
        </Popover>
      ) : (
        <Tooltip trigger="click" title="log in first">
          <NeonCard
            bg={"/assets/wallets/connect.png"}
            className={"walletConnect hover"}
            // styles={{ cursor: "not-allowed" }}
          >
            <CaliGraphy
              rows={2}
              content={`Connect`}
              level={2}
              classname="blue shadow"
              type="Heading"
            />
          </NeonCard>
        </Tooltip>
      )}
      {profile?.email ? (
        <Fragment>
          <GameStats />
          {/* <NeonCard
            bg={"/assets/backgrounds/bg_nebulae.webp"}
            className={"referal hover"}
            styles={{
              paddingBlock: ".25rem",
              cursor: "default",
            }}
          >
            <Referal />
          </NeonCard> */}
        </Fragment>
      ) : (
        <Col span={24} className="grid-col-row-item user">
          <User />
        </Col>
      )}
    </Row>
  );
}

export default ColRight;

const GetFlexed = () => {
  return (
    <Flexed
      gap=".5rem"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className="LightColorPopup"
      style={{ fontSize: ".8rem", paddingBlock: ".3rem" }}
    >
      <div style={{ textAlign: "center", maxWidth: "350px" }}>
        Connect your <b>wallet</b> to get <b>in-game boosts</b> and{" "}
        <b>special rewards!</b>
      </div>
    </Flexed>
  );
};
