import React from "react";

import styles from "./styles.module.scss";
import CartProduct from "./CartProduct";
import { useCart } from "../../../../context/CartProvider";
import useUser from "../../../../hooks/useUser";

function OrderDetails() {
  const { products, isCartSyncing } = useCart();
  const { isLoggedIn } = useUser();
  const isEmpty = !products || products?.length === 0;

  const renderBody = () => {
    if (isEmpty && isCartSyncing) {
      return (
        <div className={styles.wrapper__loading}>
          {[1, 2, 3].map((i) => (
            <CartProduct.Skeleton key={i} />
          ))}
        </div>
      );
    }

    if (!isLoggedIn)
      return <h2 className={styles.wrapper__empty}>Login to see you cart</h2>;

    if (isEmpty && !isCartSyncing)
      return <h2 className={styles.wrapper__empty}>Your cart is empty</h2>;

    return products.map((product) => (
      <CartProduct key={product.id} product={product} />
    ));
  };

  // TODO: Refactor this with simple card
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper__title}>OrderDetails</h2>
      {renderBody()}
    </div>
  );
}

export default OrderDetails;
