import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const WrapperCountDownTimer = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "-6.6rem",
        zIndex: "2",
        textAlign: "center",
        width: "110%",
        color: "#64d2ff",
        textShadow: "0px 0px",
        padding: ".8rem 0px",
        left: "-5%",
        ...props,
      }}
    >
      <CountDownTimer fontSize={props.fontSize} />
    </div>
  );
};

const CountDownTimer = ({ fontSize }) => {
  const activeround = useSelector((state) => state.round.data);
  const vals = activeround?.intervals;
  const [state, setState] = useState({
    state: null,
    message: "",
  });

  const update = () => {
    const last = new Date(
      new Date(vals?.[vals?.length - 1]?.passTime).setSeconds(0, 0)
    );
    if (last < new Date()) return;

    const find = vals?.find((e) => {
      const currentTime = new Date();
      const startDate = new Date(`${e.startDate}`);
      const passTime = new Date(`${e.passTime}`);
      return currentTime >= startDate && currentTime <= passTime;
    });
    const end =
      new Date() >= new Date(find?.startDate) &&
      new Date() < new Date(find?.endDate);
    const pass =
      new Date() >= new Date(find?.endDate) &&
      new Date() < new Date(find?.passTime);

    if (end) {
      if (state.state !== find?.endDate && state.message !== "Ends in :")
        setState({
          message: "Interval Ends in  ",
          state: find?.endDate,
        });
    } else if (pass) {
      if (state.state !== find?.passTime && state.message !== "Pass Ends in :")
        setState({
          message: "Pass-Holder Interval End ",
          state: find?.passTime,
        });
    } else {
      const f = vals?.find((e) => new Date(e?.startDate) > new Date());
      if (f) {
        if (state.state !== f?.startDate && state.message !== "Starts in :")
          setState({
            message: "Starts in :",
            state: f?.startDate,
          });
      } else {
        let currentDate = new Date();

        let currentSeconds = currentDate.getSeconds();

        currentDate.setSeconds(currentSeconds - 1);
        if (state.state !== null && state.message !== null)
          setState({
            message: null,
            state: null,
          });
      }
    }
  };
  const check = (val) => {
    if (val === 0 || val === undefined || val === null) return true;
  };
  useEffect(() => {
    if (!Array.isArray(vals)) return;
    const last = new Date(
      new Date(vals?.[vals?.length - 1]?.passTime).setSeconds(0, 0)
    );
    if (last < new Date()) return;
    update();
    if (!state.state) return;

    const timer = setInterval(() => {
      const { days, hours, minutes, seconds } = calculateTimeLeft(state?.state);
      if (check(days) && check(hours) && check(minutes) && check(seconds)) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        update();
      } else {
        if (state.message === "Ended :") {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
          setTimeLeft({ days, hours, minutes, seconds });
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [vals, state?.state]);

  const calculateTimeLeft = (time) => {
    const now = new Date().getTime();
    const targetTime = new Date(time).getTime();
    const difference = targetTime - now;

    if (difference <= 0) {
      // Target date has passed
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState();
  const condition =
    check(timeLeft?.days) &&
    check(timeLeft?.hours) &&
    check(timeLeft?.minutes) &&
    check(timeLeft?.seconds);
  return state?.state === null || condition ? null : (
    <TIMER time={timeLeft} message={state?.message} fontSize={fontSize} />
  );
};
const TIMER = ({ time, message, fontSize }) => {
  const GetVal = (val) => {
    return String(val).length === 1 ? `0${val}` : val;
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: ".3rem",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          marginBottom: "0px",
          fontSize,
        }}
      >
        {message}
      </p>
      <p style={{ marginBottom: "0px", fontSize }}>
        {GetVal(time?.days) +
          ":" +
          GetVal(time?.hours) +
          ":" +
          GetVal(time?.minutes) +
          ":" +
          GetVal(time?.seconds)}
      </p>
    </div>
  );
};
