import { base_url } from "config/constants";

const getShopBaseUrl = () => {
  return `${base_url}/shop`;
};

const getCartBaseUrl = () => {
  return `${base_url}/cart`;
};

export const withShopBaseUrl = (url) => {
  return `${getShopBaseUrl()}${url}`;
};

export const withCartBaseUrl = (url) => {
  return `${getCartBaseUrl()}${url}`;
};

// The function `parseProduct` takes in product data and returns a simplified object with specific properties.
export const parseProduct = (data) => {
  return {
    id: data.id,
    name: data.name,
    price: data.priceIntraverse,
    imageUrl: data.imageUrl,
    description: data.description,
    percentage: data.attributes?.percentage,
    multiplier: data.attributes?.multiplier,
    duration: data.attributes?.duration,
  };
};
