import { withCartBaseUrl } from "../../utils";
import React, { createContext, useState, useContext, useEffect } from "react";
import { parseInCartProduct } from "./utils";
import { useREALAuth } from "context/AuthProvider";

const CartContext = createContext();

function CartProvider({ children }) {
  // keeps track of products in the cart (not all the products)
  const [products, setProducts] = useState([]);
  // keeps track of the total price of all products in the cart
  const [totalPrice, setTotalPrice] = useState(0);
  // keeps track of cart sync status
  const [isCartSyncing, setIsCartSyncing] = useState(true);
  // keeps track of cart sync errors
  const [cartSyncError, setCartSyncError] = useState(null);
  // keeps track of product IDs that are currently being updated
  const [updatingProductIds, setUpdatingProductIds] = useState([]);
  // keeps track of product IDs that have failed to add, remove or update
  const [errorProductIds, setErrorProductIds] = useState([]);

  const { auth } = useREALAuth();

  // keeps track of product IDs that should be released on the next sync (add, remove or update process is completed for these ids but the cart has not been synced yet)
  // These IDs are part of the `updatingProductIds` list and will be removed from it when the cart is synced
  const [, setQueuedProductsIds] = useState([]);

  // controller is used to sync the cart with latest sync request
  let controller = new AbortController();

  // TODO: This function is not the correct, And it should be fixed later
  const createAuthHeader = async () => {
    const token = await auth?.auth?.currentUser?.getIdToken();

    if (!token) {
      throw new Error(
        "Failed to get token, user is not logged in, or there is an issue with the token. Cart will try to sync on next auth change."
      );
    }

    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  };

  useEffect(
    () => {
      if (!cartSyncError) return;
      if (!auth) return;
      sync();
    },
    // references of functions inside of the auth might change, so we need to listen to uid
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth?.auth?.currentUser.uid]
  );

  /**
   * The function `setProductLoading` updates the list of product IDs being updated and removes any error
   * product IDs for a given product ID.
   * @param productId - The `productId` parameter is the unique identifier of the product that needs to
   * be set as loading.
   */
  const setProductLoading = (productId) => {
    setUpdatingProductIds((prev) => {
      if (prev.includes(productId)) {
        return prev;
      }

      return [...prev, productId];
    });
    setErrorProductIds((prev) => prev.filter((id) => id !== productId));
  };

  /**
   * The function `setProductShouldReleaseOnSync` adds a product ID to a list of queued product IDs if
   * it is not already included.
   * @param productId - The `productId` parameter is the unique identifier of a product that needs to
   * be released on sync.
   */
  const setProductShouldReleaseOnSync = (productId) => {
    setQueuedProductsIds((prev) => {
      if (prev.includes(productId)) {
        return prev;
      }
      return [...prev, productId];
    });
  };

  /**
   * The function setProductError updates error product IDs based on the provided product ID.
   * @param productId - The `productId` parameter in the `setProductError` function is the unique
   * identifier of a product that needs to be updated or has encountered an error.
   */
  const setProductError = (productId) => {
    // Remove the product from the list of products being updated
    setUpdatingProductIds((prev) => prev.filter((id) => id !== productId));
    setErrorProductIds((prev) => {
      if (prev.includes(productId)) {
        return prev;
      }
      return [...prev, productId];
    });
  };

  /**
   * The `releaseProduct` function removes a product ID from both the `productLoading` and
   * `errorProductIds` arrays.
   * @param productId - The `productId` parameter is the unique identifier of the product that needs to
   * be released.
   */
  const releaseProduct = (productId) => {
    setUpdatingProductIds((prev) => prev.filter((id) => id !== productId));
    setErrorProductIds((prev) => prev.filter((id) => id !== productId));
  };

  /**
   * The function `sync` is used to synchronize the cart with the latest cart data from the server.
   * It sets the `isCartSyncing` state to `true` to indicate that the cart is currently being synced.
   * If an error occurs during the sync process, the `cartSyncError` state is updated with the error
   * message. If the sync process is successful, the `products` state is updated with the latest cart
   * data from the server.
   * The function also releases products that were queued for release by removing them from the
   * `queuedProductsIds` list.
   */
  const sync = async () => {
    controller.abort();
    controller = new AbortController();
    setIsCartSyncing(true);
    setCartSyncError(null);

    try {
      const response = await fetch(withCartBaseUrl("/"), {
        signal: controller.signal,
        headers: await createAuthHeader(),
      });
      const productsData = await response.json();

      const products = productsData.items?.map(parseInCartProduct) ?? [];
      const totalPrice = productsData.total ?? 0;
      setTotalPrice(totalPrice);
      setProducts(products);
      // Release products that were queued for release
      setQueuedProductsIds((prev) => {
        return prev.filter((id) => {
          releaseProduct(id);
          return false;
        });
      });
      setIsCartSyncing(false);
    } catch (error) {
      console.error("Error syncing cart:", error);
      if (error.name === "AbortError") {
        console.error("Request was aborted");
        return;
      }
      setCartSyncError(error ?? "An error occurred while syncing the cart");
      setIsCartSyncing(false);
    }
  };

  /**
   * The `addProduct` function asynchronously adds a product to a cart with error handling and
   * synchronization.
   * @param productId - The `productId` parameter in the `addProduct` function is the unique
   * identifier of the product that needs to be added to the cart.
   * @param quantity - The `quantity` parameter in the `addProduct` function is the quantity of the
   * product that needs to be added to the cart.
   * @param projectId - The `projectId` parameter in the `addProduct` function is the unique identifier
   * of the project that the product belongs to.
   * The `addProduct` function sends a POST request to the server to add a product to the cart. If the
   * request is successful, the `productShouldReleaseOnSync` function is called to release the product
   * on the next sync. If an error occurs during the process, the `setProductError` function is called
   * to update the error product IDs.
   */
  const addProduct = async ({ productId, quantity, projectId }) => {
    setProductLoading(productId);
    try {
      await fetch(withCartBaseUrl("/products"), {
        method: "POST",
        body: JSON.stringify({ productId, quantity, projectId }),
        headers: await createAuthHeader(),
      });
      setProductShouldReleaseOnSync(productId);
    } catch (error) {
      console.error("Error adding product to cart:", error);
      setProductError(productId);
    }
    sync();
  };

  /**
   * The `removeProduct` function asynchronously removes a product from the cart using a POST request to
   * the server and handles errors accordingly.
   * @param productId - The `productId` parameter in the `removeProduct` function is the unique
   * identifier of the product that needs to be removed from the cart.
   */
  const removeProduct = async (productId) => {
    setProductLoading(productId);
    try {
      await fetch(withCartBaseUrl("/products"), {
        method: "DELETE",
        body: JSON.stringify({ productId }),
        headers: await createAuthHeader(),
      });
      setProductShouldReleaseOnSync(productId);
    } catch (error) {
      console.error("Error removing product from cart:", error);
      setProductError(productId);
    }
    sync();
  };

  /**
   * The `updateProduct` function asynchronously updates the quantity of a product in the cart via an API
   * call and handles errors accordingly.
   * @param productId - The `productId` parameter in the `updateProduct` function is the unique
   * identifier of the product that needs to be updated in the cart. It is used to identify the specific
   * product that the quantity should be updated for.
   * @param quantity - The `quantity` parameter in the `updateProduct` function represents the new
   * quantity of a product that you want to update in the cart. It is the amount by which you want to
   * change the quantity of the product with the specified `productId`.
   * @param projectId - The `projectId` parameter in the `updateProduct` function is the unique identifier
   * of the project that the product belongs to.
   *
   * The `quantity` parameter can be a positive number, It will replace the current quantity of the product.
   */
  const updateProduct = async ({ productId, quantity, projectId }) => {
    setProductLoading(productId);
    try {
      await fetch(withCartBaseUrl("/products"), {
        method: "PATCH",
        body: JSON.stringify({ productId, quantity, projectId }),
        headers: await createAuthHeader(),
      });
      setProductShouldReleaseOnSync(productId);
    } catch (error) {
      console.error("Error updating product in cart:", error);
      setProductError(productId);
    }
    sync();
  };

  /**
   * The `checkout` function sends a POST request to the checkout endpoint with authentication headers
   * and returns the response as JSON, handling any errors that occur.
   * @returns The `checkout` function is returning the result of calling `res.json()`, which will return
   * a promise that resolves to the JSON representation of the response.
   */
  const checkout = async () => {
    try {
      const res = await fetch(withCartBaseUrl("/checkout"), {
        method: "POST",
        headers: await createAuthHeader(),
      });

      return res.json();
    } catch (error) {
      console.error("Error checking out:", error);
    }
  };

  const value = {
    products,
    totalPrice,
    sync,
    addProduct,
    removeProduct,
    updateProduct,
    checkout,
    isCartSyncing,
    cartSyncError,
    updatingProductIds,
    errorProductIds,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

function useCart() {
  return useContext(CartContext);
}

export { CartProvider, useCart };
