// This abstraction is provided to make it easier to change the user data source in the future.
// As the user source is vague and can be from different sources, this abstraction is provided to make it easier to change the user data source in the future.
import { useSelector } from "react-redux";

const useUser = () => {
  const user = useSelector((state) => state.profile.profile);

  const isLoggedIn = !user?.email ? false : true;

  return {
    user,
    isLoggedIn,
  };
};

export default useUser;
