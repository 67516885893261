import useUserNFTs from "../../hooks/useUserNFTs";

import styles from "./styles.module.scss";
import NeonCard from "@common/neoncard";
import classNames from "classnames";
import NFTCard from "./card";
import SimpleCard from "common/SimpleCard";
import useClaimBooster from "./useClaimBooster";
import { useNotification } from "hooks";
import { useEffect } from "react";

const MINT_LINK = "https://mint.intraverse.io/";

function NFTDetails({ revalidate }) {
  const { activeNFTs } = useUserNFTs();
  const { openNotification } = useNotification();
  const { claim, response, isLoading } = useClaimBooster({ revalidate });

  useEffect(() => {
    if (response) {
      openNotification(response.message, response.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const isEmpty = activeNFTs?.length === 0;

  return (
    <SimpleCard title={"Your NFTs"}>
      <p className={styles.nfts_content}>
        Explore your multipliers right here! Holding an NFT from a competing
        project grants you a 2X multiplier when selecting its avatar. Moreover,
        with Galaxy Passes, you can claim boosters for FREE, unlocking an
        impressive 4X multiplier!
      </p>
      {isEmpty ? (
        <p className={styles.nfts_content}>
          Still don't have a Galaxy Pass?
          <a href={MINT_LINK} target="_blank" rel="noreferrer">
            {" "}
            Mint it NOW!
          </a>
        </p>
      ) : (
        <div className={styles.nfts_wrapper}>
          <div className={styles.nfts_scroll_row}>
            {activeNFTs?.map((e, i) => (
              <NFTCard key={i} nft={e} />
            ))}
          </div>
          <div className={styles.buttons_holder}>
            <NeonCard
              className={classNames(styles.button, "hover", {
                [styles["button--loading"]]: isLoading,
              })}
              onClick={() => {
                claim();
              }}
            >
              CLAIM BOOSTERS
            </NeonCard>
            <NeonCard
              className={classNames(styles.button, "hover", {
                [styles["button--loading"]]: isLoading,
              })}
              onClick={() => {
                window.open(MINT_LINK);
              }}
            >
              Mint a new Galaxy Pass
            </NeonCard>
          </div>
        </div>
      )}
    </SimpleCard>
  );
}

export default NFTDetails;
