import InterVersaVideo from "./components/video";
import BoosterIndicator from "./components/BoosterIndicator";
import PlayNow from "./components/playNow";
import { useDispatch, useSelector } from "react-redux";
import { Row, Tooltip } from "antd";
import { useRealTimedb, useNotification } from "../../../../hooks";
import { setmodalopen } from "../../../../Store/Features/modalSlice";
import NeonCard from "../../../../common/neoncard";
import CaliGraphy from "../../../../common/Caligraphy";
import Inventory from "./components/Inventory";
import Calculator from "./components/Calcultor";
import ShopButton from "./components/ShopButton";

function ColLeft() {
  const user = useSelector((state) => state.profile.profile);
  const { rtdata } = useRealTimedb();
  const { openNotification } = useNotification();
  const dispatch = useDispatch();

  const handleclick = () => {
    if (!user?.email) {
      return openNotification("Login First!");
    }
    // if (hide) return openNotification("Mobile Version is not supported yet!");

    let gameInProgress = rtdata?.gameInProgress;
    if (gameInProgress) {
      return openNotification("Game Work in Progress!");
    }
    let enableNftwc = rtdata?.enableNFTWCReal;
    let checkPass = rtdata?.passCheckReal;
    checkPass = rtdata?.passCheckReal;
    if (
      (checkPass === 1 &&
        (user?.holdingIntraversePass === false ||
          !user?.holdingIntraversePass)) ||
      enableNftwc !== 1
    ) {
      // return openNotification("Access opened for galaxy pass holders only");
    }
    dispatch(setmodalopen("playnow"));
  };
  return (
    <>
      <Row
        className="grid-col-row left-col"
        gutter={[{}, { xs: 10, sm: 15, md: 10, lg: 15, xl: 15, xxl: 18 }]}
      >
        {user?.email ? (
          <PlayNow handleclick={handleclick} />
        ) : (
          <Tooltip title="log in first" trigger="click">
            <NeonCard
              className={"nobg hover"}
              styles={{ cursor: "not-allowed" }}
            >
              <CaliGraphy
                level={2}
                type="Heading"
                content="PLAY"
                classname="blue shadow"
              />
            </NeonCard>
          </Tooltip>
        )}

        <Inventory />
        <ShopButton />
        <BoosterIndicator />
        <Calculator />
        <InterVersaVideo />
      </Row>
    </>
  );
}

export default ColLeft;
