import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { usePlayerTable } from "./playerLeaderBoardColumns";
import { useProjectTable } from "./projectColumns";
import { useReferralTable } from "./referralBoardColumns";

function Tabs() {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const active = useSelector((state) => state.leaderboards.activeTab);
  const { player_columns, player_data, player_loading } = usePlayerTable();
  const { project_loading, projects_columns, projects_data } =
    useProjectTable();
  const { referral_loading, referral_columns, referral_data } =
    useReferralTable();

  const isLoading = useMemo(
    () =>
      active === 1
        ? player_loading
        : active === 2
        ? project_loading
        : referral_loading,
    [player_loading, project_loading, referral_loading, active]
  );
  const data = useMemo(
    () =>
      active === 1 ? player_data : active === 2 ? projects_data : referral_data,
    [player_data, projects_data, referral_data, active]
  );
  const columns = useMemo(
    () =>
      active === 1
        ? player_columns
        : active === 2
        ? projects_columns
        : referral_columns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [player_columns, projects_columns, referral_columns]
  );

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  useEffect(() => {
    // custom expanded row classadd

    const trs = document
      .querySelector(".ant-table-tbody")
      .querySelectorAll("tr");
    trs?.forEach((e) => {
      const key = parseInt(e.getAttribute("data-row-key"));
      const expanded = expandedRowKeys.includes(key);
      if (expanded) e.classList.add("activerow");
      else e.classList.remove("activerow");
    });
  }, [expandedRowKeys]);
  useEffect(() => {
    if (active !== 2) {
      setExpandedRowKeys([]);
    }
  }, [active]);
  return (
    <Table
      className={`rankingtable table_leaderboard`}
      showHeader={true}
      columns={columns}
      pagination={false}
      dataSource={data}
      loading={isLoading}
      scroll={{ x: active === 1 ? 800 : 0, y: "100%" }}
      expandable={{
        onExpand: handleExpand,
        expandedRowKeys: expandedRowKeys,
      }}
      style={{
        transition: "all .2s",
        paddingTop: ".2rem",
      }}
    />
  );
}

export default Tabs;
