// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tag__Mu-da {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_wrapper__M7dhb {
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/common/BoosterAttributes/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,wBAAA;EAAA,mBAAA;AACF","sourcesContent":[".tag {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.wrapper {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `styles_tag__Mu-da`,
	"wrapper": `styles_wrapper__M7dhb`
};
export default ___CSS_LOADER_EXPORT___;
