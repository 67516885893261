import React from "react";

import styles from "./styles.module.scss";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.header__content}>
        <span className={styles.header__content__title}>INTRA BOOSTERS</span>
        <span className={styles.header__content__subtitle}>
          Special Offers for Limited Time
        </span>
      </div>
      <div className={styles.header__gradient_overly} />
      {/* We don't have these picture anymore but I'm not deleting them for possible future use!  */}
      {/* <img
        className={styles.header__right_image}
        alt="glory-of-dream"
        src="/assets/shop/glory-of-dream.png"
      />
      <img
        className={styles.header__left_image}
        alt="amirdrassil"
        src="/assets/shop/amirdrassil.png"
      /> */}
    </div>
  );
}

export default Header;
