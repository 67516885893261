import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getApps } from "firebase/app";
import { usingapp } from "./constants";

const configuration = {
  firebase: {
    dev: {
      apiKey: "AIzaSyCygpVzehZWMTlNiM01hToDyZtDExIAhd8",
      authDomain: "metatope-gaming.firebaseapp.com",
      databaseURL: "https://metatope-gaming-default-rtdb.firebaseio.com",
      projectId: "metatope-gaming",
      storageBucket: "metatope-gaming.appspot.com",
      messagingSenderId: "598565600199",
      appId: "1:598565600199:web:9ac5fd8b2deaa9ecfa77e2",
      measurementId: "G-QV1Q26SKHN",
    },
    prod: {
      apiKey: "AIzaSyCygpVzehZWMTlNiM01hToDyZtDExIAhd8",
      authDomain: "metatope-gaming.firebaseapp.com",
      databaseURL: "https://metatope-gaming-default-rtdb.firebaseio.com",
      projectId: "metatope-gaming",
      storageBucket: "metatope-gaming.appspot.com",
      messagingSenderId: "598565600199",
      appId: "1:598565600199:web:9ac5fd8b2deaa9ecfa77e2",
      measurementId: "G-QV1Q26SKHN",
    },
  },
  functons: {
    dev: "https://us-central1-intraverse-3aa8e.cloudfunctions.net",
    prod: "https://us-central1-intraverse-3aa8e.cloudfunctions.net",
  },
  localfunctions: {
    dev: "dev",
    prod: "3aa8e",
  },
  buckets: {
    dev: "https://storage.googleapis.com/metatope-gaming.appspot.com",
    prod: "https://storage.googleapis.com/metatope-gaming.appspot.com",
  },
  discord: {},
  discordAuthUrl: {
    dev: "https://discord.com/api/oauth2/authorize?client_id=1166364889082761256&redirect_uri=https%3A%2F%2Fplay.intraverse.io%2Fquests&response_type=code&scope=identify%20guilds%20email%20guilds.join",
    prod: "https://discord.com/api/oauth2/authorize?client_id=1166364889082761256&redirect_uri=https%3A%2F%2Fplay.intraverse.io%2Fquests&response_type=code&scope=identify%20guilds%20email%20guilds.join",
  },
  discordRedirect: {
    dev: "https://play.metatope.com/quests",
    prod: "https://play.metatope.com/quests",
  },
};
const cloudurl = configuration.functons[usingapp];
const local = configuration.localfunctions[usingapp];
const bucketBaseUrl = configuration.buckets[usingapp];
const discordAuthUrl = configuration.discordAuthUrl[usingapp];
const DISCORD_REDIRECT_URL = configuration.discordRedirect[usingapp];
const localcloudurl = `http://127.0.0.1:5001/intraverse-${local}/us-central1`;

const gameWebglUrl = bucketBaseUrl + "/Webgl";
const gameMacUrl = bucketBaseUrl + "/Mac/Intraverse.zip";
const gameWindowsUrl = bucketBaseUrl + "/Windows/Intraverse.zip";
const gameAndriodUrl = bucketBaseUrl + "/Android/Intraverse.apk";

// Initialize Firebase
const app = initializeApp(configuration.firebase[usingapp]);

const analytics = getAnalytics(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const existing = getApps(app);
const db = getFirestore(app);
const rdb = getDatabase(app);
const auth = getAuth(app);

console.log({ env: usingapp });

export {
  db,
  rdb,
  auth,
  storage,
  // cloudurl,
  usingapp,
  existing,
  functions,
  analytics,
  firestore,
  gameMacUrl,
  gameWebglUrl,
  gameAndriodUrl,
  gameWindowsUrl,
  localcloudurl,
  discordAuthUrl,
  DISCORD_REDIRECT_URL,
};
