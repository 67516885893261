import ProfileSliceReducer from "./Features/ProfileSlice";
import PlayingReducer from "./Features/PlayingReducer";
import AdminQuestsReducer from "./Features/AdminQuests";
import { configureStore } from "@reduxjs/toolkit";
import ModalSlice from "./Features/modalSlice";
import WombatWallet from "./Features/WombatWallet";
import LoadingSlice from "./Features/loadingSlice.js";
import leaderboardSlice from "./Features/leaderboard.js";
import roundslice from "./Features/roundslice.js";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    profile: ProfileSliceReducer,
    Admin: AdminQuestsReducer,
    playing: PlayingReducer,
    modal: ModalSlice,
    WombatWallet: WombatWallet,
    loading: LoadingSlice,
    leaderboards: leaderboardSlice,
    round: roundslice,
  },
});
