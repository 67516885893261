// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__SG2kc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9rem;
  padding: 12px 6px;
  min-height: unset;
}
.styles_button--disabled__8T2So {
  opacity: 0.8;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Intraverse/Inventory/components/BoosterDetails/BoosterCard/CardBody/ActivateButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;AACJ","sourcesContent":[".button {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  font-size: 0.9rem;\n  padding: 12px 6px;\n  min-height: unset;\n\n  &--disabled {\n    opacity: 0.8;\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__SG2kc`,
	"button--disabled": `styles_button--disabled__8T2So`
};
export default ___CSS_LOADER_EXPORT___;
