// import { useDispatch } from "react-redux";
// import { unload } from "../../Store/Features/loadingSlice";
// import { auth } from "../../config/firebase";
// import { useUsersNFTs, useNotification } from "..";
// import { useWeb3Modal } from "@web3modal/react";
// import { useSegment } from "../../Segment";
// import { SEGMENT_EVENTS } from "../../Segment/segmentEvents";
// import { authrequest } from "../../config/api";
// import { CHAINS } from "../../config/urls";
// import { USERENDPOINTS } from "../../config/endpoints";

// export const useCheckWallet = () => {
//   const userId = auth?.currentUser?.uid;
//   const dispatch = useDispatch();
//   const { getAllNFTs } = useUsersNFTs();
//   const { openNotification } = useNotification();
//   const { close } = useWeb3Modal();
//   const { trackSegment, createDataToSendObject, groupCallSegment } =
//     useSegment();

//   const walletcheck = async (walletAddress, chain) => {
//     try {
//       var res;

//       if (!walletAddress) {
//         dispatch(unload());
//         return openNotification("Couldn't select account", "error");
//       }

//       res = await authrequest({
//         endpoint: USERENDPOINTS.multiWalletCloudSession,
//         data: { walletAddress, userId, chain },
//       });

//       if (res?.data) {
//         const name = SEGMENT_EVENTS.wallet_connected.event_name;
//         const data = createDataToSendObject(name);
//         trackSegment(userId, name, {
//           chain,
//           walletAddress,
//           wallet: data?.wallet,
//           "galaxy pass": data?.["galaxy pass"],
//         });
//         groupCallSegment(chain, data);
//         await getAllNFTs(walletAddress, chain);
//       }
//       if (res?.data || res?.status === 200) {
//         openNotification("Wallet has been connected successfully", "success");
//       } else if (res?.status === 409) {
//         openNotification("Wallet already exists", "error");
//       } else {
//         openNotification(
//           res?.data?.error || "Couldn't Connect Account",
//           "error"
//         );
//       }
//       return res?.data;
//     } catch (e) {
//       console.log(e?.response || e);
//       if (e?.response?.status === 409) {
//         openNotification(
//           "Wallet already connected to another account",
//           "error"
//         );
//       } else {
//         openNotification(e?.message, "error");
//       }
//       dispatch(unload());
//       close();
//       // return { error: "Wallet Already Exists" };
//     }
//   };
//   const cardanoconnect = async ({ data, stakeAddress }) => {
//     try {
//       let walletAddress = stakeAddress;
//       if (!walletAddress) {
//         dispatch(unload());
//         return openNotification("Couldn't select account", "error");
//       }
//       let res;

//       res = await authrequest({
//         data: { walletAddress: stakeAddress, chain: CHAINS.Cardano.chain },
//         endpoint: USERENDPOINTS.multiWalletCloudSession,
//       });
//       if (res.status === 200) {
//         res = await authrequest({
//           data,
//           endpoint: `${USERENDPOINTS.getUserWalletContents}?chain=cardano&owner=${stakeAddress}`,
//         });
//         const name = SEGMENT_EVENTS.wallet_connected.event_name;
//         const dataToTrack = createDataToSendObject(name);
//         trackSegment(userId, name, {
//           chain: CHAINS.Cardano.chain,
//           walletAddress,
//           wallet: data?.wallet,
//           "galaxy pass": data?.["galaxy pass"],
//         });
//         groupCallSegment(CHAINS.Cardano.chain, dataToTrack);
//       } else {
//         console.log("not connected", res);
//       }

//       if (res?.data || res?.status === 200) {
//         openNotification("Wallet has been connected successfully", "success");
//       } else {
//         openNotification(
//           res?.data?.error || "Couldn't Connect Account",
//           "error"
//         );
//       }
//       return res?.data;
//     } catch (e) {
//       dispatch(unload());
//       const res = e?.response?.data;
//       const data = e?.data;
//       if (res === "No cardano Project Found") {
//         return openNotification(res, "info");
//       } else if (data === "No cardano Project Found") {
//         return openNotification(data, "info");
//       } else {
//         const string =
//           typeof data === String
//             ? data
//             : e?.response?.message
//             ? e?.response?.message
//             : e?.message;
//         openNotification(string || "Couldn't Connect Account", "error");
//       }
//       console.log(e?.response);
//     }
//   };

//   const walletcheckSimpler = async (walletAddress, chain) => {
//     try {
//       let res = await authrequest({
//         endpoint: USERENDPOINTS.multiWalletCloudSession,
//         data: { walletAddress, userId, chain },
//       });

//       if (res?.data) {
//         await getAllNFTs(walletAddress, chain, true);
//         openNotification(`wallet changed: ${walletAddress}`, "success");
//         dispatch(unload());
//       }
//     } catch (e) {
//       // return { error: "Wallet Already Exists" };
//     }
//   };
//   return { walletcheck, walletcheckSimpler, cardanoconnect };
// };
import { useDispatch } from 'react-redux';
import { unload } from '../../Store/Features/loadingSlice';
import { auth } from '../../config/firebase';
import { useUsersNFTs, useNotification } from '..';
import { useWeb3Modal } from '@web3modal/react';
import { useSegment } from '../../Segment';
import { SEGMENT_EVENTS } from '../../Segment/segmentEvents';
import { authrequest } from '../../config/api';
import { CHAINS } from '../../config/urls';
import { USERENDPOINTS } from '../../config/endpoints';

export const useCheckWallet = () => {
    const userId = auth?.currentUser?.uid;
    const dispatch = useDispatch();
    const { getAllNFTs } = useUsersNFTs();
    const { openNotification } = useNotification();
    const { close } = useWeb3Modal();
    const { trackSegment, createDataToSendObject, groupCallSegment } =
        useSegment();

    const walletcheck = async (walletAddress, chain) => {
        try {
            var res;

            if (!walletAddress) {
                dispatch(unload());
                return openNotification("Couldn't select account", 'error');
            }

            res = await authrequest({
                endpoint: USERENDPOINTS.multiWalletCloudSession,
                data: { walletAddress, userId, chain },
            });

            if (res?.data) {
                const name = SEGMENT_EVENTS.wallet_connected.event_name;
                const data = createDataToSendObject(name);
                trackSegment(userId, name, {
                    chain,
                    walletAddress,
                    wallet: data?.wallet,
                    'galaxy pass': data?.['galaxy pass'],
                });
                groupCallSegment(chain, data);
                await getAllNFTs(walletAddress, chain);
            }
            if (res?.data || res?.status === 200) {
                openNotification(
                    'Wallet has been connected successfully',
                    'success'
                );
            } else if (res?.status === 409) {
                openNotification('Wallet already exists', 'error');
            } else {
                openNotification(
                    res?.data?.error || "Couldn't Connect Account",
                    'error'
                );
            }
            return res?.data;
        } catch (e) {
            console.log(e?.response || e);
            if (e?.response?.status === 409) {
                openNotification(
                    'Wallet already connected to another account',
                    'error'
                );
            } else {
                openNotification(e?.message, 'error');
            }
            dispatch(unload());
            close();
            // return { error: "Wallet Already Exists" };
        }
    };
    const cardanoconnect = async ({ data, stakeAddress }) => {
        try {
            let walletAddress = stakeAddress;
            if (!walletAddress) {
                dispatch(unload());
                return openNotification("Couldn't select account", 'error');
            }
            let res;

            res = await authrequest({
                data: {
                    walletAddress: stakeAddress,
                    chain: CHAINS.Cardano.chain,
                },
                endpoint: USERENDPOINTS.multiWalletCloudSession,
            });
            if (res.status === 200) {
                res = await authrequest({
                    data,
                    endpoint: `${USERENDPOINTS.getUserWalletContents}?chain=cardano&owner=${stakeAddress}`,
                });
                const name = SEGMENT_EVENTS.wallet_connected.event_name;
                const dataToTrack = createDataToSendObject(name);
                trackSegment(userId, name, {
                    chain: CHAINS.Cardano.chain,
                    walletAddress,
                    wallet: data?.wallet,
                    'galaxy pass': data?.['galaxy pass'],
                });
                groupCallSegment(CHAINS.Cardano.chain, dataToTrack);
            } else {
                console.log('not connected', res);
            }

            if (res?.data || res?.status === 200) {
                openNotification(
                    'Wallet has been connected successfully',
                    'success'
                );
            } else {
                openNotification(
                    res?.data?.error || "Couldn't Connect Account",
                    'error'
                );
            }
            return res?.data;
        } catch (e) {
            dispatch(unload());
            const res = e?.response?.data;
            const data = e?.data;
            if (res === 'No cardano Project Found') {
                return openNotification(res, 'info');
            } else if (data === 'No cardano Project Found') {
                return openNotification(data, 'info');
            } else {
                const string =
                    typeof data === String
                        ? data
                        : e?.response?.message
                        ? e?.response?.message
                        : e?.message;
                openNotification(string || "Couldn't Connect Account", 'error');
            }
            console.log(e?.response);
        }
    };

    const walletcheckSimpler = async (walletAddress, chain) => {
        try {
            let res = await authrequest({
                endpoint: USERENDPOINTS.multiWalletCloudSession,
                data: { walletAddress, userId, chain },
            });

            if (res?.data) {
                await getAllNFTs(walletAddress, chain, true);
                openNotification(`wallet changed: ${walletAddress}`, 'success');
                dispatch(unload());
            }
        } catch (e) {
            // return { error: "Wallet Already Exists" };
        }
    };
    return { walletcheck, walletcheckSimpler, cardanoconnect };
};
