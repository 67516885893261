import { useNavigate } from "react-router-dom";
const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        zIndex: 10000,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: "2.5rem",
        letterSpacing: ".1rem",
        fontWeight: "600",
        flexDirection: "column",
      }}
    >
      <span> Invalid Url</span>
      <span
        style={{
          fontSize: "1.2rem",
          color: "skyblue",
          cursor: "pointer",
          marginTop: ".5rem",
          textDecoration: "underline",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Back to Home
      </span>
    </div>
  );
};

export default Error404;
