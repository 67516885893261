import { useSelector } from "react-redux";

export const useFunctions = () => {
  const mySubmissions = useSelector((s) => s.modal.questsubmissions);

  const checkIfSubmittedFromAnyProject = (questId, timesCanBeCompleted) => {
    var counter = 0;
    if (!Array.isArray(mySubmissions)) return;
    mySubmissions?.forEach((e) => {
      if (e?.questId == questId) {
        if (e?.status !== "rejected") counter++;
      }
    });
    if (counter >= timesCanBeCompleted) {
      return false;
    } else {
      return "allow";
    }
  };

  return {
    checkIfSubmittedFromAnyProject,
  };
};
