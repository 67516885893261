import { BUTTON } from "common/button";
import NeonCard from "common/neoncard";
import CaliGraphy from "common/Caligraphy";
import { useActiveBooster } from "context/ActiveBoosterProvider";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";

function BoosterIndicator() {
  const { activeBooster, revalidate, isLoading } = useActiveBooster();

  if (!activeBooster && isLoading) {
    return (
      <NeonCard>
        <Loading />
      </NeonCard>
    );
  }

  return (
    <NeonCard className="nobg">
      {!activeBooster && <NoActiveBoosters />}
      {activeBooster && (
        <ActiveBooster booster={activeBooster} revalidate={revalidate} />
      )}
    </NeonCard>
  );
}

const Loading = () => {
  return <div className={styles.loading_container}></div>;
};

const ActiveBooster = ({ booster, revalidate }) => {
  const activationDate = Date.parse(booster?.activationDate);
  const durationInSeconds = Number(booster?.boosterDuration) * 60;
  const activeTimeInSeconds = Math.floor((Date.now() - activationDate) / 1000);

  const remainingInSeconds = durationInSeconds - activeTimeInSeconds;

  const [remainingTime, setRemainingTime] = useState(remainingInSeconds);

  const remainingTimeInMinutes = useMemo(() => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }, [remainingTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((time) => {
        if (time === 0) {
          revalidate();
          clearInterval(timer);
          return 0;
        }

        return time - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.active_boosters_container}>
      <CaliGraphy
        level={2}
        rows={1}
        type="Heading"
        className={styles.active_boosters_container__title}
        content="Active Booster"
        classname="blue shadow"
      />
      <img
        className={styles.active_boosters_container__image}
        src={booster?.imageUrl}
        alt="booster"
      />
      <span className={styles.active_boosters_container__timer}>
        {remainingTimeInMinutes}
      </span>
    </div>
  );
};

const NoActiveBoosters = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.no_active_boosters_container}>
      <CaliGraphy
        level={2}
        rows={3}
        type="Heading"
        style={{
          fontSize: "1.1rem",
          marginBottom: "12px",
        }}
        content="You don't have any active boosters!"
        classname="blue shadow"
      />
      <div className={styles.buttons_container}>
        <BUTTON
          className={styles.buy_button}
          onClick={() => {
            navigate("/inventory");
          }}
          text={"Activate a Booster"}
        />
        <BUTTON
          className={styles.buy_button}
          onClick={() => {
            navigate("/shop");
          }}
          text={"Buy a Booster"}
        />
      </div>
    </div>
  );
};

export default BoosterIndicator;
