import React from "react";

import { Select } from "antd";

import { useProjects } from "@context/ProjectsProvider";

function ProjectSelect({
  className,
  wrapperClassName,
  onChange,
  value,
  disabled,
  setHoverEffect,
}) {
  const { projects } = useProjects();

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    // We need this wrapper to prevent the hover effect from being triggered when the user hovers over the select dropdown
    // and to prevent the click event from being propagated to the parent component
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseEnter={() => {
        if (setHoverEffect) {
          setHoverEffect(false);
        }
      }}
      onMouseLeave={() => {
        if (setHoverEffect) {
          setHoverEffect(true);
        }
      }}
      className={wrapperClassName}
    >
      <Select
        showSearch
        className={className}
        disabled={disabled}
        placeholder="Select a Project"
        optionFilterProp="children"
        filterOption={filterOption}
        value={value}
        onChange={(project) => {
          onChange(project);
        }}
        options={projects.map((project) => ({
          value: project.id,
          label: project.name,
        }))}
      />
    </div>
  );
}

export default ProjectSelect;
