import React from "react";
import { Tag, Flex } from "antd";
import { ImPower, ImStopwatch } from "react-icons/im";
import styles from "./styles.module.scss";

function BoosterAttributes({ percentage, duration }) {
  return (
    <Flex gap="4px 0" wrap="wrap" className={styles.wrapper}>
      <Tag className={styles.tag} icon={<ImPower />} color="#55acee">
        {percentage}X
      </Tag>
      <Tag className={styles.tag} icon={<ImStopwatch />} color="#cd201f">
        {duration} minutes
      </Tag>
    </Flex>
  );
}

export default BoosterAttributes;
