import { Popover } from "antd";
import { GiTrophy } from "react-icons/gi";
import { SiTether } from "react-icons/si";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useReferralTable = () => {
  const { data, loading } = useSelector(
    (state) => state.leaderboards.referralLeaderBoard
  );
  const profile = useSelector((state) => state.profile.profile);
  const round = useSelector((state) => state.round.data);
  const refferal_leaderBoard_prize = round?.refferal_leaderBoard_prize;
  const totalUsers = round?.refferal_leaderBoard_len;

  const referral_columns = [
    {
      title: "Ranking",
      dataIndex: "sr",
      // width: "27.5%",

      render: (data) => {
        return (
          <span
            style={{
              color: data?.color,
              whiteSpace: "nowrap",
            }}
          >
            <Popover content={"Ranking " + data?.text}>{data?.text} </Popover>
          </span>
        );
      },
      EXPAND_COLUMN: true,
    },
    {
      title: "Projects",
      dataIndex: "name",
      ellipsis: true,
      // width: "40%",
      EXPAND_COLUMN: false,
      render: (data) => {
        return (
          <span style={{ color: data?.color }}>
            {data?.text === "-" ? (
              data?.text
            ) : (
              <Popover content={data?.text}>{data?.text}</Popover>
            )}
          </span>
        );
      },
    },

    {
      title: "Effective Referral Points",
      dataIndex: "points",
      EXPAND_COLUMN: false,
      render: (data) => {
        if (!data) return;
        const notshowPoints = data?.notshowPoints;
        if (notshowPoints) return;
        return (
          <span
            className="tableIconRow"
            style={{ color: data?.color || undefined }}
          >
            <Popover
              content={`Effective referral Points   ${parseFloat(
                data?.text
              ).toFixed(2)}`}
            >
              {!isNaN(data?.text) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "center",
                    gap: ".2rem",
                  }}
                >
                  <GiTrophy
                    style={{
                      marginLeft: "0rem",
                      top: "1px",
                      position: "relative",
                      fontSize: "1rem",
                    }}
                  />
                  {parseFloat(data?.text).toFixed(2)}
                </div>
              ) : null}
            </Popover>
          </span>
        );
      },
    },
    {
      title: "Prize",
      dataIndex: "prize",
      EXPAND_COLUMN: false,
      render: (data) => {
        if (!data) return;
        return (
          <span
            className="tableIconRow"
            style={{ color: data?.color || undefined }}
          >
            <Popover content={`Prize ${data?.text || 0}`}>
              {data?.text || 0}{" "}
              {data?.noIcon ? null : (
                <SiTether
                  style={{
                    top: "2px",
                    position: "relative",
                    fontSize: ".8rem",
                  }}
                />
              )}
            </Popover>
          </span>
        );
      },
    },
  ];
  const getData = (data) => {
    if (data?.length < totalUsers) {
      const remaining = Array.isArray(data)
        ? [...data, ...Array(totalUsers - data.length).fill(null)]
        : [...Array(totalUsers - data.length).fill(null)];
      const mapped = remaining?.map((user, ind) => {
        if (!user) {
          return {
            username: "-",
            refferalCode: "-",
            total: 0,
            userId: " ",
          };
        } else return user;
      });
      return mapped;
    } else {
      return data;
    }
  };
  const mapped = useMemo(() => {
    return getData(data);
  }, [data, round]);

  const referral_data = useMemo(
    () =>
      mapped?.map((e, i) => {
        const colors = ["#99FF80", "#FF9980"];
        return {
          key: i,
          sr: {
            text: `#${i + 1}`,
          },

          points: {
            text: e?.total,
          },
          prize: {
            text: refferal_leaderBoard_prize?.[i],
            color: colors[0],
          },

          name: {
            text: e?.username,
          },
        };
      }),
    [mapped, round, data]
  );
  return {
    referral_columns,
    referral_data,
    referral_loading: loading,
  };
};
