import { useSelector } from "react-redux";
import NeonCard from "../../../../common/neoncard";
import Flexed from "../../../../common/Styled/Flexed";
import { useBreakPoints } from "../../../../hooks";
import SELECT from "../../../../common/Select";

const Filters = ({ setActive, active }) => {
  const categories = useSelector((state) => state.modal.categories);
  const categoriesToRender = categories?.find((e) => e?.id === "nftwc");

  const { xs, sm, md } = useBreakPoints();
  const sizeCondition = xs || sm || md;
  if (!categories) return null;
  return (
    <>
      {sizeCondition ? (
        <SELECT
          options={[
            { label: "All", value: "all" },
            ...categoriesToRender?.categories?.map((e) => {
              return { label: e, value: e };
            }),
          ]}
          value={active}
          setvalue={setActive}
          placeholder="Select Filters"
          style={{
            minWidth: "220px",
            marginBottom: "1.5rem",
          }}
        />
      ) : (
        <Flexed
          alignItems="center"
          gap="1.1rem"
          style={{
            marginBottom: "1rem",
          }}
        >
          <NeonCard
            onClick={() => setActive("all")}
            styles={{
              borderWidth: ".15rem",
              minWidth: "7rem",
              fontSize: ".85rem",
            }}
            className={`admincard ${active === "all" ? "active" : ""}  `}
          >
            All
          </NeonCard>
          {categoriesToRender?.categories?.map((e, i) => {
            return (
              <NeonCard
                onClick={() => setActive(e)}
                styles={{
                  borderWidth: ".15rem",
                  minWidth: "7.8rem",
                  fontSize: ".85rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`admincard questTabs ${
                  active === e ? "active" : ""
                }  `}
                key={i}
              >
                {e}
              </NeonCard>
            );
          })}
        </Flexed>
      )}
    </>
  );
};

export default Filters;
