// The function `parseInCartProduct` takes in product data and returns a simplified object with specific properties.
export function parseInCartProduct(data) {
  return {
    id: data.id,
    name: data.name,
    price: data.priceIntraverse,
    quantity: data.quantity,
    imageUrl: data.imageUrl,
    description: data.description,
    projectId: data.projectId,
    percentage: data.attributes?.percentage,
    multiplier: data.attributes?.multiplier,
    duration: data.attributes?.duration,
  };
}
