import { Col, Popover, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useMemo } from "react";
import { GiHighKick, GiRadarSweep, GiTrophy } from "react-icons/gi";
import NeonCard from "../../../../../common/neoncard";
import { useNotification, useUtils } from "../../../../../hooks";
import { SEGMENT_EVENTS } from "../../../../../Segment/segmentEvents";
import { useSegment } from "../../../../../Segment";
import { setmodalopen } from "../../../../../Store/Features/modalSlice";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../../../config/urls";
import { GetDollarSign, GetNFTSign } from "../../../../../common/Styles";

export const useProjectTable = () => {
  const { format } = useUtils();

  const user = useSelector((state) => state.profile.profile);
  const round = useSelector((state) => state.round.data);
  const { data, loading } = useSelector(
    (state) => state.leaderboards.projectsLeaderBoard
  );

  const projects_columns = [
    {
      title: "Ranking",
      dataIndex: "sr",
      // width: "27.5%",

      render: (data) => {
        return (
          <span
            style={{
              color: data?.color,
              whiteSpace: "nowrap",
            }}
          >
            <Popover content={"Ranking " + data?.text}>{data?.text} </Popover>
          </span>
        );
      },
      EXPAND_COLUMN: true,
    },
    {
      title: "Projects",
      dataIndex: "name",
      ellipsis: true,
      // width: "40%",
      EXPAND_COLUMN: false,
      render: (data) => {
        return (
          <span style={{ color: data?.color }}>
            <Popover content={data?.text}>{data?.text}</Popover>
          </span>
        );
      },
    },

    {
      title: "Total Points",
      dataIndex: "combinePoints",
      EXPAND_COLUMN: false,
      render: (data) => {
        return (
          <span className={`tableIconRow ${!data ? "none" : ""}`}>
            <Popover content={"Total Points " + data?.text}>
              <span style={{ color: data?.color }}>{data?.text}</span>
            </Popover>
          </span>
        );
      },
    },
    {
      title: " Rewards",
      dataIndex: "points",
      EXPAND_COLUMN: false,
      render: (data) => {
        if (!data) return;
        const icon = data?.icon;
        const icons = data?.icons || [];
        const findIsUSD = icons?.find((e) => e === "USD");
        const findIsNFT = icons?.find((e) => e === "NFT");
        const is2 = Array.isArray(icons) && icons?.length === 2;
        const messageProject = `Prize in ${
          is2 ? "NFT and token" : findIsNFT ? "NFT" : "TOKEN"
        }`;
        return (
          <span
            className="tableIconRow"
            style={{
              color: data?.color || undefined,
              marginLeft: is2 ? "-10px" : undefined,
            }}
          >
            {data?.childRow && data?.text ? (
              <Popover content={`Prize in ${icon}`}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "center",
                    gap: ".2rem",
                  }}
                >
                  <GiTrophy
                    style={{
                      marginLeft: "0rem",
                      top: "1px",
                      position: "relative",
                      fontSize: ".93rem",
                    }}
                  />
                  <span style={{ fontSize: ".95rem" }}>{data?.text}</span>
                  {icon === "USD" ? (
                    <GetDollarSign
                      styles={{
                        top: "3px",
                        left: "-4px",
                      }}
                    />
                  ) : null}
                  {icon !== "USD" || icon === "NFT" ? (
                    <GetNFTSign styles={{ top: "3.5px", height: "1.2rem" }} />
                  ) : null}
                </div>
              </Popover>
            ) : (
              !data?.childRow && ( // parent
                <Popover content={messageProject}>
                  <Fragment>
                    <span style={{ fontSize: ".95rem" }}>{data?.text}</span>
                    {findIsNFT ? <GetNFTSign /> : null}
                    {findIsUSD ? (
                      <GetDollarSign
                        styles={{
                          top: "3px",
                          left: "-4px",
                        }}
                      />
                    ) : null}
                    {!findIsNFT && !findIsUSD ? <GetNFTSign /> : null}
                  </Fragment>
                </Popover>
              )
            )}
          </span>
        );
      },
    },
    {
      title: " ",
      // width: "29%",
      ellipsis: true,
      dataIndex: "btn",
      EXPAND_COLUMN: false,
      render: (data) => {
        return (
          <Fragment>
            {data?.icon ? (
              <Row
                className="tableIconRow icons_row"
                gutter={[2, 2]}
                justify="space-between"
              >
                <Col span={11} className="icon_col">
                  <Popover content="In-Game Score">
                    <GiHighKick />
                    <p>{data?.ingame}</p>
                  </Popover>
                </Col>
                <Col span={11} className="icon_col">
                  <Popover content="Quests Score">
                    <GiRadarSweep />
                    <p>{data?.combine}</p>
                  </Popover>
                </Col>
              </Row>
            ) : null}
            {data?.points ? (
              <div className="tableIconRow">
                <p>{data?.points}</p>
              </div>
            ) : null}
            {data?.id ? <QuestButton data={data} /> : null}
          </Fragment>
        );
      },
    },
  ];

  // world cup projects data for drop down (users list)
  const subplayers = (projectdata, zone, sweep = [], usersPrizeType = []) => {
    const data = projectdata?.users;
    if (!Array.isArray(data)) return;
    const name = user?.username;
    const find = data?.find((e) => e?.userName === name);

    const arr = data?.slice(0, 10).map((e, i) => {
      let name = e?.userName;
      const color = "white";
      const colors = "#99FF80";

      return (
        i < 10 && {
          key: e?.userId,
          sr: {
            text: `#${e?.position}`,
            color,
          },
          name: {
            text: name,
            color,
          },
          btn: {
            points: format(e?.totalPoints),
          },
          specialReward: {
            child: true,
          },
          points: {
            color: colors,
            noIcon: true,
            text: sweep?.[i],
            childRow: true,
            icon: usersPrizeType?.[i],
          },
        }
      );
    });

    arr?.unshift({
      key: "icon",
      btn: {
        icon: true,
        combine: format(projectdata?.totalQuestPoint || 0),
        ingame: format(projectdata?.totalPoints || 0),
      },
      points: format(user?.k),
      specialReward: {
        child: true,
      },
    });
    let serched = false;
    arr?.forEach((e) => {
      if (e.name?.text === find?.userName) {
        serched = true;
        return;
      }
    });
    if (!serched) {
      arr?.push({
        key: data?.username,
        sr: {
          text: `#${find?.position}`,
        },
        specialReward: {
          child: true,
        },
        name: {
          text: user?.username,
        },
        btn: {
          points: find?.totalPoints,
        },
      });
    }
    return arr;
  };

  const projects_data = useMemo(
    () =>
      data?.map((e, i) => {
        const winnerProjectCount = round?.WPD?.length || 0;

        const length = winnerProjectCount
          ? winnerProjectCount
          : data.length / 2;
        const colors = i < length ? "#99FF80" : "#FF9980";

        const WPD = round?.WPD;
        const LPD = round?.LPD;
        const getIndex = () => {
          const l = WPD?.length;
          return i - l;
        };
        return {
          key: i,
          sr: {
            text: `#${i + 1}`,
            color: colors,
          },
          btn: {
            id: e,
          },
          points: {
            color: colors,
            text:
              i < length
                ? WPD?.[i]?.sweepTotal || 0
                : LPD?.[getIndex()]?.sweepTotal || 0,
            icons:
              i < length
                ? WPD?.[i]?.sweepType || []
                : LPD?.[getIndex()]?.sweepType || [],
          },
          combinePoints: {
            text: format((e?.totalQuestPoint || 0) + (e?.totalPoints || 0)),
            color: colors,
          },

          name: {
            text: e?.projectName,
            color: colors,
          },

          children: subplayers(
            e,
            i < length ? "winners" : "losers",
            i < length
              ? WPD?.[i]?.sweepUsers || []
              : LPD?.[getIndex()]?.sweepUsers || [],
            i < length
              ? WPD?.[i]?.usersPrizeType || []
              : LPD?.[getIndex()]?.usersPrizeType || []
          ),
        };
      }),
    [data, user?.username, round]
  );
  return {
    projects_columns,
    projects_data,
    project_loading: loading,
  };
};

const QuestButton = ({ data }) => {
  const user = useSelector((state) => state.profile.profile);
  const questsubmissions = useSelector((state) => state.modal.questsubmissions);
  const projects = useSelector(
    (state) => state.leaderboards.projectsLeaderBoard.data
  );

  const { openNotification } = useNotification();
  const { groupCallSegment } = useSegment();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!projects) return;

  return (
    <NeonCard
      className={"tablebtn questButton"}
      styles={{
        cursor: "pointer",
        margin: "auto",
        minWidth: "3.3rem",
        minHeight: "unset !important",
      }}
      onClick={() => {
        if (!user?.email) {
          return openNotification("Login First!");
        }
        if (questsubmissions?.length > 0) {
          const ProjectId = data?.id?.projectId;
          const find = questsubmissions?.find(
            (e) => e?.projectId !== ProjectId
          );
          if (find) {
            const name = projects?.find(
              (e) => e?.projectId === find?.projectId
            );
            return openNotification(
              `You are already doing quests for  ${name?.projectName}`
            );
          }
        }
        const name = SEGMENT_EVENTS.click_quest.event_name;
        const obj = SEGMENT_EVENTS.click_quest.data_to_send;
        obj.type = data?.id?.projectName;
        groupCallSegment(name, obj);
        dispatch(setmodalopen("quests"));

        const d = data.id;
        const url = `${d.projectName}/${d.projectId}`;
        return navigate(URLS.client.quests.quests + url);
      }}
    >
      <Popover
        content={
          <>
            Earn <b>more points</b> by completing <b>quests</b>.
          </>
        }
        style={{ fontSize: "1rem" }}
        trigger={"hover"}
      >
        Quests
      </Popover>
    </NeonCard>
  );
};
