// import { unload, load } from "../../Store/Features/loadingSlice";
// import { setmodalopen } from "../../Store/Features/modalSlice";
// import { auth, cloudurl } from "../../config/firebase";
// import useNotification from "../useNotifcation";
// import { useDispatch } from "react-redux";
// import axios from "axios";

// export const useAxios = () => {
//   const dispatch = useDispatch();
//   const { openNotification } = useNotification();

//   const get = async (url, messages) => {
//     try {
//       dispatch(load());
//       const idtoken = await auth.currentUser?.getIdToken();

//       const response = await axios.get(`${cloudurl}/${url}`, {
//         headers: { Authorization: `Bearer ${idtoken}` },
//       });

//       dispatch(unload());

//       if (response.status === 200) {
//         openNotification(messages?.success || "Action Performed", "success");
//       } else if (response.status === 403) {
//         openNotification("Unauthorized", "error");
//       } else {
//         openNotification(messages?.error || "Couldn't perform action", "error");
//       }
//       dispatch(setmodalopen(false));
//     } catch (e) {
//       console.log(e);
//       dispatch(setmodalopen(false));
//       dispatch(unload());
//       openNotification(messages?.error || "Couldn't perform action", "error");
//     }
//   };
//   const post = async (url, body, messages) => {
//     try {
//       dispatch(load());

//       const idtoken = await auth.currentUser?.getIdToken();
//       const response = await axios.post(`${cloudurl}/${url}`, body, {
//         headers: { Authorization: `Bearer ${idtoken}` },
//       });

//       dispatch(unload());

//       if (response.status === 200) {
//         if (body?.action !== "check")
//           openNotification(messages?.success || "Action Performed", "success");
//       } else if (response.status === 403) {
//         openNotification("Unauthorized", "error");
//       } else {
//         if (body?.action !== "check")
//           openNotification(
//             messages?.error || "Couldn't perform action",
//             "error"
//           );
//       }
//       dispatch(setmodalopen(false));
//       return response.data;
//     } catch (e) {
//       console.log(e);

//       dispatch(setmodalopen(false));
//       dispatch(unload());

//       if (body?.action !== "check")
//         openNotification(messages?.error || "Couldn't perform action", "error");
//     }
//   };
//   const postSimple = async (url, body) => {
//     try {
//       const idtoken = await auth.currentUser?.getIdToken();
//       const response = await axios.post(`${cloudurl}/${url}`, body, {
//         headers: { Authorization: `Bearer ${idtoken}` },
//       });

//       return response;
//     } catch (e) {
//       console.log(e);
//       return e.message;
//     }
//   };
//   const postForm = async (url, body, messages) => {
//     try {
//       dispatch(load());

//       const idtoken = await auth.currentUser?.getIdToken();
//       const response = await axios.postForm(`${cloudurl}/${url}`, body, {
//         headers: { Authorization: `Bearer ${idtoken}` },
//       });

//       dispatch(unload());

//       if (response.status === 200) {
//         openNotification(messages?.success || "Action Performed", "success");
//       } else if (response.status === 403) {
//         openNotification("Unauthorized", "error");
//       } else {
//         openNotification(messages?.error || "Couldn't perform action", "error");
//       }
//       dispatch(setmodalopen(false));
//     } catch (e) {
//       console.log(e);

//       dispatch(setmodalopen(false));
//       dispatch(unload());

//       openNotification(messages?.error || "Couldn't perform action", "error");
//     }
//   };
//   return { get, post, postForm, postSimple };
// };
