import { getDatabase, ref, onValue, update } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import { setquestFilters } from "../../Store/Features/AdminQuests";
import useNotification from "../useNotifcation";
import { auth } from "../../config/firebase";

export const useQuestFilters = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.Admin.admin);
  const { openNotification } = useNotification();

  const getQuestFilters = (newData) => {
    const cu = auth.currentUser?.uid;
    if (!cu || !admin?.role) return;

    try {
      const db = getDatabase();
      const nftwcProjectsRef = ref(db, "questFitlers");
      onValue(nftwcProjectsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          dispatch(setquestFilters(data));
        } else if (data === null || data === undefined) {
          dispatch(setquestFilters([]));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateQuestFilters = (newData) => {
    const cu = auth.currentUser?.uid;
    if (!cu || !admin?.role) return;
    try {
      const obj = {
        [newData]: newData,
      };
      const db = getDatabase();

      const nftwcProjectsRef = ref(db, "questFitlers");
      update(nftwcProjectsRef, obj)
        .then((e) => {
          openNotification("Filter added", "success");
        })
        .catch(() => {
          openNotification("Coudn't add filter", "error");
        });
    } catch (e) {
      console.log(e);
    }
  };
  const removeQuestFilters = (newData) => {
    const cu = auth.currentUser?.uid;
    if (!cu || !admin?.role) return;
    try {
      const obj = {
        [newData]: null,
      };
      const db = getDatabase();

      const nftwcProjectsRef = ref(db, "questFitlers");
      update(nftwcProjectsRef, obj)
        .then((e) => {
          openNotification("Filter removed", "success");
        })
        .catch(() => {
          openNotification("Coudn't remove filter", "error");
        });
    } catch (e) {
      console.log(e);
    }
  };

  return { removeQuestFilters, getQuestFilters, updateQuestFilters };
};
