import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import { Spin } from "antd";
import theme from "../../theme";

const ContainerOverlay = styled.div`
  position: fixed;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999;
`;
const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.ADMIN_BACKGROUND_MAIN};
  padding: 20px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SpinStyled = styled(Spin)`
  color: white !important ;
  fill: white !important;
  .ant-spin-dot-item {
    background-color: white !important;
    color: white !important;
  }
  .ant-spin-text {
    color: white !important;
  }
`;

const Loader = ({ text }) => {
  const loading = useSelector((state) => state.loading.loading);
  if (!loading) {
    return null;
  }
  return (
    <ContainerOverlay>
      <Overlay>
        {/* <SpinStyled
          className="ANTD_Spinner"
          size="large"
          style={{ color: "white" }}
        /> */}

        <ThreeCircles
          visible={true}
          height="100"
          width="100"
          // color="white"
          color={theme.TEXT_COLOR_PRIMARY}
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Overlay>
    </ContainerOverlay>
  );
};

export default Loader;

// <div className="overlay-container">
//   <div className="overlay">
//     <Spin className="spinner" size="large" />
//     <h2 className="overlay-text">
//       {pathname.includes("play")
//         ? "Game is Loading..."
//         :
//     </h2>
//   </div>
// </div>
