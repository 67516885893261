import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { AiOutlineProject } from "react-icons/ai";
import { RiGlobalLine } from "react-icons/ri";
import { GiSprint } from "react-icons/gi";
import { FaList, FaFileCsv, FaShoppingCart } from "react-icons/fa";
import { BsCalendarEvent, BsQuestionSquare } from "react-icons/bs";
import { BiCategoryAlt, BiText } from "react-icons/bi";
import {
  MdOutlineEmojiEvents,
  MdAdminPanelSettings,
  MdLeaderboard,
} from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";

const u = {
  client: {
    home: "/",
    quests: {
      quests: "/nftwc/quests/",
      mysubmissions: "/nftwc/quests/",
    },
  },
  admin: {
    home: "/",
  },
};
export const URLS = u;
export const SPRINTQUESTTYPE = "sprintQuest";
export const GLOBALQUESTTYPE = "globalQuest";
export const MYAPPLICATIONS = "myapplications";

export const CHAINS = {
  ETHEREUM: {
    chain: "eth",
    name: "Ethereum",
    image: ["assets/wallets/eth.png", "assets/wallets/polygon.png"],
  },
  WAX: {
    chain: "wax",
    name: "WAX Cloud ",
    image: ["assets/wallets/wax-chain.png"],
  },

  VCHAIN: {
    chain: "vchain",
    name: "VECHAIN ",
    image: ["assets/wallets/vechain.png"],
  },
  SOLANA: {
    chain: "solana",
    name: "SOLANA ",
    image: ["assets/wallets/solana.png"],
  },
  Cardano: {
    chain: "cardano",
    wallet: "metamask",
    name: "Cardano",
    image: ["assets/wallets/cardano.png"],
    imageDisconnect: ["assets/wallets/wallets.png"],

    fullName: "Cardano",
  },
};
export const AdminRoutes = [
  {
    url: "/intradashboard/questmanager",
    name: "QUEST",
    Icon: <HomeOutlined />,
  },
  {
    name: "APPLICATIONS",
    url: "/intradashboard/questapplications",
    Icon: <UserOutlined />,
  },

  {
    url: "/intradashboard/projects",
    name: "PROJECTS",
    Icon: <FaList />,
  },
  {
    url: "/intradashboard/rounds",
    name: "ROUNDS",
    Icon: <AiOutlineProject />,
  },
  {
    url: "/intradashboard/shop",
    name: "SHOP",
    Icon: <FaShoppingCart />,
  },
  {
    url: "/intradashboard/adminmanager",
    name: "ADMIN MANAGER",
    Icon: <MdAdminPanelSettings />,
    isManager: true,
  },
  {
    url: "/intradashboard/nftwc",
    name: "NFTWC",
    Icon: <MdOutlineEmojiEvents />,
  },

  {
    url: "/intradashboard/content",
    name: "Content",
    Icon: <BiText />,
  },
  {
    Icon: <IoMdAnalytics />,
    name: "Analytics",
    url: "/intradashboard/analytics",
    isDev: true,
  },
  {
    Icon: <FaFileCsv />,
    name: "Download CSV",
    url: "/intradashboard/leaderboardCSV",
  },
];

export const AdminQuizQuestions = [
  {
    url: "/intradashboard/quizquestions",
    name: "Quiz Questions",
    Icon: <BsQuestionSquare />,
  },
  {
    url: "/intradashboard/categories",
    name: "Categories",
    Icon: <BiCategoryAlt />,
  },
];
export const AdminRoutesGernalQuestSystemRoutes = [
  {
    url: "/intradashboard/generalquestmanager",
    name: "QUEST",
    Icon: <HomeOutlined />,
  },
  {
    name: "APPLICATIONS",
    url: "/intradashboard/generalquestapplications",
    Icon: <UserOutlined />,
  },

  {
    url: "/intradashboard/generalsprints",
    name: "SPRINTS",
    Icon: <AiOutlineProject />,
  },

  {
    url: "/intradashboard/gqs",
    name: "GQS",
    Icon: <MdOutlineEmojiEvents />,
  },

  {
    Icon: <FaFileCsv />,
    name: "Download CSV",
    url: "/intradashboard/generalleaderboardCSV",
  },
];

export const GeneralRoutes = [
  {
    url: "/quests/generalquests",
    name: "Quest Board",
    Icon: <RiGlobalLine style={{ fontSize: "1.2rem" }} />,
  },
  {
    url: "/quests/sprintquests",
    name: "Sprint Board",
    Icon: <GiSprint style={{ fontSize: "1.1rem" }} />,
  },
  {
    name: "My SUBMISSIONS",
    url: "/quests/mysubmissions",
    Icon: <UserOutlined />,
  },
  {
    Icon: <MdLeaderboard />,
    name: "LeaderBoard",
    url: "/quests/leaderboard",
  },
  {
    Icon: <BsCalendarEvent />,
    name: "Goto NFTWC",
    isLeaderBoard: true,
  },
];
export const NFTWCQUESTS = [
  {
    url: u.client.quests.quests,
    name: "Quest Board",
    Icon: <RiGlobalLine style={{ fontSize: "1.2rem" }} />,
  },

  {
    name: "My SUBMISSIONS",
    url: u.client.quests.mysubmissions,
    Icon: <UserOutlined />,
  },

  {
    Icon: <BsCalendarEvent />,
    name: "Goto NFTWC",
    url: "/",
    isLeaderBoard: true,
  },
];
