import { theme } from "antd";

const { defaultSeed, darkAlgorithm } = theme;

const darkSeed = {
  ...defaultSeed,
  fontFamily: "Chakra Petch",
  colorPrimary: "#64d2ff",
  colorBgBase: "#1F1F1F",
  colorLink: "#64d2ff",
};

const darkMapTokens = darkAlgorithm(darkSeed);

export default darkMapTokens;
