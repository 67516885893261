import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wombatclicked: false,
};

export const WombatWallet = createSlice({
  name: "WombatWallet",
  initialState,
  reducers: {
    setWombatClicked: (state, action) => {
      state.wombatclicked = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWombatClicked } = WombatWallet.actions;

export default WombatWallet.reducer;
