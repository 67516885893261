import { useEffect, useState } from "react";
import variables from "@styles/variables/_variables.scss";

export const useBreakPoints = () => {
  const {
    "xs-breakpoint": xs,
    "sm-breakpoint": sm,
    "md-breakpoint": md,
    "lg-breakpoint": lg,
    "xl-breakpoint": xl,
    "xxl-breakpoint": xxl,
  } = variables;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth } = window;
    let size;

    if (innerWidth < Number(xs) && innerWidth < Number(sm)) {
      size = "xs";
    } else if (innerWidth >= Number(sm) && innerWidth < Number(md)) {
      size = "sm";
    } else if (innerWidth >= Number(md) && innerWidth < Number(lg)) {
      size = "md";
    } else if (innerWidth >= Number(lg) && innerWidth < Number(xl)) {
      size = "lg";
    } else if (innerWidth >= Number(xl) && innerWidth < Number(xxl)) {
      size = "xl";
    } else {
      size = "xxl";
    }

    return {
      xs: size === "xs",
      sm: size === "sm",
      md: size === "md",
      lg: size === "lg",
      xl: size === "xl",
      xxl: size === "xxl",
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }
    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  return windowSize;
};
