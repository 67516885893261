import React from "react";

function Logo() {
  return (
    <div className="logo">
      <img alt="logo" src="/assets/logo/logo.png" />
    </div>
  );
}

export default Logo;
