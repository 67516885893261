import PagniatedCol from "../PaginatedCol";
import { Col, Row } from "antd";

const QuestsApplications = () => {
  return (
    <Row className="QuestManager">
      <Col span={24} className="buttoncol"></Col>
      <PagniatedCol />
    </Row>
  );
};

export default QuestsApplications;
