import React from "react";
import { useSelector } from "react-redux";
import { Quiz } from "./quiz";
import { NormalQuest } from "./NormalQuest";

const QuestsWrapper = () => {
  const QUEST = useSelector((state) => state.modal.selectedquest);
  const round = useSelector((state) => state.round.data);
  const type = QUEST?.type;

  if (type === "quiz") {
    // show Quiz Quest
    return <Quiz QUEST={QUEST} round={round} />;
  } else {
    // show blockchain- social quest ui
    return <NormalQuest QUEST={QUEST} round={round} />;
  }
};

export default QuestsWrapper;
