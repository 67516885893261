import { Button } from "antd";
import classNames from "classnames";
import React, { useRef } from "react";

/**
 * @param {{
 *   onClick?: ()=> void,
 *   text?: string,
 *   className?: string,
 *   icon?: html,
 *   style?: CSSProperties,
 *   stylebtn?: CSSProperties,
 *   red?: boolean,
 *   isLoading?: boolean,
 *   downlaod,
 *   href
 * }} props
 */

export const BUTTON = ({
  red,
  text,
  onClick,
  icon,
  isLoading,
  style = {},
  stylebtn,
  className,
  buttonClassName,
  downlaod,
  href,
}) => {
  const ref = useRef();
  const modifiedStyles = {
    ...style,
  };

  return (
    <div
      onClick={isLoading !== true ? onClick : null}
      className={`btnwrapper ${className ? className : ""} ${red ? "red" : ""}`}
      style={{ ...modifiedStyles }}
    >
      <Button
        ref={ref}
        href={href ? href : undefined}
        download={downlaod}
        target={"_blank"}
        className={classNames("beforeafter", buttonClassName)}
        disabled={isLoading}
        icon={isLoading ? null : icon}
        style={stylebtn ? { ...stylebtn } : undefined}
      >
        {text}
      </Button>
    </div>
  );
};
