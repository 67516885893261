import { authrequest } from "../../config/api";
import { USERENDPOINTS } from "../../config/endpoints";
import useNotification from "../useNotifcation";

export const useUsersNFTs = () => {
  const { openNotification } = useNotification();

  const getAllNFTs = async (walletaddress, chain, Nonotification) => {
    if (!walletaddress && !chain) {
      if (!Nonotification) openNotification("Invalid address", "error");
      return null;
    }
    try {
      const apiEndpoint = `${USERENDPOINTS.getUserWalletContents}?owner=${walletaddress}&chain=${chain}`;
      const { data } = await authrequest({ endpoint: apiEndpoint });
      return data || [];
    } catch (error) {
      console.log({ error });
      if (!Nonotification) openNotification("Error fetching nfts", "error");

      return [];
    }
  };

  return { getAllNFTs };
};
