import { useProjects } from "@context/ProjectsProvider";
import { useREALProfile } from "@context/ProfileProvider";
import { useMemo } from "react";

const getImagePasses = ({ name, chain }) => {
  if (!name || !chain) return "";
  const newName = name.replace(/\s/g, "");
  const extension = chain === "wax" ? "png" : "jpg";
  return `/assets/nft/${chain}/${newName}.${extension}`;
};

const GalaxyPassNames = ["Nebula", "Red Giant", "Black Hole", "Super Nova"];

const useUserNFTs = () => {
  const { projects } = useProjects();
  const { profile } = useREALProfile();

  const activeNFTs = useMemo(() => {
    const userNFTs = profile?.nfts || [];

    const activeNFTs = projects
      ? userNFTs
          .filter((nft) => {
            // if the nft is galaxy  pass, return true, we should show them.
            if (GalaxyPassNames.includes(nft.attributes)) {
              return true;
            }
            // if the nft is not a galaxy pass show it if the project is active.
            return projects.some((project) => project.id === nft.projectId);
          })
          .map((nft) => {
            return {
              ...nft,
              src:
                nft.img ??
                getImagePasses({
                  name: nft.attributes ?? nft.collection,
                  chain: nft.chain,
                }),
            };
          })
      : [];

    return activeNFTs;
  }, [projects, profile]);

  return {
    activeNFTs: activeNFTs,
  };
};

export default useUserNFTs;
