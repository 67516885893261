import { Card, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { load, unload } from "../../../../../Store/Features/loadingSlice";
import { setmodalopen } from "../../../../../Store/Features/modalSlice";
import NeonCard from "../../../../../common/neoncard";
import {
  useUpload,
  useGetImage,
  useNotification,
  useQuest,
} from "../../../../../hooks";
import CaliGraphy from "../../../../../common/Caligraphy";
import INPUT from "../../../../../common/Input";
import { BUTTON } from "../../../../../common/button";
import { GetTags } from "./tags";

export const NormalQuest = ({ QUEST, round }) => {
  const user = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.loading.loading);
  const { trackQuest, submitNftwcQuest, questValidation } = useQuest();
  const [validation, setvalidation] = useState(null);
  const { openNotification } = useNotification();
  const [image, setimage] = useState(null);
  const [imageBlob, setimageBlob] = useState(null);
  const [value, setvalue] = useState(QUEST?.proofLink);

  const { upload } = useUpload();
  const dispatch = useDispatch();
  const { getimage } = useGetImage();
  const { id } = useParams();
  const condition =
    QUEST?.type === "social"
      ? validation === "success" || image !== null
      : validation === "success";
  const duplicate = QUEST?.duplicate;
  const isSocial = duplicate ? QUEST?.type : QUEST?.type === "social";

  useEffect(() => {
    return () => {
      setimage(null);
      setvalue(null);
      setimageBlob(null);
    };
  }, []);

  useEffect(() => {
    if (QUEST?.image) {
      getimage(QUEST?.image).then((e) => setimageBlob(e));
    }
    return () => {};
  }, [QUEST]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Your existing code goes here
      if (String(value).trim().length > 0 && value) {
        const linkRegex =
          /(?:^|\s)(https:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#!]*[\w\-\@?^=%&amp;/~\+#])?)/;
        // const twitterRegex =
        //     /https?:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/status\/(\d{19})(?![^\d/])/;
        if (linkRegex.test(value)) {
          // if (twitterRegex.test(value)) {
          //   openNotification("Twitter link.", "success");
          //   setvalidation("success");
          // } else {
          //   openNotification("Link selected!", "success");
          //   setvalidation("success");
          // }
          openNotification("Link selected!", "success");
          setvalidation("success");
        } else {
          openNotification("Invalid link", "error");
          setvalidation("error");
        }
      } else {
        setvalidation("");
      }
    }, 800); // Wait for 500 milliseconds before triggering the effect

    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (QUEST?.description) {
      // Check if it contains a <p> element with one child that is a <br>
      const pElements = document.querySelectorAll(".questtext  p");
      pElements.forEach((p) => {
        if (
          p.children.length === 1 &&
          p.children[0].tagName.toLowerCase() === "br"
        ) {
          p.classList.toggle("p_none");
        }
      });
    }
  }, [QUEST?.description]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      setimage(null);
      return openNotification("Select a valid image");
    }
    if (file.size > 5 * 1024 * 1024) {
      openNotification(
        "hey! The image exceed the max allowed size for attachments. Pls try again with a lighter image."
      );
      setimage(null);
      setvalue(null);

      return;
    }
    setimage(file);
    setimageBlob(URL.createObjectURL(file));
    setvalue(null);
  };

  const onPost = async () => {
    if (loading) return;
    dispatch(load());

    const notValidated = await questValidation({
      type: "normal",
      QUEST,
      proofLink: value,
      projectId: id,
    });
    if (notValidated) {
      dispatch(unload());
      return;
    }
    if (!condition) {
      if (isSocial) {
        openNotification("Please either select a link or an image");
        return true;
      }
      openNotification("Please select a link");
      return true;
    }

    const obj = {
      completedBy: auth.currentUser?.uid,
      userName: user?.username,
      activeround: QUEST?.activeround,
      roundId: QUEST?.roundId,
      projectId: id,
      questId: QUEST?.id,
      questName: QUEST?.name,
      timesCanBeCompleted: QUEST?.timesCanBeCompleted,
      status: "pending",
      reward: parseInt(QUEST?.reward),
      createdAt: new Date().toISOString(),
      categories: QUEST?.categories,
    };
    if (value) obj["proofLink"] = value;
    try {
      let imageres;

      if (image !== null) {
        imageres = await upload(image, "images/");
        obj["image"] = imageres.metadata.fullPath;
      }
      await submitNftwcQuest({ data: obj });
      openNotification("You submitted the quest correctly", "success");
      await trackQuest({ QUEST, id });

      dispatch(setmodalopen(false));
      dispatch(unload());
    } catch (e) {
      console.log(e);
      openNotification("Coudn't submit the quest!", "error");
      dispatch(unload());
    }
  };

  return (
    <NeonCard
      className={"quest_card largecurves"}
      styles={{
        marginInline: "auto",
        overflow: "hidden",
        height: "unset !important",
        maxHeight: "unset !important",
        minHeight: "unset !important",
      }}
    >
      <Card className="quest_card">
        <h1>
          {QUEST?.name}
          <p>{duplicate ? "(Re-Submission)" : null}</p>
        </h1>
        <div className="mendatory">
          <span> Mandatory to tag:</span>
          <GetTags />
        </div>
        <p
          className="questtext"
          dangerouslySetInnerHTML={{
            __html: QUEST?.description,
          }}
        />

        {isSocial ? (
          <CaliGraphy
            classname="smaller"
            style={{ marginBlock: "2rem .5rem" }}
            content="(either use link or upload image)"
          />
        ) : null}
        <Form
          onFinish={(e) => {}}
          labelCol={24}
          className="card_form"
          style={{
            display: "block",
            flexDirection: "column",
            gap: "1rem",
            // minHeight: "300px",
          }}
        >
          <Form.Item label={"Paste here the proof link"}>
            <INPUT
              type="text"
              placeholder={"https://twitter.com/johndoe/status/1234567890"}
              value={value}
              setvalue={setvalue}
            />
          </Form.Item>

          {isSocial ? (
            <Form.Item>
              <INPUT
                className="file"
                type="file"
                accept="image/*"
                setvalue={handleImageUpload}
                setimage={setimage}
              />
            </Form.Item>
          ) : null}
        </Form>

        {imageBlob ? (
          <div className="imageborder">
            <img alt="" src={imageBlob} />
          </div>
        ) : image ? (
          <div className="imageborder">
            <img alt="" src={URL.createObjectURL(image)} />
          </div>
        ) : null}

        <Row
          justify={"space-between"}
          align={"center"}
          className="btnsrow"
          gutter={[15, 15]}
        >
          {condition ? (
            <BUTTON
              text={"POST"}
              onClick={onPost}
              style={{ marginLeft: "auto" }}
            />
          ) : (
            <>
              <BUTTON
                text={QUEST?.reward + " pt."}
                style={{ marginLeft: "auto" }}
                className="nobtn"
              />
              {/* <BUTTON
                style={{ marginLeft: "1rem" }}
                text={round?.roundName}
                className="nobtn"
              /> */}
            </>
          )}
        </Row>
      </Card>
    </NeonCard>
  );
};
