import React, { useState } from "react";
import NeonCard from "@common/neoncard";
import styles from "./styles.module.scss";
import classNames from "classnames";
import DynamicValueButton from "../DynamicValueButton";
import { useCart } from "../../context/CartProvider";
import useUser from "../../hooks/useUser";
import { useNotification } from "hooks";
import ProjectSelectorModal from "./ProjectSelectorModal";
import ProjectSelect from "../ProjectSelect";

// setHoverEffect controls the hover effect on the card (Parent)
function AddToCartButton({ className, setHoverEffect, productId }) {
  const { openNotification } = useNotification();
  const [selectorModalOpen, setSelectorModalOpen] = useState(false);
  const { isLoggedIn } = useUser();
  const {
    products,
    updatingProductIds,
    errorProductIds,
    addProduct,
    removeProduct,
    updateProduct,
  } = useCart();

  const isProductInCart = products.some((product) => product.id === productId);
  const currentProduct = products.find((product) => product.id === productId);
  const isProductUpdating = updatingProductIds.includes(productId);
  const isProductError = errorProductIds.includes(productId);

  const value = isProductInCart ? currentProduct.quantity : 0;

  const handleAddProduct = async (e) => {
    e.stopPropagation();

    if (!isLoggedIn) {
      openNotification("Please login to add products to cart");
      return;
    }

    setSelectorModalOpen(true);
  };

  if (value > 0)
    return (
      <>
        <ProjectSelect
          disabled={isProductUpdating}
          setHoverEffect={setHoverEffect}
          value={currentProduct.projectId}
          onChange={(projectId) => {
            updateProduct({ productId, projectId });
          }}
          className={styles.select}
          wrapperClassName={styles.select_wrapper}
        />
        <DynamicValueButton
          value={value}
          onChange={(value) => {
            if (value === 0) {
              removeProduct(productId);
            } else {
              updateProduct({ productId, quantity: value });
            }
          }}
          disabled={isProductUpdating}
          setHoverEffect={setHoverEffect}
        />
      </>
    );

  const buttonText = isProductError ? "Something went wrong :(" : "Add to Cart";

  return (
    <>
      <ProjectSelectorModal
        open={selectorModalOpen}
        onProjectSelect={(projectId) => {
          setSelectorModalOpen(false);
          addProduct({
            productId,
            projectId,
            quantity: 1,
          });
        }}
        onClose={() => {
          setSelectorModalOpen(false);
        }}
      />

      <NeonCard
        className={classNames(
          styles.button,
          "hover",
          "add-to-cart",
          className,
          {
            [styles["button--disabled"]]: isProductUpdating || isProductError,
          }
        )}
        onClick={(e) => handleAddProduct(e)}
        onMouseEnter={() => {
          if (setHoverEffect) {
            setHoverEffect(false);
          }
        }}
        onMouseLeave={() => {
          if (setHoverEffect) {
            setHoverEffect(true);
          }
        }}
      >
        {buttonText}
      </NeonCard>
    </>
  );
}

export default AddToCartButton;
