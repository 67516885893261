import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

export const useRealTimedb = () => {
  const [rtdata, setrtdata] = useState([]);
  const [gqsRTDATA, setgqsRTDATA] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const nftwcProjectsRef = ref(db, "nftwcProjects");
    onValue(nftwcProjectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setrtdata(data);
      }
    });
    const gqsRTDATARef = ref(db, "GeneralQuestSystem");
    onValue(gqsRTDATARef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setgqsRTDATA(data);
      }
    });
  }, []);

  return { rtdata, gqsRTDATA };
};
