import React, { createContext, useState, useContext } from "react";
import { parseProduct, withShopBaseUrl } from "../../utils";

const ProductsContext = createContext();

function ProductsProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * The function `fetchProducts` asynchronously fetches product data from an API endpoint, parses the
   * data, sets the products state, and handles loading and error states.
   */
  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const url = new URL(withShopBaseUrl("/products"));
      const params = { status: "active" };
      url.search = new URLSearchParams(params).toString();

      const response = await fetch(url);

      const productsData = await response.json();
      const products = productsData.map(parseProduct);
      setProducts(products);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
      setError(error);
    }
  };

  const value = {
    products,
    isLoading,
    error,
    fetchProducts,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
}

function useProducts() {
  return useContext(ProductsContext);
}

export { ProductsProvider, useProducts };
