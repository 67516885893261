import { MdContentCopy } from "react-icons/md";
import CaliGraphy from "../../../../../../common/Caligraphy";
import { useSelector } from "react-redux";
import Flexed from "../../../../../../common/Styled/Flexed";
import { Popover } from "antd";
import { useMemo } from "react";
import { auth } from "../../../../../../config/firebase";
import { SiTether } from "react-icons/si";
import { GiTrophy } from "react-icons/gi";
import { useBreakPoints, useNotification } from "../../../../../../hooks";
function Referal() {
  const { openNotification } = useNotification();
  const user = useSelector((state) => state.profile.profile);
  const rankingboard = useSelector((state) => state.rankingboard);
  const activeround = useSelector((state) => state.round.data);
  const refferal_leaderBoard_prize =
    activeround?.refferal_leaderBoard_prize || [];

  let index = 0;
  const data = rankingboard?.data;
  const MyData = useMemo(
    () =>
      data?.find((e, i) => {
        if (e?.userId === auth.currentUser?.uid) {
          index = i;
          return e;
        }
      }),
    [data]
  );
  const handlecopy = () => {
    navigator.clipboard.writeText(user?.refferalCode);
    openNotification("referral copied successfully!");
  };
  const { md, lg, xl, xxl } = useBreakPoints();
  const isSize = md || lg || xl || xxl;
  return (
    <Popover
      title={<GetFlexed />}
      trigger={"hover"}
      className="LightColorPopup"
    >
      <>
        <Flexed
          flexDirection="column"
          gap=".8rem"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%", paddingBlock: isSize ? undefined : ".5rem" }}
        >
          <CaliGraphy
            rows={3}
            content={`Bring Friends and earn prizes in $`}
            level={3}
            style={{
              paddingInline: ".5rem",
              // textShadow: "none",
              fontSize: isSize ? "1.1rem" : ".9rem",
              maxBlockSize: isSize ? "90%" : "100%",
              fontWeight: 550,
            }}
            classname="blue shadow"
            type="Heading"
          />
          {user?.refferalCode ? (
            <CaliGraphy
              rows={2}
              level={5}
              type="Heading"
              style={{
                cursor: "pointer",
              }}
              content={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "1rem" }} onClick={handlecopy}>
                    {user.refferalCode}
                  </span>
                  <MdContentCopy
                    style={{ fontSize: "1.2rem" }}
                    onClick={handlecopy}
                  />
                </div>
              }
              classname="blue shadow smaller id"
            />
          ) : null}
          {/* {MyData ? ( */}
          <Flexed
            alignItems="center"
            // justifyContent="flex-start"
            justifyContent="center"
            gap="1rem"
            style={{ width: "100%", paddingInline: ".8rem" }}
          >
            <Flexed gap=".3rem" style={{ color: "white", fontSize: "1rem" }}>
              <GiTrophy
                style={{
                  marginLeft: "0rem",
                  top: "1px",
                  position: "relative",
                  fontSize: "1rem",
                }}
              />
              {MyData?.total || 0}
            </Flexed>
            <Flexed
              gap=".3rem"
              style={{ color: "rgb(153, 255, 128)", fontSize: "1rem" }}
            >
              <SiTether
                style={{
                  top: "2px",
                  position: "relative",
                  fontSize: "1rem",
                }}
              />
              {MyData?.total ? refferal_leaderBoard_prize?.[index] : 0}
            </Flexed>
          </Flexed>
          {/* ) : null} */}
        </Flexed>
      </>
    </Popover>
  );
}

export default Referal;
const GetFlexed = () => {
  const activeround = useSelector((state) => state.round.data);
  const percent = `${activeround?.refferal_prize_percentage}% `;
  return (
    <Flexed
      gap=".5rem"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className="LightColorPopup"
      style={{ fontSize: ".8rem", paddingBlock: ".3rem" }}
    >
      <div style={{ fontSize: ".9rem" }}>
        Bring <b>friends</b> and earn <b>prizes</b>
      </div>
      <div>How does it work?</div>
      <div style={{ textAlign: "center", maxWidth: "350px" }}>
        Every time your friends play, you will receive {percent || "10%"}of
        their points on the <b>Referral Leaderboard</b>
      </div>
    </Flexed>
  );
};
