import { collection, onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { db } from "../../../config/firebase";
import QuestsManager from "./QuestsManager";
import QuestsApplications from "./QuestsApplications";
import ModalWrapper from "./Modal";
import AdminLayout from "./layout";
import { useEffect, Fragment } from "react";
import Error404 from "../../../common/404";
import { useFunctions } from "./functions";
import { setCategories } from "../../../Store/Features/modalSlice";
const NftwcQuests = () => {
  const dispatch = useDispatch();

  // fetch categories for quests and quest applications
  useEffect(() => {
    const gqsRoundRef = collection(db, "categories");

    const unsubscribe = onSnapshot(gqsRoundRef, (querySnapshot) => {
      const a = querySnapshot?.docs.map((e) => {
        return { id: e.id, ...e.data() };
      });
      dispatch(setCategories(a));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useFunctions();

  return (
    <Fragment>
      <ModalWrapper />
      <div>
        <Routes>
          <Route path="/" element={<AdminLayout />}>
            <Route path="/" element={<QuestsManager />} />
            <Route path="/submissions" element={<QuestsApplications />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </div>
    </Fragment>
  );
};

export default NftwcQuests;
