import { Card, Col, Row } from "antd";
import React, { useMemo, useState } from "react";
import { auth } from "../../../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { load, unload } from "../../../../../Store/Features/loadingSlice";
import {
  setmodalopen,
  setselectedquest,
} from "../../../../../Store/Features/modalSlice";
import NeonCard from "../../../../../common/neoncard";
import {
  useNotification,
  useQuest,
  useGetQuizQuestions,
} from "../../../../../hooks";
import { BUTTON } from "../../../../../common/button";
import { GetTags } from "./tags";
import { useParams } from "react-router-dom";

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
const shuffledOptions = shuffleArray(["a", "b", "c", "d"]);
const obj = {
  0: "a)",
  1: "b)",
  2: "c)",
  3: "d)",
};

export const Quiz = ({ QUEST, round }) => {
  const { trackQuest, submitNftwcQuest, questValidation, checkQuizAttempts } =
    useQuest();
  const { getQuizes } = useGetQuizQuestions();
  const loading = useSelector((state) => state.loading.loading);

  // project id
  const { id } = useParams();
  const projectId = id;

  const quizs = useMemo(() => shuffleArray(QUEST?.quizs), [QUEST?.quizs]);

  const [arr, setArr] = useState(Array(quizs?.length).fill(null) || []);
  const user = useSelector((state) => state.profile.profile);
  const find = arr?.find((e) => e?.answer === null || e?.answer === undefined);

  const [page, setPage] = useState(0);
  const setValue = (id, val, i) => {
    const newArr = arr.map((aa, ii) => {
      if (ii === i) {
        return { id, answer: val };
      } else {
        return aa;
      }
    });
    setArr(newArr);
  };

  const dispatch = useDispatch();
  const { openNotification } = useNotification();

  const onPost = async () => {
    if (loading) return;
    dispatch(load());

    const notValidated = await questValidation({
      find,
      type: "quiz",
      QUEST,
      projectId,
    });
    if (notValidated) {
      dispatch(unload());
      return;
    }

    const obj = {
      completedBy: auth.currentUser?.uid,
      userName: user?.username,
      questId: QUEST?.id,
      questName: QUEST?.name,
      timesCanBeCompleted: QUEST?.timesCanBeCompleted,
      reward: parseInt(QUEST?.reward),
      roundId: QUEST?.roundId,
      projectId,
      categories: QUEST?.categories,
      quizs: arr,
      createdAt: new Date().toISOString(),
    };

    try {
      const data = await getQuizes({ quizzes: quizs });
      const checkArr = await checkQuizAttempts({ data, arr });
      obj["activeround"] = QUEST?.activeround;
      obj["roundId"] = QUEST?.roundId;
      if (checkArr?.length < arr?.length) {
        const correctAns = checkArr?.length;
        const totalAns = arr?.length;
        const val = correctAns + "/" + totalAns + " questions were correct";
        await submitNftwcQuest({
          data: { ...obj, status: "rejected", reason: val },
        });
        openNotification("quest rejected", "error");
      } else {
        await submitNftwcQuest({
          data: {
            ...obj,
            status: "approved",
          },
        });
        await trackQuest({ QUEST, id: projectId });

        openNotification("quest approved", "success");
      }

      // const res = await postSimple("quizManager", obj);
      dispatch(unload());

      dispatch(setselectedquest(null));
      dispatch(setmodalopen(false));
    } catch (e) {
      console.log(e);
      openNotification("Coudn't submit the quest!", "error");
      dispatch(unload());
    }
  };

  return (
    <NeonCard
      className={"quest_card largecurves"}
      styles={{
        marginInline: "auto",
        overflow: "hidden",
      }}
    >
      <Card className="quest_card">
        <h1>{QUEST?.name}</h1>
        <div className="mendatory">
          <span>
            {" "}
            QUIZ QUEST : {page + 1}/{quizs?.length}
          </span>
          <GetTags />
        </div>

        <Row
          style={{
            marginTop: "4rem",
            gap: "2rem",
            justifyContent: "flex-start",
          }}
        >
          {quizs?.map((e, i) => {
            const f = arr?.find((a) => a?.id === e?.id);
            const found = f?.answer;
            return (
              i === page && (
                <Col span={24} key={i}>
                  <h2 style={{ fontSize: "1.2rem", marginBottom: "1.3rem" }}>
                    {e?.question}
                  </h2>
                  <Row
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      rowGap: "1.2rem",
                      marginBottom: ".5rem",
                    }}
                  >
                    {shuffledOptions.map((optionKey, ind) => (
                      <Col
                        key={optionKey}
                        xs={24}
                        sm={24}
                        md={11}
                        lg={11}
                        style={{
                          fontSize: "1rem",
                          cursor: "pointer",
                          background:
                            found === e?.[optionKey] ? "#ffffff1f" : "",
                        }}
                        onClick={() => {
                          setValue(e?.id, e?.[optionKey], i);
                        }}
                      >
                        {`${obj[ind]} ${e?.[optionKey]}`}
                      </Col>
                    ))}
                  </Row>
                </Col>
              )
            );
          })}
        </Row>

        <Row justify={"end"} style={{ marginTop: "100px" }}>
          {page > 0 ? (
            <BUTTON
              style={{ marginLeft: "auto" }}
              text={"Previous"}
              onClick={() => {
                setPage((e) => {
                  if (e > 0) {
                    return e - 1;
                  } else return e;
                });
              }}
            />
          ) : null}
          {page < quizs?.length - 1 ? (
            <BUTTON
              style={{ marginLeft: page > 0 ? "1rem" : "auto" }}
              text={"Next"}
              onClick={() => {
                setPage((e) => {
                  if (e < quizs?.length - 1) {
                    return e + 1;
                  } else return e;
                });
              }}
            />
          ) : null}
          {page === quizs?.length - 1 && !find ? (
            <BUTTON
              style={{ marginLeft: page > 0 ? "1rem" : "auto" }}
              text={"POST"}
              onClick={onPost}
            />
          ) : null}
        </Row>
      </Card>
    </NeonCard>
  );
};
