import { useCart } from "../../../../../../context/CartProvider";
import { Button } from "antd";
import React from "react";
import { FaRegTrashCan } from "react-icons/fa6";

function DeleteButton({ productId }) {
  const { removeProduct, updatingProductIds } = useCart();

  const isLoading = updatingProductIds.includes(productId);
  return (
    <Button
      danger
      type="text"
      shape="circle"
      icon={<FaRegTrashCan fontSize={"22px"} />}
      disabled={isLoading}
      onClick={() => {
        if (isLoading) return;
        removeProduct(productId);
      }}
    />
  );
}

export default DeleteButton;
