import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  apploading: true,
  walletLoading: false,
};

export const LoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    unload: (state) => {
      state.loading = false;
    },
    setwalletLoading: (state, action) => {
      state.walletLoading = action.payload;
    },

    setapploading: (state, action) => {
      state.apploading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { load, unload, setapploading, setwalletLoading } =
  LoadingSlice.actions;

export default LoadingSlice.reducer;
