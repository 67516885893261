import { Button } from "antd";
import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Button
      type="text"
      shape="circle"
      onClick={() => {
        if (pathname === "/shop") {
          navigate("/");
          return;
        }

        navigate("/shop");
      }}
      icon={<FaArrowLeftLong color="white" fontSize={"20px"} />}
    />
  );
}

export default BackButton;
