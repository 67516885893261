import React from "react";
import styles from "./styles.module.scss";

import { Tabs } from "antd";
import PaddedCard from "../PaddedCard";

function Description({ content }) {
  const items = [
    {
      key: "1",
      label: "DESCRIPTION",
      children: <div className={styles.content}>{content}</div>,
    },
    {
      key: "2",
      label: "HOW IT WORKS",
      children: (
        <div className={styles.content}>
          <p>
            Boosters are your secret weapon for maximizing your potential score
            in our gaming world. When activated, these power-ups grant you the
            ability to increase your score for a specified duration. It's like
            having a turbocharged engine for your gaming journey!
          </p>

          <p>
            To harness the incredible benefits of a booster, simply navigate to
            your inventory and select the booster you wish to activate. Once
            activated, dive into your favorite games and watch as your score
            skyrockets to new heights.
          </p>

          <p>
            But here's the magic: while your booster is active, every point you
            earn is magnified by the booster's power. So, whether you're aiming
            for the leaderboard's summit or simply striving to surpass your
            personal best, these boosters will propel you toward your goals with
            unmatched speed and efficiency.
          </p>

          <p>
            So, gear up, activate your boosters, and embark on an exhilarating
            gaming adventure where every move counts and every score is
            amplified to extraordinary levels!
          </p>
        </div>
      ),
    },
  ];

  return (
    <PaddedCard className={styles.description}>
      <Tabs defaultActiveKey="1" items={items} />
    </PaddedCard>
  );
}

export default Description;
