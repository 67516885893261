import React from "react";
import { Modal } from "antd";
import NeonCard from "@common/neoncard";

import styles from "./styles.module.scss";
import classNames from "classnames";
import ProjectSelect from "../../ProjectSelect";

function ProjectSelectorModal({ open, onClose, onProjectSelect }) {
  const [selectedProjectId, setSelectedProjectId] = React.useState(null);

  return (
    // We need this wrapper div to stop the event propagation
    // Otherwise, the click event will be propagated to the parent component (AddToCartButton will make problems)
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal
        className={`interversamodal`}
        wrapClassName={`interversamodalwrapper`}
        centered
        transitionName=""
        open={open}
        onCancel={() => {
          setSelectedProjectId(null);
          onClose();
        }}
        onOk={onProjectSelect}
        destroyOnClose={true}
        footer={null}
        mask={true}
        styles={{
          mask: {
            backdropFilter: "blur(5px)",
          },
          body: {
            overflow: "hidden",
            opacity: 1,
          },
          content: {
            boxShadow: "unset",
          },
        }}
        closeIcon={<span style={{ display: "none" }} />}
        outsideClose={true}
        closable={true}
      >
        <NeonCard className="largecurves">
          <div className={styles.body}>
            <h3 className={styles.body__title}>Select a Project</h3>
            <p>
              {/* generate a text that why you need to select a project */}
              Each booster pack is associated with a project. Please select a
              project to continue. This booster pack will be associated with the
              selected project.
            </p>
            <ProjectSelect
              className={styles.body__select}
              wrapperClassName={styles.body__select_wrapper}
              onChange={(value) => setSelectedProjectId(value)}
              value={selectedProjectId}
            />
            <NeonCard
              className={classNames("hover", styles.body__submit_button)}
              onClick={() => {
                if (!selectedProjectId) {
                  return;
                }
                onProjectSelect(selectedProjectId);
                setSelectedProjectId(null);
              }}
            >
              Add to Cart
            </NeonCard>
          </div>
        </NeonCard>
      </Modal>
    </div>
  );
}

export default ProjectSelectorModal;
