import { useNotification } from "../../../../../hooks";

const arrOfTags = [
  // "#XMasGameFest",
  // "@adoptwombat",
  // "@AtomicHub",
  "#NFTWC2024",
  "@intraVerse_Game",
];
const GetTag = ({ tag }) => {
  const { openNotification } = useNotification();

  const handlecopy = (text) => {
    navigator.clipboard.writeText(text);
    openNotification(`Tag '${text}' copied successfully!`, "success");
  };
  return (
    <p className="twittertag" onClick={() => handlecopy(tag)}>
      {tag}
    </p>
  );
};

export const GetTags = () => {
  return (
    <div className="twittertagWrapper" style={{ flexWrap: "wrap" }}>
      {arrOfTags?.map((tag, i) => (
        <GetTag tag={tag} key={i} />
      ))}
    </div>
  );
};
