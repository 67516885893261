import React from "react";
import Description from "./components/Description";
import Controller from "./components/Controller";

import styles from "./styles.module.scss";
import useSingleProduct from "./useSingleProduct";
import { useParams } from "react-router-dom";
import BoosterAttributes from "../../../../common/BoosterAttributes";

function ProductPage() {
  const { id } = useParams();
  const { product } = useSingleProduct(id);

  if (!product) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__title_row}>
        <h1 className={styles.wrapper__title_row__title}>{product.name}</h1>

        <BoosterAttributes
          duration={product.duration}
          percentage={product.multiplier}
        />
      </div>
      <div className={styles.wrapper__content}>
        <Description content={product.description} />
        <Controller product={product} />
      </div>
    </div>
  );
}

export default ProductPage;
