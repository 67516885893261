// Don't ever use this component directly this is a size of `CartProduct` exported in index.jsx

import React from "react";
import styles from "./styles.module.scss";

import DeleteButton from "../components/DeleteButton";
import DynamicValueButton from "../../../../../../components/DynamicValueButton";
import { useCart } from "../../../../../../context/CartProvider";
import classNames from "classnames";
import ProjectSelect from "../../../../../../components/ProjectSelect";
import BoosterAttributes from "../../../../../../../../common/BoosterAttributes";

function Md({ product }) {
  const { removeProduct, updateProduct, updatingProductIds } = useCart();

  const isLoading = updatingProductIds.includes(product.id);

  return (
    <div className={styles.card}>
      <div className={styles.card__image_wrapper}>
        <img
          className={styles.card__image_wrapper__image}
          alt={`${product.name}`}
          src={product.imageUrl}
        />
      </div>
      <div className={styles.card__content}>
        <div className={styles.card__content__main}>
          <BoosterAttributes
            duration={product.duration}
            percentage={product.multiplier}
          />
          <span className={styles.card__content__main__title}>
            {product.name}
          </span>
          <span className={styles.card__content__main__description}>
            {product.description}
          </span>
        </div>
        <div className={styles.card__content__control_col}>
          <ProjectSelect
            disabled={isLoading}
            value={product.projectId}
            onChange={(projectId) => {
              updateProduct({ productId: product.id, projectId });
            }}
            className={styles.card__content__control_col__select}
            wrapperClassName={styles.card__content__control_col__select_wrapper}
          />

          <div className={styles.card__content__control_col__control_row}>
            <DynamicValueButton
              className={
                styles.card__content__control_col__control_row__quantity
              }
              value={product.quantity}
              disabled={isLoading}
              onChange={(value) => {
                if (value === 0) {
                  removeProduct(product.id);
                } else {
                  updateProduct({ productId: product.id, quantity: value });
                }
              }}
            />
            <span
              className={styles.card__content__control_col__control_row__price}
            >
              $ {product.price}
            </span>
            <DeleteButton productId={product.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

// TODO: This could be improved
const MdSkeleton = () => {
  return (
    <div className={classNames(styles.card, styles["card--skeleton"])}>
      <div className={styles.card__image_wrapper}>
        <div className={styles.card__image_wrapper__image} />
      </div>
      <div className={styles.card__content}>
        <div className={styles.card__content__main}>
          <span className={styles.card__content__main__title}></span>
          <span className={styles.card__content__main__description}></span>
        </div>
        <div className={styles.card__content__control_row}>
          <span className={styles.card__content__control_row__price}></span>
        </div>
      </div>
    </div>
  );
};

Md.Skeleton = MdSkeleton;

export default Md;
