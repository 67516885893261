import React from "react";
import { Col, Row, Button, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NeonCard from "../../../../../common/neoncard";
import { setActiveLeaderboardTab } from "../../../../../Store/Features/leaderboard";
import { FaInfoCircle } from "react-icons/fa";
import CaliGraphy from "../../../../../common/Caligraphy";
import Flexed from "../../../../../common/Styled/Flexed";

const LeaderBoardTabs = () => {
  const round = useSelector((state) => state.round.data);
  const activeTab = useSelector((state) => state.leaderboards.activeTab);

  const dispatch = useDispatch();

  const tab2 = (
    <CaliGraphy
      style={{ textAlign: "center", maxWidth: "350px", fontSize: ".9rem" }}
      type="text"
      isHtml={true}
      content={`<b>Important Notice: </b>Don't make fake accounts or use the same
          wallet. If you break this rule, you'll be banned, and your NFTs won't
          work in the tournament anymore.`}
    />
  );
  return round?.isGrouped ? (
    <Col sm={24} xs={24}>
      <Row>
        <Col span={24}>
          <NeonCard
            className={`neonbuttontabs TableBtn ${
              activeTab === 3 ? "active" : ""
            }`}
            onClick={() => {
              dispatch(setActiveLeaderboardTab(3));
            }}
          >
            <Button type="primary">Group A</Button>
          </NeonCard>
          <NeonCard
            className={`neonbuttontabs TableBtn ${
              activeTab === 4 ? "active" : ""
            }`}
            onClick={() => dispatch(setActiveLeaderboardTab(4))}
          >
            <Button type="primary">Group B</Button>
          </NeonCard>
        </Col>
      </Row>
    </Col>
  ) : (
    <Flexed flexDirection="row" style={{ width: "100%" }}>
      <GetTab text={"Player Board"} num={1} activeTab={activeTab} />
      <GetTab
        text={round?.title || "NFTWC2024"}
        num={2}
        activeTab={activeTab}
      />
      {/* <GetTab
        text={"Referral Board"}
        num={3}
        activeTab={activeTab}
        tab2={tab2}
      /> */}
    </Flexed>
  );
};

const GetTab = ({ tab2, activeTab, num, text }) => {
  const dispatch = useDispatch();
  return (
    <NeonCard
      className={`neonbuttontabs TableBtn  leaderboardtab  ${
        activeTab === num ? "active" : ""
      }`}
      onClick={() => dispatch(setActiveLeaderboardTab(num))}
    >
      <Button type="primary">{text}</Button>
      {tab2 ? (
        <Popover title={tab2} style={{ maxWidth: "300px" }} trigger={"hover"}>
          <FaInfoCircle color="white" />
        </Popover>
      ) : null}
    </NeonCard>
  );
};

export default LeaderBoardTabs;
