import { Col, Row } from "antd";
import { useBreakPoints } from "../../../../../../hooks";
import NeonCard from "../../../../../../common/neoncard";
import { useDispatch } from "react-redux";
import {
  setDisclaimer,
  setmodalopen,
} from "../../../../../../Store/Features/modalSlice";
import CaliGraphy from "../../../../../../common/Caligraphy";

function User() {
  const { xs, sm } = useBreakPoints();
  const dispatch = useDispatch();
  const hide = xs || sm;

  return (
    <Row
      align={"top"}
      gutter={[0, { xs: 10, sm: 15, md: 10, lg: 15, xxl: 18 }]}
    >
      <Col span={24}>
        <NeonCard
          bg={"/assets/backgrounds/bg_bluepinkNebula.webp"}
          className="login hover"
          onClick={() => {
            dispatch(setmodalopen("login"));
          }}
        >
          <CaliGraphy
            rows={1}
            content={`login`}
            level={2}
            classname="blue shadow"
            type="Heading"
          />
        </NeonCard>
      </Col>
      <Col span={24}>
        <NeonCard
          bg={"/assets/backgrounds/bg_nebulae.webp"}
          className="signup hover"
          onClick={() => {
            dispatch(setmodalopen("signup"));
          }}
        >
          <CaliGraphy
            rows={1}
            content={`signup`}
            level={2}
            classname="blue shadow"
            type="Heading"
          />
        </NeonCard>
      </Col>
      {hide ? null : (
        <Col span={24}>
          <NeonCard
            onClick={() => {
              const a = true;
              if (a) return null;
              else {
                dispatch(setmodalopen("disclaimer"));
                dispatch(
                  setDisclaimer({
                    text: "without login you cannot collect points and win prizes.",
                    button: "PLAY AS A GUEST",
                  })
                );
              }
            }}
            bg={"/assets/backgrounds/bg_nebulae_2.webp"}
            className="playasguest hover"
            styles={{ cursor: "not-allowed" }}
          >
            <CaliGraphy
              rows={2}
              content={`play as guest`}
              level={2}
              classname="blue shadow"
              type="Heading"
            />
            <CaliGraphy
              rows={2}
              classname="blue shadow"
              style={{
                fontSize: ".8rem",
                maxWidth: "95%",
                textAlign: "center",
                marginInline: "auto",
              }}
              // content={`Your experience will be limited`}
              level={3}
            />
          </NeonCard>
        </Col>
      )}
    </Row>
  );
}

export default User;
