import React, { useEffect, useState } from "react";
import { Layout as AntdLayout } from "antd";
import BackButton from "./BackButton";
import styles from "./styles.module.scss";
import { useBreakPoints } from "../../../../hooks/useBreakPoints";
import CartButton from "./CartButton";

const { Header: AntdHeader } = AntdLayout;

function Header() {
  const { sm, xs } = useBreakPoints();
  const isSmall = sm || xs;

  const [title, setTitle] = useState(isSmall ? "SHOP" : "INTRAVERSE SHOP");

  useEffect(() => {
    setTitle(isSmall ? "SHOP" : "INTRAVERSE SHOP");
  }, [isSmall]);

  return (
    <AntdHeader className={styles.header}>
      <div className={styles.header__content}>
        <BackButton />
        <img
          className={styles.header__content__logo}
          alt="intraverse logo"
          src="/assets/logo/simple-logo.png"
        />
        <h1 className={styles.header__content__title}>{title}</h1>
      </div>
      <CartButton />
    </AntdHeader>
  );
}

export default Header;
