import React, { Fragment } from "react";
import NeonCard from "../../../../../../common/neoncard";
import CaliGraphy from "../../../../../../common/Caligraphy";

function PlayNow({ handleclick }) {
  return (
    <Fragment>
      <EventDisplay handleclick={handleclick} />
    </Fragment>
  );
}

const EventDisplay = ({ handleclick }) => {
  return (
    <NeonCard onClick={handleclick} className={"nobg hover"} styles={{}}>
      <CaliGraphy
        level={2}
        type="Heading"
        content="PLAY"
        classname="blue shadow"
      />{" "}
    </NeonCard>
  );
};
const DefaultDisplay = ({ handleclick }) => {
  return (
    <NeonCard
      onClick={handleclick}
      bg={"/assets/backgrounds/bg1.webp"}
      className={"hover"}
    >
      <CaliGraphy
        level={2}
        type="Heading"
        content="PLAY"
        classname="blue shadow"
      />{" "}
    </NeonCard>
  );
};
export default PlayNow;
