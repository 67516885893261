import { useREALAuth } from "context/AuthProvider";
import { useState } from "react";
import { withBoosterBaseUrl } from "utils/utils";

const useClaimBooster = ({ revalidate }) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { auth } = useREALAuth();

  const claim = async () => {
    setIsLoading(true);

    try {
      const token = await auth.auth?.currentUser?.getIdToken();

      const header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(withBoosterBaseUrl("/claim"), {
        headers: header,
      });

      if (!response.ok) {
        throw new Error("Error claiming boosters");
      }

      if (revalidate) {
        revalidate();
      }

      setResponse({
        message: "Boosters claimed successfully",
        status: "success",
      });
    } catch (error) {
      setResponse({
        message:
          "There was an error claiming boosters, you have already claimed them",
        status: "error",
      });
      setError(error);
    }

    setIsLoading(false);
  };

  return {
    claim,
    response,
    isLoading,
    error,
  };
};

export default useClaimBooster;
