import SimpleCard from "@common/SimpleCard";
import React, { useMemo } from "react";

import styles from "./styles.module.scss";

import BoosterCard from "./BoosterCard";
import { useActiveBooster } from "context/ActiveBoosterProvider";
import NeonCard from "common/neoncard";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

function BoosterDetails({ boosters, isLoading, revalidate }) {
  const navigate = useNavigate();

  const { activeBooster } = useActiveBooster();

  const userHasActiveBooster = !!activeBooster;

  const body = useMemo(() => {
    if (!boosters?.length && isLoading) {
      return (
        <div className={styles.boosters_grid}>
          {[1, 2, 3]?.map((i) => {
            return <BoosterCard.Skeleton key={i} />;
          })}
        </div>
      );
    }
    if (boosters?.length === 0) {
      return (
        <div>
          <p className={styles.booster_content}>
            You don't have any boosters right now, You can buy boosters from
            shop or claim boosters if you have certain NFTs on your wallet.
          </p>

          <NeonCard
            className={classNames(styles.button, "hover")}
            onClick={() => {
              navigate("/shop");
            }}
          >
            Go to the Shop
          </NeonCard>
        </div>
      );
    }
    return (
      <div className={styles.boosters_grid}>
        {boosters?.map((booster) => {
          return (
            <BoosterCard
              key={booster.id}
              booster={booster}
              userHasActiveBooster={userHasActiveBooster}
              revalidate={() => {
                revalidate();
              }}
            />
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boosters, isLoading]);

  return (
    <SimpleCard title={"BOOSTERS"} className={styles.card}>
      <p className={styles.booster_content}>
        Activate a BOOSTER and start winning today!
      </p>
      {body}
    </SimpleCard>
  );
}

export default BoosterDetails;
