import styles from "./styles.module.scss";
import NeonCard from "@common/neoncard";
import classNames from "classnames";
import SimpleCard from "common/SimpleCard";
import { useNavigate } from "react-router-dom";

function LoginError() {
  const navigate = useNavigate();

  return (
    <SimpleCard title={"You are not logged in!"}>
      <p className={styles.content}>
        Login first to see your NFTs and Boosters!
      </p>

      <NeonCard
        className={classNames(styles.button, "hover")}
        onClick={() => {
          navigate("/");
        }}
      >
        BACK TO MAIN PAGE
      </NeonCard>
    </SimpleCard>
  );
}

export default LoginError;
