import { useREALAuth } from "@context/AuthProvider";
import React, { createContext, useState, useContext, useEffect } from "react";
import { withBoosterBaseUrl } from "utils/utils";

const ActiveBoosterContext = createContext();

function ActiveBoosterProvider({ children }) {
  const [activeBooster, setActiveBooster] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { auth } = useREALAuth();

  const fetchActiveBooster = async () => {
    if (!auth) return;
    setIsLoading(true);

    try {
      const token = await auth.auth?.currentUser?.getIdToken();

      const header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        withBoosterBaseUrl("/history?status=active"),
        {
          headers: header,
        }
      );
      const activeBoosters = await response.json();

      if (activeBoosters.message) {
        setActiveBooster(null);
        return;
      }

      setActiveBooster(activeBoosters.boosters[0]);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!auth) {
      setError("Not logged in!");
      setIsLoading(false);
      return;
    }

    fetchActiveBooster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const revalidate = () => {
    if (!auth) {
      setError("Not logged in!");
      setIsLoading(false);
      return;
    }

    fetchActiveBooster();
  };

  const value = {
    activeBooster,
    revalidate,
    isLoading,
    error,
  };

  return (
    <ActiveBoosterContext.Provider value={value}>
      {children}
    </ActiveBoosterContext.Provider>
  );
}

function useActiveBooster() {
  return useContext(ActiveBoosterContext);
}

export { ActiveBoosterProvider, useActiveBooster };
