import { useNavigate } from "react-router-dom";
import NeonCard from "@common/neoncard";
import CaliGraphy from "@common/Caligraphy";

function ShopButton() {
  const navigate = useNavigate();

  return (
    <NeonCard
      onClick={() => navigate("/shop")}
      className={"nobg hover"}
      styles={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CaliGraphy
        level={2}
        type="Heading"
        content="Shop"
        classname="blue shadow"
      />
    </NeonCard>
  );
}

export default ShopButton;
