import { auth, db } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setadmin } from "../../Store/Features/AdminQuests";
import { AdminRoutes } from "../../config/urls";

export const useAdmin = () => {
  const admin = useSelector((state) => state.Admin.admin);
  const [loading, setloading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const arr = AdminRoutes.map((e) => e?.url);
  const dispatch = useDispatch();
  const get = async () => {
    try {
      if (pathname === "/intradashboard" || pathname === "/intradashboard/") {
        if (!admin?.role) {
          return navigate("/intradashboard/login");
        }
      }
      if (!auth.currentUser?.uid) return navigate("/intradashboard/login");
      else if (admin?.role) {
        if (!arr.includes(pathname))
          return navigate("/intradashboard/questmanager");
      }

      setloading(true);
      const docRef = doc(db, "admins", auth.currentUser?.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setadmin(data));
        setloading(false);
        if (pathname.includes("intradashboard")) {
          const find = arr.includes(pathname);
          if (!find) navigate("/intradashboard/questmanager");
        }
        return data;
      } else {
        if (pathname.includes("/intradashboard")) {
          const find = arr.includes(pathname);
          if (find) navigate("/intradashboard/login");
        }
      }
    } catch (error) {
      if (pathname.includes("intradashboard")) {
        navigate("/intradashboard/login");
      }
      console.log({ error });
      setloading(false);
    }
  };

  useEffect(() => {
    if (admin?.role) return;
    get();
    return () => {
      setloading(false);
    };
  }, [auth.currentUser]);

  return { get, loading };
};
