// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_content__sCqfC {
  color: white;
  opacity: 0.9;
  line-height: 1.4rem;
  font-size: 1rem;
}

.styles_button__eijn0 {
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
  height: 60px;
  min-height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Intraverse/Inventory/components/LoginError/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".content {\n  color: white;\n  opacity: 0.9;\n  line-height: 1.4rem;\n  font-size: 1rem;\n}\n\n.button {\n  display: flex;\n  flex-grow: 0;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  color: white;\n  height: 60px;\n  min-height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `styles_content__sCqfC`,
	"button": `styles_button__eijn0`
};
export default ___CSS_LOADER_EXPORT___;
