export const HAS_HOVER_CLASS_NAME = "has-hover";

export default function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0;

  const isTouchScreen = isTouchScreenDevice();

  function enableHover() {
    if (isTouchScreen) return;
    if (new Date() - lastTouchTime < 500) return;
    document.body.classList.add(HAS_HOVER_CLASS_NAME);
  }

  function disableHover() {
    document.body.classList.remove(HAS_HOVER_CLASS_NAME);
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date();
  }

  document.addEventListener("touchstart", updateLastTouchTime, true);
  document.addEventListener("touchstart", disableHover, true);
  document.addEventListener("mousemove", enableHover, true);

  enableHover();
}

const isTouchScreenDevice = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};
