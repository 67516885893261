import { Modal } from "antd";
import NeonCard from "common/neoncard";
import CaliGraphy from "common/Caligraphy";

import styles from "./styles.module.scss";

function InformationModal({ isOpen, close, title, children }) {
  return (
    <Modal
      className={styles.modal}
      centered
      open={isOpen}
      footer={null}
      mask={true}
      maskClosable={true}
      width={800}
      wrapProps={{
        style: { overflowX: "hidden" },
      }}
      keyboard={null}
      styles={{
        body: { background: "transparent" },
        mask: {
          backdropFilter: "blur(1rem)",
          WebkitBackdropFilter: "blur(1rem)",
          background: "rgba(0,0,0,0.5)",
        },
      }}
      onCancel={() => {
        close();
      }}
      closeIcon={null}
      closable={true}
      outsideClose={true}
      destroyOnClose
    >
      <NeonCard
        styles={{
          padding: "3rem",
          width: "100%",
          background: "green",
          margin: "auto",
        }}
      >
        <CaliGraphy
          type="heading"
          rows={2}
          style={{ marginBottom: "30px" }}
          content={title}
        />
        <div className={styles.content}>{children}</div>
      </NeonCard>
    </Modal>
  );
}

export default InformationModal;
