import { BUTTON } from "../../../../../../common/button";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { CgCloseO } from "react-icons/cg";
import { useLogout } from "../../../../../../hooks";
import NeonCard from "../../../../../../common/neoncard";
import CaliGraphy from "../../../../../../common/Caligraphy";
import { db } from "../../../../../../config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useSelector } from "react-redux";

function GameStats() {
  const activeround = useSelector((state) => state.round.data);
  const [content, setContent] = useState("");
  const { handlelogout } = useLogout();

  useEffect(() => {
    const docRef = doc(db, "Content", "contentDoc");
    try {
      var unsubscribeuser = onSnapshot(docRef, (d) => {
        if (d.exists()) {
          setContent(d.data()?.content);
        }
      });
    } catch (e) {}
    return () => {
      unsubscribeuser?.();
    };
  }, []);

  return (
    <NeonCard
      styles={{ height: "320px", overflow: "hidden" }}
      className="gameStats rightSide nobg hover"
      stats
    >
      <Row className="gameStats_Inventory" justify={"center"} gutter={[0, 5]}>
        <Col span={24} className={"round_title_counter"}>
          <CaliGraphy
            rows={1}
            content={`${activeround?.title || "NFTWC2023"} INFO`}
            level={2}
            classname="blue shadow"
            type="Heading"
          />
        </Col>

        <Col span={24} className="round_info">
          {/* <RoundCountdown /> */}

          <CaliGraphy
            style={{
              overflow: "hidden",
            }}
            rows={15}
            isHtml
            scrollAble
            // content={
            //   <span
            //     className="Content_Round VerticalScrollBar"
            //     style={{ textAlign: "left", minHeight: "10rem" }}
            //     dangerouslySetInnerHTML={{
            //       __html: content,
            //     }}
            //   />
            // }
            content={content ? content : "Round Details.... "}
          />
        </Col>
        <Col
          span={24}
          className={"round_title_counter"}
          style={{
            display: "flex",
            borderTop: "1px solid white",
            justifyContent: "center",
            paddingBlock: ".5rem 1rem",
            marginTop: ".5rem",
            // boxShadow: "0px 0px 20px 0px rgba(0,0,0,.5)",
          }}
        >
          <BUTTON
            red
            text={"logout"}
            onClick={handlelogout}
            icon={<CgCloseO />}
          />
        </Col>
      </Row>
    </NeonCard>
  );
}

export default GameStats;
