import { useEffect } from "react";
import WebFont from "webfontloader";

export const useFontLoader = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap",
        ],
      },
      active: () => {},
      inactive: () => {
        console.error("Failed to load font");
      },
    });
  }, []);

  return null;
};
