import React, { useState } from "react";
import styles from "./styles.module.scss";
import { BUTTON } from "common/button";
import { useCart } from "Modules/Shop/context/CartProvider";
import classNames from "classnames";
import { useNotification } from "hooks";

function Payment({ totalPrice }) {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("PAY NOW");
  const { openNotification } = useNotification();
  const { checkout } = useCart();

  const handlePayment = async () => {
    try {
      setIsLoading(true);
      setButtonText("Processing...");
      const response = await checkout();
      if (response && response.payment_url) {
        window.location.href = response.payment_url;
        return;
      }
      openNotification("Payment failed", "error");
      setIsLoading(false);
      setButtonText("PAY NOW");
    } catch (error) {
      openNotification("Payment failed", "error");
      setIsLoading(false);
      setButtonText("PAY NOW");
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper__title}>Payment</h2>
      <div className={styles.wrapper__body}>
        <p className={styles.wrapper__body__subtitle}>
          Total Price: $ {totalPrice}
        </p>
        <div className={styles.wrapper__body__description}>
          <img
            className={styles.wrapper__body__description__image}
            alt="cryptocurrency payment"
            src="/assets/shop/cryptocurrency-payment.svg"
          />
          <p className={styles.wrapper__body__description__content}>
            Crypto: Litecoin, Ethereum, Tether, Bitcoin, BitcoinCash
          </p>
        </div>
        <BUTTON
          buttonClassName={classNames(styles.wrapper__body__pay_button, {
            [styles["wrapper__body__pay_button--disabled"]]: isLoading,
          })}
          text={buttonText}
          onClick={handlePayment}
        />
      </div>
    </div>
  );
}

export default Payment;
