import React from "react";

import styles from "./styles.module.scss";
import NeonCard from "@common/neoncard";
import classNames from "classnames";
import CardBody from "./CardBody";

function BoosterCardSkeleton() {
  return <NeonCard className={styles.pulse}></NeonCard>;
}

function BoosterCard({ booster, userHasActiveBooster, revalidate }) {
  const isActive = booster.status === "active";
  const isConsumed = booster.status === "consumed";
  const isReady = booster.status === "ready";

  return (
    <NeonCard
      className={classNames(styles.card, "booster-card", {
        [styles["card--consumed"]]: isConsumed,
        [styles["card--ready"]]: isReady,
        [styles["card--active"]]: isActive,
      })}
    >
      <CardBody
        booster={booster}
        userHasActiveBooster={userHasActiveBooster}
        revalidate={revalidate}
      />
    </NeonCard>
  );
}

BoosterCard.Skeleton = BoosterCardSkeleton;
export default BoosterCard;
