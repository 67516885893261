import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playerLeaderBoard: {
    loading: false,
    data: [],
  },
  referralLeaderBoard: {
    loading: false,
    data: [],
  },
  projectsLeaderBoard: {
    loading: false,
    data: [],
  },
  activeTab: 2,
};

export const LeaderBoarSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    setPlayerLeaderBoard: (state, action) => {
      if (action.payload.hasOwnProperty("loading")) {
        state.playerLeaderBoard.loading = action.payload.loading;
      }
      if (action.payload.hasOwnProperty("data")) {
        state.playerLeaderBoard.data = action.payload.data;
      }
    },
    setreferralLeaderBoard: (state, action) => {
      if (action.payload.hasOwnProperty("loading")) {
        state.referralLeaderBoard.loading = action.payload.loading;
      }
      if (action.payload.hasOwnProperty("data")) {
        state.referralLeaderBoard.data = action.payload.data;
      }
    },
    setProjectsLeaderBoard: (state, action) => {
      if (action.payload.hasOwnProperty("loading")) {
        state.projectsLeaderBoard.loading = action.payload.loading;
      }
      if (action.payload.hasOwnProperty("data")) {
        state.projectsLeaderBoard.data = action.payload.data;
      }
    },
    setActiveLeaderboardTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlayerLeaderBoard,
  setreferralLeaderBoard,
  setProjectsLeaderBoard,
  setActiveLeaderboardTab,
} = LeaderBoarSlice.actions;

export default LeaderBoarSlice.reducer;
