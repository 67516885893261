import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Wombat } from "wombat-ual";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useWallet } from "../../../../../hooks";
import { setWombatClicked } from "../../../../../Store/Features/WombatWallet";

const chains = [
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    name: "WAX",
    rpcEndpoints: [
      {
        protocol: "https",
        host: "wax.greymass.com",
        port: 443,
      },
    ],
  },
];
const TestApp = ({
  ual: { activeUser, showModal, logout, hideModal, users, modal, ...rest },
}) => {
  const { connectwallet } = useWallet();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const wombatclicked = useSelector(
    (state) => state.WombatWallet.wombatclicked
  );
  const find = profile?.wallets?.find((e) => e?.chain === "wax");
  const account = users?.[0]?.accountName;
  // const [clicked, setclicked] = useState(false);
  // useEffect(() => {
  //   if (!find && account) {
  //     logout();
  //     hideModal();
  //     setclicked(false);
  //     connectwallet("wax", users?.[0]?.accountName, true).then((e) => {});
  //   }
  // }, [find, account]);
  useEffect(() => {
    if (!find && !account && wombatclicked) {
      showModal();
    } else if (account && !find) {
      if (wombatclicked) {
        connectwallet("wax", users?.[0]?.accountName, true).then((e) => {
          logout();
          hideModal();
          dispatch(setWombatClicked(false));
        });
      }
    }
    return () => {
      dispatch(setWombatClicked(false));
    };
  }, [wombatclicked, find, account]);

  // const renderLoginButton = () => (
  //   <p
  //     style={{
  //       width: "100%",
  //       display: "flex",
  //       gap: ".5rem",
  //       margin: "0pc",
  //     }}
  //     onClick={() => {
  //       setclicked(true);
  //       showModal();
  //     }}
  //   >
  //     <img
  //       src="assets/wallets/wombat.jpg"
  //       alt="wombat"
  //       style={{
  //         width: "38px",
  //         height: "38px",
  //         objectFit: "contain",
  //         filter: "brightness(.8)",
  //         boxShadow: "none",
  //       }}
  //     />
  //     Wombat
  //   </p>
  // );

  // return renderLoginButton();
  return null;
};
const TestAppConsumer = withUAL(TestApp);

export const WombatWrapper = ({ click }) => {
  const wombat = new Wombat(chains, { appName: "Intraverse-App" });

  if (!click) return null;
  return (
    <UALProvider
      appName="Intraverse App"
      authenticators={[wombat]}
      chains={chains}
      key={chains[0].chainId}
    >
      <TestAppConsumer />
    </UALProvider>
  );
};
