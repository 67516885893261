import { Route, Routes, useLocation } from "react-router-dom";

import React, { Fragment, useEffect } from "react";
import {
  useGetQuests,
  useFontLoader,
  useFetchRound,
  useFetchLeaderboards,
} from "./hooks";
import BGAnimation from "./common/animations";
import Redirectes from "./Modules/Redirectes";
import { useSelector } from "react-redux";
import UsetifulComponent from "./Usetiful";
import Loader from "./common/loader";
import IntraverseRoutes from "./Modules/Intraverse/IntraverseRoutes";
import watchForHover from "@common/watchForHover";
import { usingapp } from "config/constants";

const packageJson = require('./symlink-2-package.json');
const AdminPanel = React.lazy(() => import("./Modules/AdminPanel"));

const Error404 = React.lazy(() => import("./common/404"));

function App() {
  watchForHover();
  useFontLoader(); // this hook initalized the app font from google fonts
  useFetchRound(); // fetch round is needed in both home and quest routes
  useGetQuests(); // quests and user applications are needed in home and quest routes
  useFetchLeaderboards(); // using leaderboards data in 2 routes. will refactor this
  useEffect(() => {
    if (usingapp === "dev") {
      document.title = "Dev";
    }
  }, []);

  const { pathname } = useLocation();

  const play = useSelector((state) => state.playing.play);
  const apploading = useSelector((state) => state.loading.apploading);
  const path = pathname === "/";
  const conditionToAnimate = path || !apploading || !play;

  return (
    <Fragment>
      <Loader />
      <Redirectes />
      <BGAnimation />
      <UsetifulComponent />
      <React.Suspense fallback={<></>}>
        <div className={`${conditionToAnimate ? "fade-in" : ""} app`}>
          <Routes>
            {/* client */}
            <Route index path="/*" element={<IntraverseRoutes />} />
            {/* dashboard */}
            <Route path="/intradashboard/*" element={<AdminPanel />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </React.Suspense>
      <span style={{ position: "absolute", left: "20px", bottom: "10px", color: "#fff", fontSize: "15px"}}>v{packageJson.version}</span>
    </Fragment>
  );
}

export default App;
